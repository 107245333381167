import MyBrand from 'Components/MyBrand';
import * as React from 'react';
import { NavDropdown } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const TOURNEY_SEASON = 6;

// TODO: Add a scroll to top footer?
export function MidokuniNavBar() {
	const links = [
		['/StudentInsights', 'Student Insights'],
		['/About', 'About Me'],
		['/Resources', 'Resources'],
	]

	function RaidDataAsia() {
		const raids = {
			// 44: "Hieronymus",
			// 45: "Binah",
			// 46: "Goz",
			// 47: "Chesed",
			// 48: "ShiroKuro",
			49: "Kaiten",
			50: "Perorodzilla",
			51: "Hod",
			52: "Gregorius",
			53: "Goz",
			54: "Hovercraft",
			55: "Binah",
		}
		return (
			<>
				{Object.keys(raids).map(key => <NavDropdown.Item href={`/Raid/AS/${key}`}>
					<Nav.Link key={`/Raid/AS/${key}`} href={`/Raid/AS/${key}`}>Asia / S{key} - {raids[key]}</Nav.Link>
				</NavDropdown.Item>)}
			</>
		)
	}

	function GrandRaidDataAsia() {
		const raids = {
			1: "Binah",
			2: "Kaiten",
			3: "Perorodzilla",
			4: "ShiroKuro",
		}
		return (
			<>
				{Object.keys(raids).map(key => <NavDropdown.Item href={`/GrandRaid/AS/${key}`}>
					<Nav.Link key={`/GrandRaid/AS/${key}`} href={`/GrandRaid/AS/${key}`}>Asia / S{key} - {raids[key]}</Nav.Link>
				</NavDropdown.Item>)}
			</>
		)
	}

	function RaidDataJapan() {
		const raids = {
			56: "Goz",
			57: "HoverCraft",
			58: "Binah",
			59: "Chesed",
			60: "Kurokage",
			61: "HoverCraft",
			63: "Chesed",
		}
		return (
			<>
				{Object.keys(raids).map(key => <NavDropdown.Item href={`/Raid/JP/${key}`}>
					<Nav.Link key={`/Raid/JP/${key}`} href={`/Raid/JP/${key}`}>Japan / S{key} - {raids[key]}</Nav.Link>
				</NavDropdown.Item>)}
			</>
		)
	}

	function TowerDataJapan() {
		const raids = {
			2: "Fury of Set (Light)",
		}
		return (
			<>
				{Object.keys(raids).map(key => <NavDropdown.Item href={`/Tower/JP/${key}`}>
					<Nav.Link key={`/Tower/JP/${key}`} href={`/Tower/JP/${key}`}>Japan / S{key} - {raids[key]}</Nav.Link>
				</NavDropdown.Item>)}
			</>
		)
	}
	function GrandRaidDataJapan() {
		const raids = {
			1: "Binah",
			7: "Hod",
			8: "Goz",
			10: "Kaiten",
			11: "Binah",
		}
		return (
			<>
				{Object.keys(raids).map(key => <NavDropdown.Item href={`/GrandRaid/JP/${key}`}>
					<Nav.Link key={`/GrandRaid/JP/${key}`} href={`/GrandRaid/JP/${key}`}>Japan / S{key} - {raids[key]}</Nav.Link>
				</NavDropdown.Item>)}
			</>
		)
	}

	function RaidServers() {
		const raids = {
			AS: "Asia",
			EU: "Global",
			JP: "Japan",
			KR: "Korea",
			NA: "North America",
			TWHKMC: "Taiwan / Hong Kong / Macau",
		}
		
		return (
			<>
				{Object.keys(raids).map(key => <NavDropdown.Item href={`/Raid/${key}`}>
					<Nav.Link key={`/Raid/${key}`} href={`/Raid/${key}`}>{raids[key]}</Nav.Link>
				  </NavDropdown.Item>)}
			</>
		)
	}

	function GrandRaidServers() {
		const raids = {
			AS: "Asia",
			EU: "Global",
			JP: "Japan",
			// KR: "Korea",
			NA: "North America",
			TWHKMC: "Taiwan / Hong Kong / Macau",
			TOURNAMENT: "Tournament",
		}
		
		return (
			<>
				{Object.keys(raids).map(key => <NavDropdown.Item href={`/GrandRaid/${key}`}>
					<Nav.Link key={`/GrandRaid/${key}`} href={`/GrandRaid/${key}`}>{raids[key]}</Nav.Link>
				  </NavDropdown.Item>)}
			</>
		)
	}
	function TowerServers() {
		const raids = {
			AS: "Asia",
			// EU: "Global",
			JP: "Japan",
			// KR: "Korea",
			NA: "North America",
			TWHKMC: "Taiwan / Hong Kong / Macau",
		}
		
		return (
			<>
				{Object.keys(raids).map(key => <NavDropdown.Item href={`/Tower/${key}`}>
					<Nav.Link key={`/Tower/${key}`} href={`/Tower/${key}`}>{raids[key]}</Nav.Link>
				  </NavDropdown.Item>)}
			</>
		)
	}
	function JfdServers() {
		const type="jfd";
		const raids = {
			AS: "Asia",
			// EU: "Global",
			JP: "Japan",
			// KR: "Korea",
			// NA: "North America",
			// TWHKMC: "Taiwan / Hong Kong / Macau",
		}
		
		return (
			<>
				{Object.keys(raids).map(key => <NavDropdown.Item href={`/${type}/${key}`}>
					<Nav.Link key={`/${type}/${key}`} href={`/${type}/${key}`}>{raids[key]}</Nav.Link>
				  </NavDropdown.Item>)}
			</>
		)
	}
	const pvpKey = localStorage.getItem("midokuni.pvp.private.key");

	return (
	<Navbar 
	collapseOnSelect 
	expand="lg" 
	// style={{ height: 71 }} 
	sticky="top" 
	className="bg-body-tertiary my-nav"
	data-bs-theme="dark">
		<Navbar.Brand href="/" style={{marginLeft: 10}}>
			<MyBrand />
		</Navbar.Brand>
		<Navbar.Toggle aria-controls="responsive-navbar-nav" />
		<Navbar.Collapse id="responsive-navbar-nav">
		<Nav className="me-auto" style={{ marginLeft: 10 }}>
			{links.map(d=>(
				<Nav.Link key={d[0]} href={d[0]} disabled={window.location.pathname.startsWith(d[0])}>
					{d[1]}
				</Nav.Link>))
			}
			<NavDropdown title="Total Assault">
				{/* <NavDropdown.Item href="#action3">Action</NavDropdown.Item> */}
				<NavDropdown.ItemText>Raid</NavDropdown.ItemText>
				<RaidServers />				  
				{/* <NavDropdown.Divider/> */}
				{/* <NavDropdown.ItemText>Quick Links - TOR</NavDropdown.ItemText>
				<RaidDataAsia />  
				<NavDropdown.Divider/>
				<RaidDataJapan />   */}
				{/* <NavDropdown.Divider />
				<NavDropdown.Item href="#action5">
				Something else here
				</NavDropdown.Item> */}
			</NavDropdown>
			<NavDropdown title="Grand Assault">
				{/* <NavDropdown.Item href="#action3">Action</NavDropdown.Item> */}
				<NavDropdown.ItemText>Tricolor Raid</NavDropdown.ItemText>
				<GrandRaidServers />				  
				{/* <NavDropdown.Divider/> */}
				{/* <NavDropdown.ItemText>Quick Links</NavDropdown.ItemText>
				<GrandRaidDataAsia />  
				<NavDropdown.Divider/>
				<GrandRaidDataJapan /> */}
			</NavDropdown>
			<NavDropdown title="Joint Firing Drill">
				<NavDropdown.ItemText>Joint Exercise</NavDropdown.ItemText>
				<JfdServers />
			</NavDropdown>
			<NavDropdown title="Final Restriction Release">
				<NavDropdown.ItemText>Unleashed Assault</NavDropdown.ItemText>
				<NavDropdown.ItemText>Limit Break Assault</NavDropdown.ItemText>
				<NavDropdown.ItemText>Tower</NavDropdown.ItemText>
				<TowerServers />
				{/* <TowerDataJapan /> */}
			</NavDropdown>
			<NavDropdown title="Summary">
				  <NavDropdown.Item href="/Summary/Banner">
					<Nav.Link key="/Summary/Banner" href="/Summary/Banner">Banner</Nav.Link>
				  </NavDropdown.Item>
				  <NavDropdown.Item href="/Summary/Farmable">
					<Nav.Link key="/Summary/Farmable" href="/Summary/Farmable">Farmable</Nav.Link>
				  </NavDropdown.Item>
				  <NavDropdown.Item href="/Summary/Hard">
					<Nav.Link key="/Summary/Hard" href="/Summary/Hard">Hard Quest</Nav.Link>
				  </NavDropdown.Item>
			</NavDropdown>
			<NavDropdown title="Tools">
				  <NavDropdown.Item href="/Tool/PVP">
					<Nav.Link key="/Tool/PVP" href="/Tool/PVP">PVP Analyzer</Nav.Link>
				  </NavDropdown.Item>
				  <NavDropdown.Item href="/Tool/StudentEXP">
					<Nav.Link key="/Tool/StudentEXP" href="/Tool/StudentEXP">SEX</Nav.Link>
				  </NavDropdown.Item>
				  <NavDropdown.Item href="/Tool/Roster">
					<Nav.Link key="/Tool/Roster" href="/Tool/Roster">Student Sharing</Nav.Link>
				  </NavDropdown.Item>
				  <NavDropdown.Item href="/Tool/Spark">
					<Nav.Link key="/Tool/Spark" href="/Tool/Spark">Spark Tracker</Nav.Link>
				  </NavDropdown.Item>
				  <NavDropdown.Item href="/Tool/SparkAnalytics">
					<Nav.Link key="/Tool/SparkAnalytics" href="/Tool/SparkAnalytics">Spark Analytics</Nav.Link>
				  </NavDropdown.Item>
				  <NavDropdown.Item href={`/Tool/GrandTournament/${TOURNEY_SEASON}`}>
					<Nav.Link key={`/Tool/GrandTournament/${TOURNEY_SEASON}`} href={`/Tool/GrandTournament/${TOURNEY_SEASON}`}>Grand Tournament Referee</Nav.Link>
				  </NavDropdown.Item>	
				  <NavDropdown.Item href={`/Tool/GrandTournamentList/${TOURNEY_SEASON}`}>
					<Nav.Link key={`/Tool/GrandTournamentList/${TOURNEY_SEASON}`} href={`/Tool/GrandTournamentList/${TOURNEY_SEASON}`}>Grand Tournament List</Nav.Link>
				  </NavDropdown.Item>
				<NavDropdown.Divider/>
				<NavDropdown.ItemText>Board Runner Simulators (Currently Barebones)</NavDropdown.ItemText>
				  <NavDropdown.Item href="/Tool/Board/Yuuka">
					<Nav.Link key="/Tool/Board/Yuuka" href="/Tool/Board/Yuuka">Yuuka</Nav.Link>
				  </NavDropdown.Item>
				  <NavDropdown.Item href="/Tool/Board/Saki">
					<Nav.Link key="/Tool/Board/Saki" href="/Tool/Board/Saki">Saki</Nav.Link>
				  </NavDropdown.Item>
				  <NavDropdown.Item disabled href="/Tool/Board/Custom">
					<Nav.Link disabled key="/Tool/Board/Custom" href="/Tool/Board/Custom">Custom (Coming Soon)</Nav.Link>
				  </NavDropdown.Item>
			</NavDropdown>
			<NavDropdown title="PVP History">
				  <NavDropdown.ItemText>Servers</NavDropdown.ItemText>
				  <NavDropdown.Item href="/PVP">
					<Nav.Link key="/PVP" href="/PVP">Global</Nav.Link>
				  </NavDropdown.Item>
				  { pvpKey ? <NavDropdown.Item href={`/PVP/${pvpKey}`}>
					<Nav.Link key={`/PVP/${pvpKey}`} href={`/PVP/${pvpKey}`}>Private</Nav.Link>
				  </NavDropdown.Item> : null
				  }
			</NavDropdown>
			<NavDropdown title="Fun">
				  <NavDropdown.Item href="/Fun/Kivodle">
					<Nav.Link key="/Fun/Kivodle" href="/Fun/Kivodle">Kivodle</Nav.Link>
				  </NavDropdown.Item>
				  <NavDropdown.Item href="/Fun/BaSort">
					<Nav.Link key="/Fun/BaSort" href="/Fun/BaSort">BA Sort</Nav.Link>
				  </NavDropdown.Item>
				  <NavDropdown.Item href="/Fun/Crossword">
					<Nav.Link key="/Fun/Crossword" href="/Fun/Crossword">Daily Crossword Puzzle (Concept)</Nav.Link>
				  </NavDropdown.Item>
				  <NavDropdown.Item href="https://www.youtube.com/watch?v=TI83PTcY4Mk">
					<Nav.Link key="Bad-Student" href="https://www.youtube.com/watch?v=TI83PTcY4Mk">Bad Student</Nav.Link>
				  </NavDropdown.Item>
			</NavDropdown>
		</Nav>
		
			</Navbar.Collapse>
	</Navbar>);
}
