import IconButton from "Components/IconButton";
import IStyle from "Interfaces/IStyle";
import { IStudentInfoProps } from "Interfaces/Props/IStudentInfoProps";
import { MyToast, MyToastContainer } from "Layouts/Toast";
import StudentMap from "Models/StudentMap";
import React, { useState } from "react";
import { Card, Col, Row, Toast } from 'react-bootstrap';

const styles:IStyle = {
	buttonLink: {
		margin: 5,
	}
}

export function StudentDetails(props: IStudentInfoProps) {
	const [toasts, setToasts] = useState([]);

	return (<>
	<MyToastContainer>
		{toasts.map((toast) => <MyToast>
			<Toast.Header closeButton>
				<strong className="me-auto">Copied URL!</strong>
			</Toast.Header>
			<Toast.Body>{toast}</Toast.Body>
		</MyToast>)}
	</MyToastContainer>
		<Row>
			<Col>
				<Card>
					<Card.Body>
						<Card.Img variant="top" src={new StudentMap().getIcon(props.target)} />
					</Card.Body>
					<Card.Footer>{new StudentMap().getProperName(props.target)}</Card.Footer>
				</Card>
			</Col>
		</Row>
		<Row style={{ marginTop: 10 }}>
			<Col style={styles.buttonLink}>
				<IconButton 
					// image="/logo512.png"
					image="https://ssl.gstatic.com/docs/spreadsheets/favicon3.ico"
					label="Sheet"
					href="https://docs.google.com/spreadsheets/d/1BO-Uh_CsAOIetPjrNyFy9-7r1a4RAtVwK135bBC3hzM" 
				/>
			</Col>
			<Col style={styles.buttonLink}>
				<IconButton 
					image="https://www.google.com/s2/favicons?sz=128&domain=schaledb.com"
					label="SCHALE DB"
					href={new StudentMap().getSchale(props.target)} 
				/>
			</Col>
			<Col style={styles.buttonLink}>
				<IconButton 
					image="/logo512.png"
					label="Share"
					onClick={async () => {
						const url = `https://${document.location.hostname}/StudentInsights/${props.target.replaceAll(' ', '_')}`;
						await navigator.clipboard.writeText(url);
						setToasts([...toasts, url]);
					}}
				/>
			</Col>
		</Row>
	</>);
}
