import { BrString } from "Components/BrString";
import React from "react";
import { Card } from "react-bootstrap";

export default function GenericCardRenderer(props: {model: {
	getDisplayName: () => string,
	getIcon: () => string,
}}) {
	const {model} = props;
	return (
		<Card style={{height:"auto", minWidth: 100, maxWidth: "30vw"}}>
			<Card.Img variant="top" src={model.getIcon()} />
			<Card.ImgOverlay style={{margin: 0, padding:0}}>
				<Card.Body style={{margin: 0, padding:0}}>
					<Card.Footer 
					className="text-center bg-violet d-none d-lg-block text-white"
					style={{width: "100%"
						, bottom:0, position: "absolute", opacity: 0.7
					}}
					>
						<BrString>{model.getDisplayName()}</BrString>
					</Card.Footer>
				</Card.Body>
			</Card.ImgOverlay>
			<Card.Footer
			style={{padding:2}}
			className="text-center d-block d-lg-none"
			>
				<BrString>{model.getDisplayName()}</BrString>
			</Card.Footer>
		</Card>
	);
}