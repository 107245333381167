import { ICellData } from "Interfaces/ICellData";
import { IParser } from "Interfaces/IParser";
import { ITableRow } from "Interfaces/ITableRow";
import { IRaid } from "../Interfaces/IRaid";

export default class BorrowStudent implements 
		IParser<BorrowStudent>,
		ITableRow {
	static updatedAt:string;
	name:string;
	binah:IRaid;
	chesed:IRaid;
	hod:IRaid;
	kaiten:IRaid;
	hieronymus:IRaid;
	gregorius:IRaid;
	shiroKuro:IRaid;
	perorodzilla:IRaid;
	goz:IRaid;
	getHeaders() {
		return [
			"name",
			"binah.outdoors",
			"binah.urban",
			"chesed.indoors",
			"chesed.outdoors",
			"hod.urban",
			"hod.indoors",
			"kaiten.outdoors",
			"kaiten.urban",
			"hieronymus.urban",
			"hieronymus.indoors",
			"gregorius.indoors",
			// "gregorius.???",
			"shiroKuro.urban",
			"shiroKuro.indoors",
			"perorodzilla.indoors",
			"perorodzilla.outdoors",
			"goz.indoors",
			"goz.outdoors",
		]
	}
	toArray() {
		return [
			this.name,
			this.binah.outdoors,
			this.binah.urban,
			this.chesed.indoors,
			this.chesed.outdoors,
			this.hod.urban,
			this.hod.indoors,
			this.kaiten.outdoors,
			this.kaiten.urban,
			this.hieronymus.urban,
			this.hieronymus.indoors,
			this.gregorius.indoors,
			// this.gregorius.???,
			this.shiroKuro.urban,
			this.shiroKuro.indoors,
			this.perorodzilla.indoors,
			this.perorodzilla.outdoors,
			this.goz.indoors,
			this.goz.outdoors,
		]
	}
	toKeyValues() {
		return {
			"name": this.name,
			"binah.outdoors": this.binah.outdoors,
			"binah.urban": this.binah.urban,
			"chesed.indoors": this.chesed.indoors,
			"chesed.outdoors": this.chesed.outdoors,
			"hod.urban": this.hod.urban,
			"hod.indoors": this.hod.indoors,
			"kaiten.outdoors": this.kaiten.outdoors,
			"kaiten.urban": this.kaiten.urban,
			"hieronymus.urban": this.hieronymus.urban,
			"hieronymus.indoors": this.hieronymus.indoors,
			"gregorius.indoors": this.gregorius.indoors,
			"shiroKuro.urban": this.shiroKuro.urban,
			"shiroKuro.indoors": this.shiroKuro.indoors,
			"perorodzilla.indoors": this.perorodzilla.indoors,
			"perorodzilla.outdoors": this.perorodzilla.outdoors,
			"goz.indoors": this.goz.indoors,
			"goz.outdoors": this.goz.outdoors,
		}
	}

	parse(data:ICellData[]): BorrowStudent {
		try {
			return BorrowStudent.parse(data);
		} catch {
			return null;
		}
	}

	static setUpdatedAt(updatedAt:string) {
		BorrowStudent.updatedAt = updatedAt;
	}

	static parse(data:ICellData[]): BorrowStudent {
		let student = new BorrowStudent();
		student.name = data[1]?.v ?? '';
		let i:number = 2;
		/*
		*/
		student.binah = {
			outdoors: data[i++]?.v ?? '',
			urban: data[i++]?.v ?? '',
		}
		student.chesed = {
			indoors: data[i++]?.v ?? '',
			outdoors: data[i++]?.v ?? '',
		}
		student.hod = {
			urban: data[i++]?.v ?? '',
			indoors: data[i++]?.v ?? '',
		}
		student.kaiten = {
			outdoors: data[i++]?.v ?? '',
			urban: data[i++]?.v ?? '',
		}
		student.hieronymus = {
			urban: data[i++]?.v ?? '',
			indoors: data[i++]?.v ?? '',
		}
		student.gregorius = {
			indoors: data[i++]?.v ?? '',
		}
		// NOTE: Unknown 2nd Greg
		i++;
		student.shiroKuro = {
			urban: data[i++]?.v ?? '',
			indoors: data[i++]?.v ?? '',
		}
		student.perorodzilla = {
			indoors: data[i++]?.v ?? '',
			outdoors: data[i++]?.v ?? '',
		}
		student.goz = {
			indoors: data[i++]?.v ?? '',
			outdoors: data[i++]?.v ?? '',
		}
		return student;
	}
}