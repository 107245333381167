import { BrString } from "Components/BrString";
import IParagraphStringProps from "Interfaces/Props/IParagraphStringProps";
import React from "react";
export class ParagraphString extends React.Component<IParagraphStringProps> {
	render() {
		const {As, children} = this.props;
		const splitted = children?.split(/\n\n+/g);
		if (As)
			return splitted.map((str) => (<As><BrString>{str}</BrString></As>));
		return splitted.map(str => <p><BrString>{str}</BrString></p>);
	}
}
