import { Images } from "Components/Images";
import StudentRenderer from "Components/ModelRenderers/StudentRenderer";
import MyBrandFooter from "Components/MyBrandFooter";
import MyLoadingSpinner from "Components/MyLoadingSpinner";
import { StudentPicker } from "Components/StudentPicker";
import IStyle from "Interfaces/IStyle";
import Student from "Models/Student";
import StudentMap from "Models/StudentMap";
import React, { useMemo, useState } from "react";
import { Alert, Button, CloseButton, Col, Form, Row } from "react-bootstrap";

const styles:IStyle = {
	icon: {
		textAlign: "center"
	},
	container: {
		padding: 10,
		margin: 5,
		border: "1px solid",
		// borderColor: "violet",
		borderRadius: 20,
		borderColor: "pink",
	},
	closeButton: {
		textAlign: "right"
	}
}
interface IStudentJSON {
	breakpoints: [from:number, to:number][],
	exp: number[]
}
const studentMap = new StudentMap();

export default function Spark() {
	const isLoading=false;
	const iconSize = "25%";
	const [isDrawerVisible, setIsDrawerVisible] = useState(true);
	const [compact, setCompact] = useState(true);
	const [newStudents, setNewStudents] = useState<string[]>([])
	const [dupeStudents, setDupeStudents] = useState<string[]>([])
	
	function SparkSidebar() {
		const [searchString, setSearchString] = useState("");
		const matches = useMemo(()=>{
			const data = [...new Set(studentMap.getStudentNames().filter((n) => n.toLowerCase().indexOf(searchString.toLowerCase()) >= 0).map(n => studentMap.getProperName(n)))];
			data.sort()
			return data;
		}
		, [searchString]);
		return (
			<Row>
				<Col>
					<Row>
						<Col md={2}>
						</Col>
						<Col>
							<div key="student-compact" className="mb-3">
								<Form.Check 
								checked={compact}
								type="switch"
								id="student-compact"
								label="Compact Mode"
								onChange={(a)=>{
									setCompact(a.target.checked);
								}}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<StudentPicker setTarget={(target) => setSearchString(target ?? "")} target={searchString} />
						</Col>
					</Row>
					<Row style={{overflowY: "scroll", maxHeight: "70vh"}}>
						{matches.map(id=><Col key={id} xs={4} onClick={(e)=>{
							const isDupe = e.shiftKey || e.ctrlKey || newStudents.indexOf(id) >= 0 || dupeStudents.indexOf(id) >= 0;
							if (isDupe) {
								dupeStudents.push(id);
								setDupeStudents([...dupeStudents]);
							} else {
								newStudents.push(id);
								setNewStudents([...newStudents]);
							}
						}}>
							<StudentRenderer compact={compact} bare model={Student.fromName(studentMap.getProperName(id))} />
						</Col>
						)}
					</Row>
				</Col>
			</Row>
		)
	}

	return (
	<>
		<Alert>Click on a student to add/remove. Click while holding Shift/Ctrl to add to Eligma. Idk about mobile.<br></br>Students already listed will automatically be under Eligma</Alert>
		<Row>
			<Col>
				<Button variant='outline-secondary' onClick={() => setIsDrawerVisible(!isDrawerVisible)}>
					<span className="navbar-toggler-icon" />
					Toggle
				</Button>
			</Col>
		</Row>
		<Row>
			<Col xs={12} md={6} lg={3} hidden={!isDrawerVisible}>
				{/* Hello */}
				<SparkSidebar />
			</Col>
			<Col>
				<Row visible={isLoading}>
					<Col></Col>
					<Col style={styles.spinnerRow}>
						<MyLoadingSpinner isLoading={isLoading}/>
					</Col>
					<Col></Col>
				</Row>
				<Row>
					<Col style={styles.container}>
						<Row><Col style={styles.closeButton}><CloseButton aria-label="Clear-newstudents" onClick={()=>setNewStudents([])}/></Col></Row>
						<Row>
							<Col style={styles.icon}>
								<Images.Pyroxene size={iconSize} />
							</Col>
						</Row>
						<Row>
							{newStudents.map((id, i)=><Col key={i+"_new"} xs={6} md={isDrawerVisible ? 3 : 2} onClick={(e)=>{
									const index = newStudents.findIndex(student => student === id)
									newStudents.splice(index, 1);
									setNewStudents([...newStudents]);
								}}>
								<StudentRenderer compact={compact} bare model={Student.fromName(studentMap.getProperName(id))} />
							</Col>
							)}
						</Row>
					</Col>
					<Col style={styles.container}>
						<Row><Col style={styles.closeButton}><CloseButton aria-label="Clear-newstudents" onClick={()=>setDupeStudents([])}/></Col></Row>
						<Row>
							<Col style={styles.icon}>
								<Images.Eligma size={iconSize} />
							</Col>
						</Row>
						<Row>
							{dupeStudents.map((id, i)=><Col key={i+"_dupe"} xs={6} md={isDrawerVisible ? 3 : 2} onClick={(e)=>{
									const index = dupeStudents.findIndex(student => student === id)
									dupeStudents.splice(index, 1);
									setDupeStudents([...dupeStudents]);
								}}>
								<StudentRenderer compact={compact} bare model={Student.fromName(studentMap.getProperName(id))} />
							</Col>
							)}
						</Row>
					</Col>
				</Row>

				<MyBrandFooter />
			</Col>
		</Row>
	</>
	);
}