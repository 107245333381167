import { IIconButtonProps } from "Interfaces/Props/IIconButtonProps";
import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default class IconLink extends React.Component<IIconButtonProps> {
	render() {
		return (
			<Link target="_blank" to={this.props.href}>
				<Row>
					<Col>
						{this.props.image ? <Image style={{height:18, marginRight: 2, position:"relative", top:-2}} src={this.props.image}></Image> : <span>&#x1F517;</span> }
						{this.props.label}
					</Col>
				</Row>
			</Link>
		)
	}
}
