import { ColBadge } from "Components/ColBadge";
import IconLink from "Components/IconLink";
import BorrowStudent from "Models/BorrowStudent";
import React from "react";
import { Card, Col, ListGroup, Row } from "react-bootstrap";
import { BorrowStudentRaidRenderer } from "./BorrowStudentRaidRenderer";

export class BorrowStudentRenderer extends React.Component<{ model: BorrowStudent}> {
	render() {
		const model = this.props.model;
		return (
			<ListGroup variant="flush">
				<ListGroup.Item>
					<Row>
						<ColBadge xs={12} md={12} lg={3}>Description</ColBadge>
						<Col>
							"You tell me who you're borrowing for a raid and I'm gonna tell you what I think" (Insane and Below)
						</Col>
					</Row>
				</ListGroup.Item>
				<BorrowStudentRaidRenderer model={model.binah} name="Binah"/>
				<BorrowStudentRaidRenderer model={model.chesed} name="Chesed" />
				<BorrowStudentRaidRenderer model={model.hod} name="Hod">
				</BorrowStudentRaidRenderer>
				<BorrowStudentRaidRenderer model={model.kaiten} name="Kaiten FX Mk. 0" devName="KaitenFxMk0">
				</BorrowStudentRaidRenderer>
				<BorrowStudentRaidRenderer model={model.hieronymus} name="Hieronymus">
				</BorrowStudentRaidRenderer>
				<BorrowStudentRaidRenderer model={model.gregorius} name="Gregorius">
				</BorrowStudentRaidRenderer>
				<BorrowStudentRaidRenderer model={model.shiroKuro} name="Shiro & Kuro" devName="ShiroKuro">
				</BorrowStudentRaidRenderer>
				<BorrowStudentRaidRenderer model={model.perorodzilla} name="Perorodzilla">
				</BorrowStudentRaidRenderer>
				<BorrowStudentRaidRenderer model={model.goz} name="Goz">
				</BorrowStudentRaidRenderer>
				<ListGroup.Item>
					<Row>
						<ColBadge xs={12} md={12} lg={3}>Source</ColBadge>
						<Col xs={4} sm={6} md={4} lg={2}>
							<IconLink
								image="https://ssl.gstatic.com/docs/spreadsheets/favicon3.ico"
								label="Borrows"
								href="/doc/Borrow"
							/>
						</Col>
					</Row>
				</ListGroup.Item>
				<Card.Text className="text-muted"><sub>Sheet updated on {BorrowStudent.updatedAt}</sub></Card.Text>
			</ListGroup>
		)
	}
}
