import StudentNameDatalist from "Components/StudentNameDatalist";
import { IStudentPickerProps } from "Interfaces/Props/IStudentPickerProps";
import StudentMap from "Models/StudentMap";
import React, { useState } from "react";
import { CloseButton, Col, Row } from 'react-bootstrap';

export function StudentPicker(props: IStudentPickerProps) {
	const [text, setText] = useState("");
	const visible = typeof(props.visible) == "undefined" ? true : props.visible;
	const handleTextChange = (event:React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.value === "/") return;
		if (props.ignoreNumericSpecials) {
			if (event.target.value !== "" && event.target.value[event.target.value.length-1].match(/[0-9\!\@\#\$\%\^\&\*\(\)]/g)) {
				event.target.value = event.target.value.substring(0, event.target.value.length-1);
				return;
			}
		}
		const student = new StudentMap().getProperName(event.target.value);
		props.setTarget(student ?? event.target.value);
	}

	const handleFocus = (event:React.ChangeEvent<HTMLInputElement>) => {
		event.target.select();
	}
	if (!visible) return null;
	
	  return (
	  <Row>
		<Col  className="justify-content-end">
			<label>
				Search
			</label>
			&nbsp;&nbsp;&nbsp;&nbsp;
			<input
				id={props.id ?? ""}
				className="search"
				type="text"
				name="studentName"
				list="studentNames"
				value={props.target}
				onChange={handleTextChange}
				onFocus={handleFocus}
				onKeyDown={props.handleKeyDown}
				autoFocus={props.autoFocus}
			/>
			<CloseButton style={{marginLeft:5}} aria-label="Clear" onClick={()=>props.setTarget(null)}/>
			<StudentNameDatalist />
		</Col>
	  </Row>
	  );
}
