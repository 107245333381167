import { ColBadge } from "Components/ColBadge";
import IconLink from "Components/IconLink";
import { ParagraphString } from "Components/ParagraphString";
import StudentThoughts from "Models/StudentThoughts";
import React from "react";
import { Badge, Card, Col, ListGroup, Row } from "react-bootstrap";

export class StudentThoughtsRenderer extends React.Component<{ model: StudentThoughts}> {
	render() {
		const model = this.props.model;
		return (
			
			<ListGroup variant="flush">
			<ListGroup.Item>
				<Row>
					{/* <ColBadge>TLDR</ColBadge> */}
					<Col>
						<Card.Subtitle><Badge bg="secondary">TLDR</Badge></Card.Subtitle>
						<ParagraphString As={Card.Text}>
							{model.tldr}
						</ParagraphString>
					</Col>
				</Row>
			</ListGroup.Item>
			<ListGroup.Item>
				<Row>
					<Col>
						<Card.Subtitle><Badge bg="secondary">Early Game</Badge></Card.Subtitle>
						<ParagraphString As={Card.Text}>
							{model.earlyGame}
						</ParagraphString>
					</Col>
				</Row>
			</ListGroup.Item>
			<ListGroup.Item>
				<Row>
					<Col>
						<Card.Subtitle><Badge bg="secondary">Late Game (Raids)</Badge></Card.Subtitle>
						<ParagraphString As={Card.Text}>
							{model.lateGameRaid}
						</ParagraphString>
					</Col>
				</Row>
			</ListGroup.Item>
			<ListGroup.Item>
				<Row>
					<ColBadge>Source</ColBadge>
					<Col xs={4} sm={6} md={4} lg={2}>
						<IconLink
							image="https://ssl.gstatic.com/docs/spreadsheets/favicon3.ico"
							label="Thoughts"
							href="https://bit.ly/MidoStudentThoughts"
						/>
					</Col>
				</Row>
			</ListGroup.Item>
			<Card.Text className="text-muted"><sub>Updated on {model.updated}</sub></Card.Text>
		</ListGroup>
		)
	}
}
