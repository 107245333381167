import { MyTable } from "Components/MyTable";
import { IParser } from "Interfaces/IParser";
import { ITableRow } from "Interfaces/ITableRow";
import { IStudentInfoSheetProps } from "Interfaces/Props/IStudentInfoSheetProps";
import React from "react";
import Accordion from 'react-bootstrap/Accordion';

export class StudentInfoSheet<T extends IParser<T> & ITableRow> 
extends React.Component<IStudentInfoSheetProps<T>> {
	render() {
		const data = this.props.dataSource?.findAndParseByCol<T>(
			this.props.nameCol ?? "Name", 
			this.props.target, 
			this.props.template
		);
		return (
		<Accordion className="block">
			<Accordion.Item eventKey="0">
				<Accordion.Header>{this.props.name}</Accordion.Header>
				<Accordion.Body style={{maxWidth:"200vw"}}>
					<MyTable data={data} />
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>);
	}
}
