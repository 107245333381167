import { MyTable } from "Components/MyTable";
import IStyle from "Interfaces/IStyle";
import { ITableRow } from "Interfaces/ITableRow";
import React, { useEffect, useMemo, useState } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";

const styles:IStyle = {

	board: {
		display: "flex", 
		flexDirection: "row", 
		flexWrap: "wrap"
	},
}
interface IProps {
	template?: string;
}

interface IBoardTile {
	type: string;
	count: number;
	useDie?: number[];
}

interface ILapBonusTemplates {
	Yuuka: IBoardTile[][];
	Saki: IBoardTile[][];
	Custom: IBoardTile[][];
}
interface IBoardTemplates {
	Yuuka: IBoardTile[];
	Saki: IBoardTile[];
	Custom: IBoardTile[];
}
const CUSTOM_KEY = "midokuni.board.custom";
const BOARD_TEMPLATES:IBoardTemplates = {
	Yuuka: [ 
		{type: "Eleph", count: 1},
		{type: "Eligma", count: 1},
		{type: "Yellow Orb", count: 6},
		{type: "Yellow Permit", count: 6},
		{type: "Movement", count: 3},
		{type: "Credit", count: 800000},
		{type: "Eligma", count: 1},
		{type: "Credit", count: 500000},
		{type: "Yellow Orb", count: 4},
		{type: "Credit", count: 1200000},
		{type: "Movement", count: 1},
		{type: "Yellow Permit", count: 4},
		{type: "Eligma", count: 1},
		{type: "Yellow Permit", count: 2},
		{type: "Yellow Orb", count: 2},
		{type: "Eleph", count: 3},
		{type: "Movement", count: 2},
		{type: "Eleph", count: 2}
	],
	Saki: [
		{type: "Eleph", count: 5},
		{type: "Credit", count: 3200000},
		{type: "Yellow Orb", count: 15},
		{type: "Yellow Permit", count: 22},
		{type: "Eligma", count: 8},
		{type: "Eleph", count: 7},
		{type: "Eligma", count: 12},
		{type: "Credit", count: 2400000},
		{type: "Movement", count: 6},
		{type: "Credit", count: 1600000},
		{type: "Yellow Orb", count: 12},
		{type: "Yellow Permit", count: 10},
		{type: "Eligma", count: 6},
		{type: "Eleph", count: 4},
		{type: "DiceItem", count: 1},
		{type: "Yellow Orb", count: 10},
		{type: "Credit", count: 2000000},
		{type: "Yellow Permit", count: 17},
	],
	Custom: JSON.parse(localStorage.getItem(CUSTOM_KEY)) ?? []
}
const LAP_BONUS_TEMPLATES:ILapBonusTemplates = {
	Yuuka: [
	],
	Saki: [
		[],
		[{type: "DiceItem", count: 1}],
		[],
		[{type: "DiceItem", count: 1}],
		[],
		[{type: "DiceItem", count: 1}],
		[],
		[{type: "DiceItem", count: 1}],
		[],
		[{type: "DiceItem", count: 1}],
	],
	Custom: []
}
interface IItemCtr {
	[name:string]: number;
}

class DiceItemContainer {
	[face: number]: number;
	faces: number;
	faceArray: number[];
	constructor(dieSides: number) {
		this.faceArray = [];
		for(let i=1; i<=dieSides; i++) {
			
			this[i] = 0;
			this.faceArray.push(i);
		}
		this.faces = dieSides;
	}

	add(die: number) {
		this[die]++;
	} 
	use(die: number) {
		if (this[die] <= 0) return false;
		this[die]--;
		return true;
	}
	toString() {
		let arr = [];
		for(let i=1;i<=this.faces;i++) {
			arr.push(this[i]);
		}

		return arr.join(" / ");
	}
}
interface ILogger {
	log: (a:string)=>void 
}
class Board {
	board: IBoardTile[];
	lapBonuses: IBoardTile[][];
	currentTileIndex: number;
	distanceTravelled: number = 0;
	diceItems:DiceItemContainer;
	dieSides: number;
	continueTurn: boolean = false;
	isDiceItemRandom: boolean = false;
	simItems:IItemCtr = {}
	usedDieItems = 0;
	logger: ILogger;
	constructor (board: IBoardTile[], lapBonuses: IBoardTile[][]) {
		this.board = JSON.parse(JSON.stringify(board));
		this.lapBonuses = JSON.parse(JSON.stringify(lapBonuses));
	}

	start(tile:number, dieSides:number, isDiceItemRandom:boolean, logger: ILogger) {
		this.distanceTravelled = 0;
		this.currentTileIndex = tile%this.board.length;
		this.diceItems = new DiceItemContainer(dieSides);
		this.dieSides = dieSides;
		this.isDiceItemRandom = isDiceItemRandom;
		this.simItems = {};
		this.usedDieItems = 0;
		this.continueTurn = false;
		this.logger = logger;
	}

	rollDie() {
		return Math.floor(this.dieSides*Math.random()+1);
	}
	setDicePriority(tile:number, dice: number[]) {
		this.board[tile].useDie = dice;
	}
	addDicePriority(tile:number, die: number) {
		const index = (tile+this.board.length)%this.board.length;
		this.board[index].useDie = this.board[index]?.useDie ?? [];
		this.board[index].useDie.push(die);
	}

	nextTurn() {
		this.move(this.rollDie());
		return this.process();
	}

	getCurrentLap() {
		return Math.floor(this.distanceTravelled/this.board.length);
	}

	move(tiles: number): [number, IBoardTile] {
		const nextTileIndex = (this.currentTileIndex+tiles) % this.board.length;
		if (nextTileIndex < this.currentTileIndex) {
			// New Lap
			const lap = this.getCurrentLap();
			this.logger.log(`Lap ${lap+1}`);
			if (this.lapBonuses[lap]?.length ) {
				for (const lapBonus of this.lapBonuses[lap]) {
					this.logger.log(`Lap Bonus: ${lapBonus.type} ${lapBonus.count}`)
					this.simItems[lapBonus.type] = (this.simItems[lapBonus.type] ?? 0) + lapBonus.count;
					if (lapBonus.type == "DiceItem") {
						const die = this.rollDie();
						this.logger.log(`Obtained Die ${die}`);
						this.diceItems.add(die);
					}
				}
			}
		}
		this.currentTileIndex = nextTileIndex;
		this.distanceTravelled += tiles;
		this.logger.log(`Moved ${tiles}. Now at ${this.getTile()[0]} | ${this.getTile()[1].type}  ${this.getTile()[1].count}`)
		return this.getTile();
	}

	getTile(): [number, IBoardTile] {
		return [this.currentTileIndex, this.board[this.currentTileIndex]];
	}

	getUsableDieItems() {
		const usables = this.isDiceItemRandom ? this.diceItems.faceArray : this.getTile()[1]?.useDie ?? [];
		return usables.filter((i) => this.diceItems[i] > 0);
	}

	process() {
		let [_, tile] = this.getTile();
		this.simItems[tile.type] = (this.simItems[tile.type] ?? 0) + tile.count;
		if(tile.type === "Movement") {
			this.distanceTravelled += tile.count;
			this.logger.log("Movement Tile");
			this.move(tile.count);
			this.continueTurn = true;
		} else if (tile.type == "DiceItem") {
			const die = this.rollDie();
			this.logger.log(`Obtained Die ${die}`);
			this.diceItems.add(die);
			if (this.isDiceItemRandom) {
				this.logger.log(`Immediately Using Die ${die}`);
				this.usedDieItems++;
				this.move(die);
				this.diceItems.use(die);
				this.continueTurn = true;
			} else {
				this.logger.log(`Storing Die ${die}`);
				this.continueTurn = false;
			}
		} else {
			this.logger.log("Dead Tile")
			this.continueTurn = false;
		}
		const diceItems = this.getUsableDieItems();
		if (!this.continueTurn && diceItems.length > 0) {
			this.logger.log(`Using Stored Die: ${diceItems[0]}`);
			this.usedDieItems++;
			this.move(diceItems[0]);
			this.diceItems.use(diceItems[0])
			this.continueTurn = true;
		}

		return this.getTile();
	}

	collect() {
		if (!this.isDiceItemRandom) {
			for (let i=1; i<=this.dieSides; i++) {
				if (this.diceItems[i] > 0) {
					const name = `_Unused Die ${i}`;
					this.simItems[name] = this.diceItems[i];
				}
			}
		}
		this.simItems["_Ending Tile"] = this.getTile()[0];
		this.simItems["_Distance Travelled"] = this.distanceTravelled;
		this.simItems["_Laps"] = this.getCurrentLap();
	}
}

interface IBoardTileProps {
	model: IBoardTile;
	index: number;
}

function BoardTileRenderer(props: IBoardTileProps) {
	const {model: tile, index: i} = props;
	// const imgSize = "100%";
	// let img = <span className="greyscale"><Images.Pyroxene size={imgSize}/></span>;
	// if (tile.type.endsWith("Eleph")) {
	// 	img = <Images.Eleph size={imgSize}/>;
	// } else if (tile.type === "Eligma") {
	// 	img = <Images.Eligma size={imgSize}/>;
	// }
	let imgSrc = "Raid_RankIcon_01.png";
	if (tile.type.endsWith("Eleph")) {
		imgSrc = "Item_Eleph.webp";
	} else if (tile.type === "Eligma") {
		imgSrc = "Item_Eligma.webp";
	} else if (tile.type.endsWith("Permit")) {
		if (tile.type.startsWith("Grey")) {
			imgSrc = "Item_Report1.webp";
		} else if (tile.type.startsWith("Blue")) {
			imgSrc = "Item_Report2.webp";
		} else if (tile.type.startsWith("Yellow")) {
			imgSrc = "Item_Report3.webp";
		} else if (tile.type.startsWith("Purple")) {
			imgSrc = "Item_Report4.webp";
		}
	} else if (tile.type === "Credit") {
		imgSrc = "Item_Credit.webp";
	}
	return (<div className="tile">
		<Card>
			<Card.Header>
				<Card.Title>
					#{i}
				</Card.Title>
			</Card.Header>
			<Card.Body>
				<Card.Img width={"100%"} src={`/img/${imgSrc}`}/>
				{/* {img} */}
				<Card.Text>{tile.type} x{tile.count.toLocaleString()}</Card.Text>
			</Card.Body>
			
				{!!tile.useDie?.length && <Card.Footer><Card.Text>Use Die: {JSON.stringify(tile.useDie ?? [], null, 4)}</Card.Text></Card.Footer>}
			
		</Card>
	</div>);


	// return (<Col key={i} xs={6}><Card>
	// 	<Card.Title>{i} - {tile.type} - {tile.count}</Card.Title>
	// 	<Card.Body><Card.Text>Use Die: {JSON.stringify(tile.useDie ?? [], null, 4)}</Card.Text></Card.Body>
	// </Card></Col>);
}

export default function Runner(props: IProps) {
	const [template, setTemplate] = useState(props.template ?? "Custom");
	const [simulationCount, setSimulationCount] = useState(1);
	const [diceRolls, setDiceRolls] = useState(1000);
	const [dieSides, setDieSides] = useState(6);
	const [startTile, setStartTile] = useState(0);
	const [isDiceItemRandom, setIsDiceItemRandom] = useState(false);
	const [isLogged, setIsLogged] = useState(false);
	const [simulationResults, setSimulationResults] = useState<IItemCtr[]>([]);
	const [dicePriorities, setDicePriorities] = useState<number[][]>([[],[],[],[],[],[]]);
	const dieFaces = useMemo(() => {
		const temp = [];
		for(let i=1; i<=dieSides; i++)
			temp.push(i);
		return temp;
	}, [dieSides]);
	useEffect(() => {
		
		let ignore = false;
		let temp = [];
		for(let i=1; i<=dieSides; i++)
			temp.push(NaN);
		if (!ignore) {
			setDicePriorities(temp);
		}
		return () => {
			ignore = true;
		}
	}, [dieSides]);
	const board:Board = useMemo(() => {
		const temp = new Board(BOARD_TEMPLATES[template], LAP_BONUS_TEMPLATES[template]);
		for(let i=0; i<dicePriorities.length; i++) {
			try {
				for (const prio of dicePriorities[i]) {
					if (!Number.isNaN(prio))
						temp.addDicePriority(prio-(i+1), i+1);
				}
			} catch (e) {
				
			}
		}
		return temp;
	}, [template, dicePriorities]);

	class Logger implements ILogger {
		log(str:string) {
			if (isLogged) console.log(str);
		}
	}

	const logger = new Logger();

	function runSimulation() {
		const results:IItemCtr[] = [];
		for (let sim=0; sim<simulationCount; sim++) {
			board.start(startTile, dieSides, isDiceItemRandom, logger);
			for (let dieCtr=0; dieCtr<diceRolls; dieCtr++) {
				logger.log(`Turn ${dieCtr+1}`);
				board.nextTurn();
				while(board.continueTurn) {
					board.process();
				}
			}
			board.collect();
			results.push(board.simItems);
			logger.log(`Number of Die Tickets used: ${board.usedDieItems}`);
			logger.log(`Unused Die Tickets: ${board.diceItems}`);

		}

		setSimulationResults(results);
	}

	function SimulationResults({results}: {results: IItemCtr[]}) {
		class ItemCtrTableWrapper implements ITableRow {
			getHeaders () {
				if (this.sims > 1) return ["Item", "Total", "Average Per Simulation", "Average Per Die Roll"];
				return ["Item", "Total", "Average Per Die Roll"];
			};
			toArray () {
				if (this.sims > 1)
					return [this.key,
						this.result.toLocaleString(),
						(this.result/this.sims).toLocaleString(),
						(this.result/this.total).toLocaleString()]
				return [this.key,
					this.result.toLocaleString(),
					(this.result/this.total).toLocaleString()]
			}
			toKeyValues() {
				if (this.sims > 1)
					return {
						Item: this.key,
						Total: this.result.toLocaleString(),
						"Average Per Simulation": (this.result/this.sims).toLocaleString(),
						"Average Per Die Roll": (this.result/this.total).toLocaleString()
					}
				return {
					Item: this.key,
					Total: this.result.toLocaleString(),
					"Average Per Die Roll": (this.result/this.total).toLocaleString()
				}
			};
			key: string;
			result: number;
			sims: number;
			total: number;
			constructor(key:string, result: number, total: number, sims?: number) {
				this.key = key;
				this.result = result;
				this.total = total;
				this.sims = sims ?? 1;
			}
		}

		const overall = results.reduce((accumulator, currentValue) => {
			const keys = Object.keys(currentValue);
			for (const key of keys)
				accumulator[key] = (accumulator[key] ?? 0) + currentValue[key];
			return accumulator;
		}, {})

		const keys = Object.keys(overall).sort();
		const overallData:ItemCtrTableWrapper[] = [];
		for (const key of keys) {
			overallData.push(new ItemCtrTableWrapper(key, overall[key], simulationCount*diceRolls, simulationCount));
		}
		return (<Row>
			<Col>
				<Row>
					<Col>
						<Row>
							<Col><h4 className="text-pink">Overall Simulation</h4></Col>
						</Row>
						<Row>
							<Col>
								<MyTable padding="0.1em" align="right" variant="violet" data={overallData}></MyTable>
							</Col>
						</Row>
					</Col>
				</Row>
				{results.map((sim, simi) => {
					if (simi > 20) return null;
					const keys = Object.keys(sim).sort();
					const data:ItemCtrTableWrapper[] = [];
					for (const key of keys) {
						data.push(new ItemCtrTableWrapper(key, sim[key], diceRolls));
					}
					return (<Row key={`simulation_${simi}`}>
						<Col>
							<Row>
								<Col><h4 className="text-pink">Simulation #{simi+1}</h4></Col>
							</Row>
							<Row>
								<Col>
									<MyTable padding="0.1em" align="right" variant="violet" data={data}></MyTable>
								</Col>
							</Row>
						</Col>
					</Row>)
				})}
			</Col>
		</Row>);
	}

	return (<>
	<Row>
		<Col>
			{template == "Saki" && <Row>
				<Col>
					<Alert>Does NOT take into consideration the Lap Bonuses. (80 Elephs). Though the Die Tickets are NOW considered. Plith undastandt. I did this in 3 hours right after xmas with fam</Alert>
				</Col>
			</Row>}
			<Row>
				<Col>
					<h1 className="text-pink">{template} Simulator</h1>
				</Col>
			</Row>
			<Row>
				<Col>
					<Row style={styles.filterRow}>
						<Col md={2}>
							<label>Simulations</label>
						</Col>
						<Col>
							<input 
								type="number"
								step={1}
								value={simulationCount}
								onChange={(event)=>{
									event.target.value = event.target.value.replaceAll(/[^\d]/g,"");
									if (event.target.value.length) {
										const val = parseInt(event.target.value);
										setSimulationCount(val);
									} else {
										setSimulationCount(1000);
									}
									setSimulationResults([]);
								}}
								>
							</input>
						</Col>
					</Row>
					<Row style={styles.filterRow}>
						<Col md={2}>
							<label>Turns per Simulation</label>
						</Col>
						<Col>
							<input 
								type="number"
								step={100}
								value={diceRolls}
								onChange={(event)=>{
									event.target.value = event.target.value.replaceAll(/[^\d]/g,"");
									if (event.target.value.length) {
										const val = parseInt(event.target.value);
										setDiceRolls(val);
									} else {
										setDiceRolls(1000);
									}
									setSimulationResults([]);
								}}
								>
							</input>
						</Col>
					</Row>
					<Row>
						<Col md={2}>
						</Col>
						<Col>
							<div key="isLogged" className="mb-3">
								<Form.Check 
								checked={isLogged}
								type="switch"
								id="isLogged"
								label="Enable Console Trace Logging"
								onChange={(a)=>{
									setIsLogged(a.target.checked);
								}}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col md={2}>
						</Col>
						<Col>
							<div key="isRandom" className="mb-3">
								<Form.Check 
								checked={isDiceItemRandom}
								type="switch"
								id="isRandom"
								label="Immediately use Dice Items"
								onChange={(a)=>{
									setIsDiceItemRandom(a.target.checked);
								}}
								/>
							</div>
						</Col>
					</Row>
					{!isDiceItemRandom && <>
						<Row>
							<Col><h4 className="text-pink">Only Use Die to Reach Tile _ :</h4></Col>
						</Row>
						<Row>
							<Col>You can now set multiple tile targets per die. Separate them with comma (,)</Col>
						</Row>
						
						{dieFaces?.map(face => {
							return (<Row key={`die_${face}`} style={styles.filterRow}>
								<Col md={2}>
									<label>Die {face}: </label>
								</Col>
								<Col>
									<input 
										type="text"
										step={1}
										// value={dicePriorities[face-1]?.join(",")}
										onChange={(event)=>{
											event.target.value = event.target.value.replaceAll(/[^\d,]/g,"");
											if (event.target.value.length) {
												const spl = event.target.value.split(",");
												const prios = [];
												for (const tile of spl) {
													if (tile.length) {
														prios.push((parseInt(tile)+board.board.length) % board.board.length);
													}
												}
												dicePriorities[face-1] = [...new Set([...prios])];
												// event.target.value = dicePriorities[face-1].join(",");
												setDicePriorities([...dicePriorities]);
											} else {
												dicePriorities[face-1] = [];
												setDicePriorities([...dicePriorities]);
											}
										}}
										>
									</input>
								</Col>
								{
									dicePriorities[face-1].map && dicePriorities[face-1]?.map(prio => <Col key={`prio_${prio}`}>{board.board[prio]?.type} {board.board[prio]?.count}</Col>)
								}
							</Row>)
						})}
					</>}
				</Col>
			</Row>



			<Button onClick={runSimulation}>Run Simulation</Button>Might be Slow. {isLogged && "Check Console for Progress..."}
			{/* <Row><Col> */}
				<div className="board" style={styles.board}>

				{board.board.map( (tile, i) => {
					return <BoardTileRenderer key={i} index={i} model={tile}/>
					// return (<Col key={i} xs={6}><Card>
					// 	<Card.Title>{i} - {tile.type} - {tile.count}</Card.Title>
					// 	<Card.Body><Card.Text>Use Die: {JSON.stringify(tile.useDie ?? [], null, 4)}</Card.Text></Card.Body>
					// </Card></Col>)
				})}
				</div>
				{/* </Col>
			</Row> */}
			<SimulationResults results={simulationResults} />
			{/* {JSON.stringify(simulationResults, null, 4)} */}
		</Col>
	</Row>
	</>)
}