import { ParagraphString } from "Components/ParagraphString";
import { TagBadge } from "Components/TagBadge";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";

export class BorrowStudentRaidRow extends React.Component<{ label: string; model: string; }> {
	render() {
		const { label, model } = this.props;
		if (!model)
			return <></>;
		if ([
				"Wait", 
				// "No",
			].includes(model)) {
			return (
				<Row style={{
					display: "flex",
					alignItems: "center",
					verticalAlign: "center"
				}}>
					<Col xs={6} sm={4} md={3} lg={2}><Card.Subtitle style={{ marginTop: 5 }}><TagBadge>{label}</TagBadge></Card.Subtitle></Col>
					<Col><TagBadge>{model}</TagBadge></Col>
				</Row>
			);
		}
		if (model.length < 20 && !model.includes("\n")) 
			return (<Row>
				<Col xs={6} sm={4} md={3} lg={2}><Card.Subtitle style={{ marginTop: 5 }}><TagBadge>{label}</TagBadge></Card.Subtitle></Col>
				<Col style={{
					display: "flex",
					alignItems: "center",
					verticalAlign: "center"
				}}>{model}</Col>
			</Row>
			);
		return (<Row><Col>
			<Card.Subtitle style={{ marginTop: 5 }}><TagBadge>{label}</TagBadge></Card.Subtitle>
			<ParagraphString>{model}</ParagraphString>
		</Col></Row>
		);
	}
}
