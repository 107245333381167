import { ICellData } from "Interfaces/ICellData";
import { IParser } from "Interfaces/IParser";
import { ITableRow } from "Interfaces/ITableRow";
import moment from "moment";

const dateNowObj = moment(moment().format("YYYY-MM-DD"), "YYYY-MM-DD");

export default class BannerStudent implements 
		IParser<BannerStudent>, 
		ITableRow {
	public name:string;
	public tldr:string;
	public start:string;
	public eta:number;
	public mustPullIf:string;
	public easySkipIf:string;
	public useCases:string;
	public whatDo:string;
	public investments:string;
	public rants:string;
	public comments:string;
	public farmable:string;
	public updated:string;

	getHeaders() {
		return [
			"name",
			"tldr",
			"start",
			"eta",
			"mustPullIf",
			"easySkipIf",
			"useCases",
			"whatDo",
			"investments",
			"rants",
			"comments",
			"farmable",
			"updated",
		];
	}

	getEta() {
		const raw = Math.abs(this.eta);
		return raw + " day" + (raw>1 ? 's' : '') + (this.eta < 0 ? " ago" : "");
	}
	toArray() {
		return [
			this.name,
			this.tldr,
			this.start,
			this.eta.toString(),
			this.mustPullIf,
			this.easySkipIf,
			this.useCases,
			this.whatDo,
			this.investments,
			this.rants,
			this.comments,
			this.farmable,
			this.updated,
		];
	}
	toKeyValues() {
		return {
			name: this.name,
			tldr: this.tldr,
			start: this.start,
			eta: this.eta.toString(),
			mustPullIf: this.mustPullIf,
			easySkipIf: this.easySkipIf,
			useCases: this.useCases,
			whatDo: this.whatDo,
			investments: this.investments,
			rants: this.rants,
			comments: this.comments,
			farmable: this.farmable,
			updated: this.updated,
		};
	}
	
	parse(data:ICellData[]): BannerStudent {
		try {
			return BannerStudent.parse(data);
		} catch {
			return null;
		}
	}

	static parse(data:ICellData[]): BannerStudent {
		let student = new BannerStudent();
		if (!!!data[0]?.v) return null;
		student.name = data[0]?.v ?? '';
		student.tldr = data[2]?.v ?? '';
		student.start = data[3]?.f ?? '';
		student.eta = BannerStudent.parseEta(data);
		student.mustPullIf = data[5]?.v ?? '';
		student.easySkipIf = data[6]?.v ?? '';
		student.useCases = data[7]?.v ?? '';
		student.whatDo = data[8]?.v ?? '';
		student.investments = data[9]?.v ?? '';
		student.rants = data[10]?.v ?? '';
		student.comments = data[11]?.v ?? '';
		student.farmable = data[12]?.v ?? '';
		student.updated = data[13]?.f ?? '';

		return student;
	}
	
	static parseEta(data:ICellData[]): number {
		const eta = moment(data[3]?.f ?? '', "YYYY-MMM-DD").diff(dateNowObj, "days");
		if (Number.isNaN(eta))
			return moment(data[3]?.f ?? '', "YYYY-MM-DD").diff(dateNowObj, "days")
		return eta
	}
}