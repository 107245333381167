import Student from "Models/Student";

export default class TeamFormation {
	strikers: Student[];
	specials: Student[];
	unit: number;
	type: string;
	private studentNames: string[];
	toString() {
		return `${this.strikers.join('\t')}\t${this.specials.join('\t')}`;
	}

	constructor(strikers: Student[], specials: Student[]) {
		this.strikers = strikers;
		this.specials = specials;
	}

	setType(type: string) {
		this.type = type;
	}

	setUnit(unit: number) {
		this.unit = unit;
		for (const student of this.strikers) {
			student.unit = unit;
		}
		for (const student of this.specials) {
			student.unit = unit;
		}
	}

	asKey() {
		const toRet = [];
		for (const student of this.strikers)
			toRet.push(student.getId());
		const specs = [];
		for (const student of this.specials)
			specs.push(student.getId());
		for (const student of specs.sort())
			toRet.push(student);

		return toRet.join("_");
		// return toRet.sort().join("_");
	}

	getNames() {
		if (this.studentNames) return this.studentNames;
		const toRet = [];
		for (const student of this.strikers)
			toRet.push(student.getName());
		for (const student of this.specials)
			toRet.push(student.getName());
		this.studentNames = toRet;

		return toRet;
	}

	getStudents() {
		return [...this.strikers, ...this.specials];
	}
}