
import { ILayoutProps } from 'Interfaces/Props/ILayoutProps';
import * as React from 'react';
export default abstract class BaseLayout extends React.Component<ILayoutProps>{
	componentDidMount(){
		document.title = this.props.name;
		const analytics = document.createElement("script");
		analytics.innerHTML = `
		window.dataLayer = window.dataLayer || [];
		function gtag(){dataLayer.push(arguments);}
		gtag('js', new Date());
		gtag('config', 'G-7XSGPR7S8J', {
			page_path: window.location.pathname,
			page_title: "${this.props.name}"
		});`;
		document.getElementsByTagName("head")[0].append(analytics)
	}
	
	abstract render(): JSX.Element;
}