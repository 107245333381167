import TeamFormation from "Models/TeamFormation";
import RaidReportItem from "Reports/RaidReportItem";

export default class RaidTeamReportItem extends RaidReportItem  {
	id: string;
	team: TeamFormation;
	count: number = 0;
	countPercent: string;
	static totalTeams: number = 1;

	constructor (id: string, team: TeamFormation, count: number) {
		super();
		this.id = id;
		this.team = team;
		this.count = count;
		this.countPercent = (100*count/RaidReportItem.totalPlayers).toLocaleString(undefined, { minimumFractionDigits: 4 })

	}
	export() {
		return `${this.team.getNames().join("\t")}\t${this.count}\t${this.countPercent}`
	}
	toString() {
		return `${this.team.getNames().join("\t")}
	Count: ${this.count} (${this.countPercent})`
	}
}