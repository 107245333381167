import { ICellData } from "Interfaces/ICellData";
import { IGenericStudentDisplayData } from "Interfaces/IGenericStudentDisplayData";
import { IGenericStudentTemplate } from "Interfaces/IGenericStudentTemplate";
import { IParser } from "Interfaces/IParser";
import { IUrlSource } from "Interfaces/IUrlSource";
export default class GenericStudent implements IParser<GenericStudent> {
	public name:string;
	public updated:string;
	public source:IUrlSource;
	public displayData:IGenericStudentDisplayData[] = [];
	public template: IGenericStudentTemplate;

	constructor(template: IGenericStudentTemplate) {
		this.template = template;
	}
	parse(data: ICellData[]): GenericStudent {
		return new GenericStudent(this.template).populate(data);
	}
	populate(data:ICellData[]): GenericStudent {
		this.name = data[this.template.name]?.v ?? '';
		this.updated = data[this.template.updated]?.f ?? null;
		this.source = this.template.source;

		for (const item of this.template.displayData) {
			this.displayData.push({
				index: item.index,
				label: item.label,
				content: data[item.index]?.v ?? item.content ?? '',
			});
		}
		
		return this;
	}
	static parse(data:ICellData[], template:IGenericStudentTemplate): GenericStudent {
		let student = new GenericStudent(template);

		return student.populate(data);
	}
}