import { ICellData } from "Interfaces/ICellData";
import { IParser } from "Interfaces/IParser";
import { ITableRow } from "Interfaces/ITableRow";

export default class StudentThoughts implements 
		IParser<StudentThoughts>,
		ITableRow {
	public name:string;
	public tldr:string;
	public updated:string;
	public earlyGame:string;
	public lateGameRaid:string;
	getHeaders() {
		return [
			"name",
			"tldr",
			"updated",
			"earlyGame",
			"lateGameRaid",
		]
	}
	toArray() {
		return [
			this.name,
			this.tldr,
			this.updated,
			this.earlyGame,
			this.lateGameRaid,
		]
	}
	toKeyValues() {
		return {
			name: this.name,
			tldr: this.tldr,
			updated: this.updated,
			earlyGame: this.earlyGame,
			lateGameRaid: this.lateGameRaid,
		}
	}

	parse(data:ICellData[]): StudentThoughts {
		try {
			return StudentThoughts.parse(data);
		} catch {
			return null;
		}
	}

	static parse(data:ICellData[]): StudentThoughts {
		let student = new StudentThoughts();
		student.name = data[1]?.v ?? '';
		let i:number=5;
		student.updated = data[i++]?.f ?? '';
		student.tldr = data[i++]?.v ?? '';
		student.earlyGame = data[i++]?.v ?? '';
		student.lateGameRaid = data[i++]?.v ?? '';
		
		return student;
	}
}