import React, { ComponentType, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";

interface Props {
	data: any[];
	itemsPerPage: number;
	params?: any;
	RowAs?: ComponentType<{model:any}>;
}

const MyPagination: React.FC<Props> = ({ data, itemsPerPage, RowAs, params }) => {
	const [activePage, setActivePage] = useState(1);

	const totalPages = Math.ceil((data?.length ?? 0) / itemsPerPage);
	useEffect(() => {
		setActivePage(1);
		}, [data.length]);
		const handlePageSelect = (newPage: number) => {
		newPage = Math.min(Math.max(1, newPage), totalPages);
		setActivePage(newPage);
	};
	if (totalPages < 1) return <>No Data</>;

	const renderPaginationItems = () => {
		const items = [];
		const pageRange = 2; // Number of page numbers to show on each side of the active page

		// "Jump to Page" input
		items.push(
			<li key="jump" className="page-item">
				<input
					style={{ height: "100%" }}
					type="number"
					min="1"
					max={totalPages}
					value={activePage}
					onChange={(e) => handlePageSelect(parseInt(e.target.value, 10))}
				/>
			</li>
		);

		// "First" page
		items.push(
			<Pagination.First key="first" onClick={() => handlePageSelect(1)} />
		);

		// "Prev" page
		items.push(
			<Pagination.Prev
			key="prev"
			onClick={() => handlePageSelect(activePage - 1)}
			disabled={activePage === 1}
			/>
		);

		// Page numbers
		for (
			let i = Math.max(1, activePage - pageRange);
			i <= Math.min(totalPages, activePage + pageRange);
			i++
		) {
			items.push(
			<Pagination.Item
				key={i}
				active={i === activePage}
				onClick={() => handlePageSelect(i)}
			>
				{i}
			</Pagination.Item>
			);
		}

		if (totalPages > activePage + pageRange) {
			items.push(<Pagination.Ellipsis key="ellipsis" disabled={true}></Pagination.Ellipsis>);
			items.push(
			<Pagination.Item
				key={"static_"+totalPages}
				active={totalPages === activePage}
				onClick={() => handlePageSelect(totalPages)}
			>
				{totalPages}
			</Pagination.Item>
			);
		}

		// "Next" page
		items.push(
			<Pagination.Next
			key="next"
			onClick={() => handlePageSelect(activePage + 1)}
			disabled={activePage === totalPages}
			/>
		);

		// "Last" page
		items.push(
			<Pagination.Last
			key="last"
			onClick={() => handlePageSelect(totalPages)}
			/>
		);

		return items;
	};

	const startIndex = (activePage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const currentPageData = data.slice(startIndex, endIndex);
	const PaginationItems = renderPaginationItems();
	return (
	<div>
		<Row>
			<Col>
				<Pagination>
					{PaginationItems}
				</Pagination>
			</Col>
		</Row>
		<Row>
			<Col>
				Showing <span className="text-pink">{(startIndex+1).toLocaleString()}</span> - <span className="text-pink">{(Math.min(endIndex, data.length)).toLocaleString()}</span> of <span className="text-pink">{data.length.toLocaleString()}</span>
			</Col>
		</Row>
		<Row>
			{currentPageData.map((item, index) => (
				RowAs
				? <RowAs key={index} model={item} {...params}></RowAs>
				: <p key={index}>{item.toString()}</p>
				))}
		</Row>
		<Row>
			<Col>
				<Pagination>
					{PaginationItems}
				</Pagination>
			</Col>
		</Row>
	</div>
	);
};

export default MyPagination;
