import IStyle from "Interfaces/IStyle";
import ImageGallery from "Modals/ImageGallery";
import React, { useMemo, useState } from "react";
import { Accordion, Alert, Col, Form, Row } from "react-bootstrap";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionItem from "react-bootstrap/esm/AccordionItem";

const styles:IStyle = {
	titleS: {
		fontSize: "3.6em",
		textAlign: "right",
		paddingRight: 0,
		marginTop: "-0.3em"
	},
	titleEX: {
		fontSize: "1.5em",
		textAlign: "left",
		paddingLeft: 0,
	},
	titleExtra: {
		padding: 0
	},
	spinnerRow: {
		zIndex: 10
	}
}
interface IStudentJSON {
	breakpoints: [from:number, to:number][],
	exp: number[]
}
type IStudentStatus = "Owned" | "Unowned" | "None";
function WarningMessage() {
	return (
		<Alert variant="warning" dismissible>
			This is still mainly WIP. 
		</Alert>
	)
}
export default function SparkRate() {
	const [base3StarPercent, setBase3StarPercent] = useState(3);
	const [banner2StarStatus, setBanner2StarStatus] = useState<IStudentStatus>("None");
	const [banner3StarStatus, setBanner3StarStatus] = useState<IStudentStatus>("Unowned");
	// const [spook3StarPercent, setSpook3StarPercent] = useState(0.025275)
	const [total3Stars, setTotal3Stars] = useState(92)
	const [owned3Stars, setOwned3Stars] = useState(69)
	const [total2Stars, setTotal2Stars] = useState(22)
	const [owned2Stars, setOwned2Stars] = useState(22)
	const [banner2StarPercent, banner3StarPercent] = [3, 0.7]
	const [banner2StarRate, banner3StarRate] = [banner2StarPercent/100, banner3StarPercent/100];
	const [isFes, setIsFes] = useState(false);
	const percents = useMemo(()=> {
		return {
			1: isFes ? 100-(18.5 + 2*base3StarPercent) : 100-(18.5 + base3StarPercent),
			2: 18.5,
			3: isFes ? 2*base3StarPercent : base3StarPercent,
		}
	}, [base3StarPercent, isFes]);
	const rates = useMemo(()=> {
		return {
			1: percents[1]/100,
			2: percents[2]/100,
			3: percents[3]/100,
		}
	}, [percents]);

	// const [rawTotal3Stars, setRawTotal3Stars] = useState(Math.round((percents[3] - banner3StarPercent)/spook3StarPercent)+1)
	const ligma = {
		1: 1,
		2: 10,
		3: 50
	};
	const [spook3StarPercent, displaySpook3StarPercent] = useMemo(()=>{
		const r = (percents[3] - banner3StarPercent)/(total3Stars-1);
		return [r, Math.round(r*1000000)/1000000]
	}, [total3Stars, percents[3], banner3StarPercent]);

	const unowned3Stars = useMemo(() => {
		return total3Stars-owned3Stars;
	}, [owned3Stars, total3Stars]);
	function calculateLigma(rate1:number, rate2:number, rate3:number) {
		return (rate1 * ligma[1]) 
			+ (rate2 * ligma[2])
			+ (rate3 * ligma[3])
	}

	const [totalLigma10, totalLigma10Solo, totalLigma200, totalLigma200Solo] = useMemo(()=>{
		let t10 = 0;
		const o3s = banner3StarStatus === "Owned" ? owned3Stars - 1 : owned3Stars;
		let unownedSpookRate = (rates[3]-banner3StarRate)*(((total3Stars-1)-o3s) / (total3Stars-1));
		let unownedBannerRate = banner3StarStatus === "Owned" ? 0 : banner3StarRate;
		
		// const o2s = banner3StarStatus === "Owned" ? owned2Stars - 1 : owned2Stars;
		// let unownedSpook2sRate = (rates[3]-banner2StarRate)*(((total2Stars-1)-o2s) / (total2Stars-1));
		// let unownedBanner2sRate = banner2StarStatus === "Unowned" ? banner2StarRate : 0;

		// Calculate Rolls 1-9
		for (let i=0; i<9; i++) {
			t10 += calculateLigma(rates[1], rates[2], rates[3]-(unownedSpookRate+unownedBannerRate));
			unownedBannerRate -= unownedBannerRate*banner3StarRate;
			unownedSpookRate -= unownedSpookRate*unownedSpookRate;
		}
		let t10s = t10;
		let sUnownedSpookRate = unownedSpookRate;
		let sUnownedBannerRate = unownedBannerRate;

		// Calculate Roll 10
		t10 += calculateLigma(0, rates[1]+rates[2], rates[3]-(unownedSpookRate+unownedBannerRate));
		unownedBannerRate -= unownedBannerRate*banner3StarRate;
		unownedSpookRate -= unownedSpookRate*unownedSpookRate;
		let t200 = t10;
		// Calculate Rolls 11-200
		for (let j=0; j<19; j++) {
			// Calculate Rolls 1-9
			for (let i=0; i<9; i++) {
				t200 += calculateLigma(rates[1], rates[2], rates[3]-(unownedSpookRate+unownedBannerRate));
				unownedBannerRate -= unownedBannerRate*banner3StarRate;
				unownedSpookRate -= unownedSpookRate*unownedSpookRate;
			}

			// Calculate Roll 10
			t200 += calculateLigma(0, rates[1]+rates[2], rates[3]-(unownedSpookRate+unownedBannerRate));
			unownedBannerRate -= unownedBannerRate*banner3StarRate;
			unownedSpookRate -= unownedSpookRate*unownedSpookRate;
		}
		
		// Calculate Roll 10 (Solo)
		t10s += calculateLigma(rates[1], rates[2], rates[3]-(sUnownedBannerRate+sUnownedSpookRate));
		sUnownedBannerRate -= sUnownedBannerRate*banner3StarRate;
		sUnownedSpookRate -= sUnownedSpookRate*sUnownedSpookRate;
		let t200s = t10s;

		// Calculate Rolls 11-200 (Solo)
		for (let i=0; i<190; i++) {
			t200s += calculateLigma(rates[1], rates[2], rates[3]-(sUnownedBannerRate+sUnownedSpookRate));
			sUnownedBannerRate -= sUnownedBannerRate*banner3StarRate;
			sUnownedSpookRate -= sUnownedSpookRate*sUnownedSpookRate;
		}
		return [t10, t10s, t200, t200s];	
	},[rates, owned3Stars, total3Stars, banner3StarStatus])
	
	return (<Row>
		<Col>
			<WarningMessage></WarningMessage>
			<Row>
				<Col xs={2}><label>Banner 2☆</label></Col>
				<Col>
					<Row>
						{["None", "Owned", "Unowned"].map((a:IStudentStatus) => {
							return <Col><Form.Check // prettier-ignore
								disabled
								checked={banner2StarStatus === a}
								onSelect={()=>setBanner2StarStatus(a)}
								onChange={()=>setBanner2StarStatus(a)}
								key={`2s_${a}`}
								type="radio"
								id={`2s_${a}`}
								label={a}
								// label={<TagBadge>{a}</TagBadge>}
							/></Col>
						})}	
					</Row>
				</Col>
			</Row>
			<Row>
				<Col xs={2}><label>Banner 3☆</label></Col>
				<Col>
					<Row>
						<Col><Form.Check // prettier-ignore
								key={`3s_None`}
								type="radio"
								disabled
								id={`3s_None`}
								label="None"
								// label={<TagBadge>{a}</TagBadge>}
							/></Col>
						{["Owned", "Unowned"].map((a:IStudentStatus) => {
							return <Col><Form.Check // prettier-ignore
								checked={banner3StarStatus === a}
								onSelect={()=>setBanner3StarStatus(a)}
								onChange={()=>setBanner3StarStatus(a)}
								key={`3s_${a}`}
								type="radio"
								id={`3s_${a}`}
								label={a}
								// label={<TagBadge>{a}</TagBadge>}
							/></Col>
						})}	
					</Row>
				</Col>
			</Row>
			<Row>
				<Col xs={2}><label>Total 1☆</label></Col>
				<Col>
					11
				</Col>
			</Row>
			<Row>
				<Col xs={2}><label>Owned 1☆</label></Col>
				<Col>
					11
				</Col>
			</Row>
			<Row>
				<Col xs={2}><label>Total 2☆ {banner2StarStatus !== "None" && " (Banner Inclusive)"}</label></Col>
				<Col>
				{total2Stars}
				</Col>
			</Row>
			
			<Row>
				<Col xs={2}><label>Owned 2☆ {banner2StarStatus !== "None" && " (Banner Inclusive)"}</label></Col>
				<Col>
					<Row>
						<Col>{owned2Stars}</Col>
					</Row>
				</Col>
			</Row>
			<Row>
				<Col xs={2}><label>Total 3☆ (Banner Inclusive)</label></Col>
				<Col>
					<Row>
						<Col>{total3Stars}</Col>
					</Row>
					<Row>
						<Col>
							<Row>
								<Col>
									<Row><Col>Raw:</Col></Row>
									<Row>
										<Col>
											<input type="number" max={10000} min={0} step={1} value={total3Stars} onChange={(e) => {
												const v = parseInt(e.target.value)
												setTotal3Stars(v);
												// const p = (percents[3]-banner3StarPercent)/(v-1);
												// setSpook3StarPercent(Math.round(p*1000000)/1000000);
											}}></input>
										</Col>
									</Row>
								</Col>
								<Col>
									<Row><Col>Calculate from Gacha Probability:</Col></Row>
									<Row>
										<Col>
											<input type="number" max={percents[3]-banner3StarPercent} step={0.01} min={0} value={displaySpook3StarPercent} onChange={(e) => {
												const v = parseFloat(e.target.value);
												
												setTotal3Stars(Math.round((percents[3] - banner3StarPercent)/v)+1);
												// setSpook3StarPercent(v);
											}}></input>%
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
						<Col>
							<Accordion>
								<AccordionItem eventKey="gacha-percent">
									<AccordionHeader>How to get Gacha Probability:</AccordionHeader>
									<AccordionBody>
										<ImageGallery title="How to get Gacha Probability" srcs={["/img/Tip_RateInfo.jpg", "/img/Tip_GachaProbability.jpg"]}></ImageGallery>
									</AccordionBody>
								</AccordionItem>
							</Accordion>
						</Col>
					</Row>
				</Col>
			</Row>
			
			<Row>
				<Col xs={2}><label>Owned 3☆ (Banner Inclusive)</label></Col>
				<Col>
					<Row>
						<Col>{owned3Stars}</Col>
					</Row>
					<Row>
						<Col>
							<Row>
								<Col>Raw:</Col>
								<Col>Calculate from Missing 3☆:</Col>
							</Row>
							<Row>
								<Col>
									<input type="number" max={total3Stars} min={0} step={1} value={owned3Stars} onChange={(e) => {
										const v = parseInt(e.target.value)
										setOwned3Stars(v);
									}}></input>
								</Col>
								<Col>
									<input type="number" max={total3Stars} min={0} step={1} value={unowned3Stars} onChange={(e) => {
										const v = parseInt(e.target.value)
										setOwned3Stars(total3Stars-v);
									}}></input>
								</Col>
							</Row>
						</Col>
						<Col>
							<Accordion>
								<AccordionItem eventKey="gacha-percent">
									<AccordionHeader>How to get Missing 3☆:</AccordionHeader>
									<AccordionBody>
										<ImageGallery title="How to get Missing 3☆" srcs={[
											"/img/Tip_HomeStudents.jpg", 
											"/img/Tip_StudentFilter.jpg",
											"/img/Tip_StarFilter.jpg",
											"/img/Tip_StudentScroll.jpg",
											"/img/Tip_StudentUnowned.jpg",
										]}></ImageGallery>
									</AccordionBody>
								</AccordionItem>
							</Accordion>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row>
				<Col md={2}>
				</Col>
				<Col>
					<div key="is-fes" className="mb-3">
						<Form.Check 
						checked={isFes}
						type="switch"
						id="is-fes"
						label="Fes Banner? (x2 3☆ rate)"
						onChange={(a)=>{
							setIsFes(a.target.checked);
						}}
						/>
					</div>
				</Col>
			</Row>
			<Row>
				<Col>Total Ligma 10-pull</Col><Col>{totalLigma10.toLocaleString(undefined, {maximumFractionDigits: 5})}</Col>
			</Row>
			<Row>
				<Col>Total Ligma 10x 1-pull</Col><Col>{totalLigma10Solo.toLocaleString(undefined, {maximumFractionDigits: 5})}</Col>
			</Row>
			<Row>
				<Col>Total Ligma 20x 10-pull</Col><Col>{totalLigma200.toLocaleString(undefined, {maximumFractionDigits: 5})}</Col>
			</Row>
			<Row>
				<Col>Total Ligma 200x 1-pull</Col><Col>{totalLigma200Solo.toLocaleString(undefined, {maximumFractionDigits: 5})}</Col>
			</Row>
		</Col>
	</Row>);
}