import StudentRenderer from "Components/ModelRenderers/StudentRenderer";
import MyPagination from "Components/MyPagination";
import { MyTable } from "Components/MyTable";
import { Sticky } from "Components/Sticky";
import IStyle from "Interfaces/IStyle";
import RaidTeamReportItem from "Reports/RaidTeamReportItem";
import { secondsToTime } from "Utils/Common";
import React, { useEffect, useMemo, useState } from "react";
  
import { StudentPicker } from "Components/StudentPicker";
import { IReports } from "Interfaces/IReports";
import { Badge, Button, CloseButton, Col, Modal, Nav, Row } from "react-bootstrap";
import { Line, Scatter } from "react-chartjs-2";
import { useParams } from "react-router-dom";
interface IProps {
	show: boolean;
	setShow: React.Dispatch<React.SetStateAction<boolean>>;
	reports: IReports;
	date: string;
	compact?: boolean;
	fullUnits?: boolean;
	senseis: number;
}
const commontabs = [
	"Raid",
	"Chart",
]
const tabs = [
	"Attendance",
	"Teams", 
	"Student",
	"Scatter"
]
const styles:IStyle = {
	statisticsNumber: {
		textAlign: "right"
	},
	badgeCounter: {
		textAlign: "center"
	},
	closeButton: {
		// textAlign: "right"
	}
}
export default function RaidReportModal(props:IProps) {
	const {server, season} = useParams();
	const {show, setShow, reports, senseis, date} = props;
	const displayedCommonTabs = commontabs
		.filter(ctab => {
			if (!reports.raid && ctab === "Raid") 
				return false;
			if (!reports.raid && ctab === "Chart") 
				return false;
			return true;
		}) ?? [];
	const displayedFullTabs = tabs
		.filter(ftab => {
			if (!reports.attendance && ftab === "Attendance") 
				return false;
			if (!reports.team && ftab === "Teams") 
				return false;
			if (!reports.studentStatistics && ftab === "Student") 
				return false;
			if (!reports.attendance && ftab === "Scatter") 
				return false;
			
			if (reports.attendance.length === 0 && ftab === "Attendance") 
				return false;
			if (reports.team.length === 0 && ftab === "Teams") 
				return false;
			if (reports.studentStatistics.length === 0 && ftab === "Student") 
				return false;
			if (reports.attendance.length === 0 && ftab === "Scatter") 
				return false;
			return true;	
		}) ?? [];
	const displayedTabs = [...displayedCommonTabs, ...displayedFullTabs];
	const [tab, setTab] = useState("#"+displayedTabs[0]);
	const [timeGroupBy, setTimeGroupBy] = useState(60);
	useEffect(()=>{
		setTab("#"+displayedTabs[0]);
	}, [show])
	const students = useMemo(()=>props.reports.attendance.map(r=>r.student), [props.reports.attendance])
	const handleCloseAttendanceReport = () => {
		setShow(false);
	}
	const {compact} = props;

	const Attendance = () => {
		return (<Row style={{padding: 5}}>
			{
				reports.attendance?.map(item => (
					<Col 
						key={`student-report-${item.student.getId()}`} 
						xs={12} 
						md={6} 
						lg={4} 
						xl={3}
					>
						<Row 
						className="bg-violet-transparent-05" style={{padding: 10,
							margin: 5,
							border: "1px solid",
							// borderColor: "violet",
							borderRadius: 20,
							borderColor: "pink",
						}}>
							<Col>
								<Row>
									<Col xs={3} lg={5} style={{margin: 5}}>
										<StudentRenderer bare model={item.student} />
									</Col>
									<Col>
										<Row>
											Total: {item.assist.total+item.attendance.total}
										</Row>
										<Row>
											<Col xs={12} xl={12}>
												<Row>
													Senseis:
												</Row>
												<Row>
													<Col xs={3}>
														{item.senseis.total}
													</Col>
													<Col>
													{item.senseisPercent}%
													</Col>
												</Row>
											</Col>
											<Col xs={12} xl={12}>
												<Row>
													Attendance:
												</Row>
												<Row>
													<Col xs={3}>
														{item.attendance.total}
													</Col>
													<Col>
													{item.attendancePercent}%
													</Col>
												</Row>
											</Col>
											<Col>
												<Row>
													Assist: 
												</Row>
												<Row>
													<Col xs={3}>
														{item.assist.total}
													</Col>
													<Col>
													{item.assistPercent}%
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
								</Row>
								<Row>
									<StudentDistribution bg="violet" model={item.attendance}></StudentDistribution>
									<StudentDistribution bg="pink-border" label={"(A)"} model={item.assist}></StudentDistribution>
								</Row>
							</Col>
						</Row>
					</Col>
				))
			}
			</Row>);
	}
	function TeamReportItemRenderer(props: {model: RaidTeamReportItem}) {
		const item = props.model;
		return (
			
			<Col 
			key={`student-report-${item.id}`} 
			xs={12} 
			xl={6} 
		>
			<Row 
			className="bg-violet-transparent-05" style={{padding: 10,
				margin: 5,
				border: "1px solid",
				// borderColor: "violet",
				borderRadius: 20,
				borderColor: "pink",
			}}>
				
				<Col xs={12} lg={12} style={{margin: 5}}>
					<Row>
						<Col xs={3} md={2}><StudentRenderer compact={compact} bare alwaysFooter model={item.team.strikers[0]} /></Col>
						<Col xs={3} md={2}><StudentRenderer compact={compact} bare alwaysFooter model={item.team.strikers[1]} /></Col>
						<Col xs={3} md={2}><StudentRenderer compact={compact} bare alwaysFooter model={item.team.strikers[2]} /></Col>
						<Col xs={3} md={2}><StudentRenderer compact={compact} bare alwaysFooter model={item.team.strikers[3]} /></Col>
						<Col xs={3} md={2}><StudentRenderer compact={compact} bare alwaysFooter model={item.team.specials[0]} /></Col>
						<Col xs={3} md={2}><StudentRenderer compact={compact} bare alwaysFooter model={item.team.specials[1]} /></Col>
					</Row>
				</Col>
				<Col>
					<Row>
						<Col xs={12} xl={12}>
							<Row>
								<Col><h6 className="text-pink">
									<CloseButton style={styles.statisticsNumber} aria-label="Clear-newstudents" onClick={(e:any)=>{
										// console.log(e.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement);
										e.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.remove();
									}}/>
									Total</h6></Col>
								{/* <Col><h6 className="text-pink">Total</h6></Col> */}
							</Row>
							<Row>
								<Col>{item.count} ({item.countPercent}%)</Col>
							</Row>
							<Row>
								<Col xs={12} md={8}>
									<Row>
										<h6 className="text-pink">Score</h6>
									</Row>
									<Row>
										<Col xs={2}>Mean:</Col>
										<Col xs={4} style={styles.statisticsNumber}>{Math.round(item.score.meanWith).toLocaleString()}</Col>
										<Col xs={2}>Max:</Col>
										<Col xs={4} style={styles.statisticsNumber}>{item.score.maxWith.toLocaleString()}</Col>
										<Col xs={2}>Mid:</Col>
										<Col xs={4} style={styles.statisticsNumber}>{Math.round(item.score.medianWith).toLocaleString()}</Col>
										<Col xs={2}>Min:</Col>
										<Col xs={4} style={styles.statisticsNumber}>{item.score.minWith.toLocaleString()}</Col>
									</Row>
								</Col>
								<Col>
									<Row>
										<h6 className="text-pink">Team</h6>
									</Row>
									<Row>
										<Col xs={6}>
											Max: {item.teams.maxWith}
										</Col>
										<Col xs={6}>
											Mid: {item.teams.medianWith}</Col>
										<Col xs={6}>
											Min: {item.teams.minWith}
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				</Col>
			</Row>
		</Col>
		)
	}
	const Teams = () => {
		return (<Row style={{padding: 5}}>
			<MyPagination data={reports.team ?? []} itemsPerPage={50} RowAs={TeamReportItemRenderer}></MyPagination>
			{/* {reports.team.map( item => (
			))} */}
		</Row>)
	}
	const StudentDistribution = ({model, bg, label}:{bg: string, label?:string|React.ReactNode,model:{total:number,[key:string]:number}}) => {
		if (model.total == 0) return;
		const keys = Object.keys(model).filter(a=>a!=="total").sort();
		return (<>
		{keys.map(a => (
			<Col key={a} style={styles.badgeCounter}><Badge bg={bg}>{label} {a}: {model[a].toLocaleString()}</Badge></Col>
		))}
		</>);
	}

	const StudentReport = () => {
		return (<Row style={{padding: 5}}>
			{
				reports.studentStatistics?.map(item => (
					<Col 
						key={`student-statistics-report-${item.student.getId()}`} 
						xs={12} 
						md={6}
					>
						<Row 
						className="bg-violet-transparent-05" style={{padding: 10,
							margin: 5,
							border: "1px solid",
							// borderColor: "violet",
							borderRadius: 20,
							borderColor: "pink",
						}}>
							<Col xs={3} lg={5} style={{margin: 5}}>
								<Row><Col style={styles.closeButton}><CloseButton aria-label="Clear-newstudents" onClick={(e:any)=>{
									// console.log(e.target.parentElement.parentElement.parentElement.parentElement.parentElement);
									e.target.parentElement.parentElement.parentElement.parentElement.parentElement.remove();
								}}/></Col></Row>
								<Sticky offset={0}>
									<StudentRenderer bare model={item.student} />
									<Row>
										<StudentDistribution bg="violet" model={item.attendance}></StudentDistribution>
										<StudentDistribution bg="pink-border" label={"(A)"} model={item.assist}></StudentDistribution>
									</Row>
								</Sticky>
							</Col>
							<Col>
							
								<Row>
									<Col xs={12} xl={12}>
										<Row>
											<h5 className="text-pink">Sensei</h5>
										</Row>
										<Row>
											<Col>With:</Col>
											<Col style={styles.statisticsNumber}>{item.score.withCount.toLocaleString()}</Col>
										</Row>
										<Row>
											<Col>Without:</Col>
											<Col style={styles.statisticsNumber}>{item.score.withoutCount.toLocaleString()}</Col>
										</Row>
										<hr />
										<Row>
											<h5 className="text-pink">Score</h5>
										</Row>
										<Row>
											<Col xs={4} md={12} xl={4}>Mean With:</Col>
											<Col xs={4} md={6} xl={4} style={styles.statisticsNumber}>{Math.round(item.score.meanWith).toLocaleString()}</Col>
											<Col xs={4} md={6} xl={4} style={styles.statisticsNumber}>{secondsToTime(item.time.meanWith)}</Col>
										</Row>
										<Row>
											<Col xs={4} md={12} xl={4}>Mean Without:</Col>
											<Col xs={4} md={6} xl={4} style={styles.statisticsNumber}>{Math.round(item.score.meanWithout).toLocaleString()}</Col>
											<Col xs={4} md={6} xl={4} style={styles.statisticsNumber}>{secondsToTime(item.time.meanWithout)}</Col>
										</Row>
										<Row>
											<Col xs={4} md={12} xl={4}>Mean Diff:</Col>
											<Col xs={4} md={6} xl={4} style={styles.statisticsNumber}>{Math.round(item.score.meanWith-item.score.meanWithout).toLocaleString()}</Col>
											<Col xs={4} md={6} xl={4} style={styles.statisticsNumber}>{secondsToTime(item.time.meanWith-item.time.meanWithout)}</Col>
										</Row>
										<br></br>
										<Row>
											<Col xs={4} md={12} xl={4}>Max With:</Col>
											<Col xs={4} md={6} xl={4} style={styles.statisticsNumber}>{item.score.maxWith.toLocaleString()}</Col>
											<Col xs={4} md={6} xl={4} style={styles.statisticsNumber}>{secondsToTime(item.time.maxWith)}</Col>
										</Row>
										<Row>
											<Col xs={4} md={12} xl={4}>Median With:</Col>
											<Col xs={4} md={6} xl={4} style={styles.statisticsNumber}>{Math.round(item.score.medianWith).toLocaleString()}</Col>
											<Col xs={4} md={6} xl={4} style={styles.statisticsNumber}>{secondsToTime(item.time.medianWith)}</Col>
										</Row>
										<Row>
											<Col xs={4} md={12} xl={4}>Min With:</Col>
											<Col xs={4} md={6} xl={4} style={styles.statisticsNumber}>{item.score.minWith.toLocaleString()}</Col>
											<Col xs={4} md={6} xl={4} style={styles.statisticsNumber}>{secondsToTime(item.time.minWith)}</Col>
										</Row>
										<br></br>
										<Row>
											<Col xs={4} md={12} xl={4}>Max Without:</Col>
											<Col xs={4} md={6} xl={4} style={styles.statisticsNumber}>{item.score.maxWithout.toLocaleString()}</Col>
											<Col xs={4} md={6} xl={4} style={styles.statisticsNumber}>{secondsToTime(item.time.maxWithout)}</Col>
										</Row>
										<Row>
											<Col xs={4} md={12} xl={4}>Min Without:</Col>
											<Col xs={4} md={6} xl={4} style={styles.statisticsNumber}>{item.score.minWithout.toLocaleString()}</Col>
											<Col xs={4} md={6} xl={4} style={styles.statisticsNumber}>{secondsToTime(item.time.minWithout)}</Col>
										</Row>
										<hr />
										<Row>
											<h5 className="text-pink">Team</h5>
										</Row>
										<Row>
											<Col>Mean With:</Col>
											<Col style={styles.statisticsNumber}>{item.teams.meanWith.toLocaleString()}</Col>
										</Row>
										<Row>
											<Col>Max With:</Col>
											<Col style={styles.statisticsNumber}>{item.teams.maxWith.toLocaleString()}</Col>
										</Row>
										<Row>
											<Col>Median With:</Col>
											<Col style={styles.statisticsNumber}>{item.teams.medianWith.toLocaleString()}</Col>
										</Row>
										<Row>
											<Col>Min With:</Col>
											<Col style={styles.statisticsNumber}>{item.teams.minWith.toLocaleString()}</Col>
										</Row>
										<br></br>
										<Row>
											<Col>Max Without:</Col>
											<Col style={styles.statisticsNumber}>{item.teams.maxWithout.toLocaleString()}</Col>
										</Row>
										<Row>
											<Col>Min Without:</Col>
											<Col style={styles.statisticsNumber}>{item.teams.minWithout.toLocaleString()}</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				))
			}
		</Row>);
	}
	const MyChart = () => {
		// https://react-chartjs-2.js.org/examples/area-chart
		const chartColor = {
			TOR: {
				borderColor: 'rgb(162, 53, 235)',
				backgroundColor: 'rgba(162, 53, 235, 0.5)',
			},
			INS: {
				borderColor: 'rgb(224, 60, 164)',
				backgroundColor: 'rgba(224, 60, 164, 0.5)',
			},
			EXT: {
				borderColor: 'rgb(224, 60, 79)',
				backgroundColor: 'rgba(224, 60, 79, 0.5)',
			}
		}
		// const start = reports.raid.timesMinMax[0]-();

		const xAxisTime = Array.from({length: (2+reports.raid.timesMinMax[0]%timeGroupBy)+reports.raid.timesMinMax[1]-reports.raid.timesMinMax[0]}, (_, i) => (i)).map(a=>secondsToTime(a));
		const xAxisRanks = Array.from({length: reports.raid.scores.length}, (_,i)=>1+i);
		const PeoplePerSecondChart = () => {
			const options = {
				responsive: true,
				plugins: {
				  legend: {
					// labels: {
					// 	color: 'white'
					// },
					// title: {
					// 	color: 'white'
					// }
				  },
				  title: {
					display: false,
					text: 'People per Second',
					// color: 'white',
				  },
				},
			  };
			  const dataset = {
				  labels: xAxisTime,
				  datasets: Object.keys(reports.raid.timesCount).filter(a=>["N","VH","H","HC"].indexOf(a) < 0).map((v) => {
					  return {
						  fill: true,
						  label: v,
						  data: reports.raid.timesCount[v],
						  ...chartColor[v]
						}
					  }
				  ),
				};

			return <Line options={options} data={dataset} />;
		}
		
		const RankScoreChart = () => {
			const options = {
				responsive: true,
				plugins: {
				  legend: {
					display: false,
				  },
				  title: {
					display: false,
					text: 'Rank vs Score',
					size: 16
				  },
				}
			  };
			  const dataset = {
				  labels: xAxisRanks,
				  datasets: [{
					fill: false,
					label: "Scores",
					data: reports.raid.scores,
					...chartColor.TOR
				  }],
				};

			return <Line options={options} data={dataset} />;
		}

		const RankTimeChart = () => {
			const options = {
				responsive: true,
				plugins: {
				  legend: {
					display: false,
				  },
				  title: {
					display: false,
					text: 'Rank vs Time',
				  },
				},
			  };
			  const dataset = {
				  labels: xAxisRanks,
				  datasets: [{
					fill: false,
					label: "Times",
					data: reports.raid.times,
					...chartColor.TOR
				  }],
				};

			return <Line options={options} data={dataset} />;
		}
		  return (<Row>
			<Col>
				<Row><Col><h4 className="text-pink">People per Second</h4></Col></Row>
				<Row><Col><PeoplePerSecondChart></PeoplePerSecondChart></Col></Row>
				<hr></hr>
				<Row><Col><h4 className="text-pink">Rank vs Score</h4></Col></Row>
				<Row><Col><RankScoreChart></RankScoreChart></Col></Row>
				<hr></hr>
				<Row><Col><h4 className="text-pink">Rank vs Time</h4></Col></Row>
				<Row><Col><RankTimeChart></RankTimeChart></Col></Row>
			</Col>
		  </Row>);
	}
	const RaidReport = () => {
		if (!reports.raid) return null;
		const data = reports.raid?.timeTableGroupedBy(timeGroupBy);
		const topCheck = [1,2,3,4,5,6,7,8,9,10,20,50,100,1000,2000,3000,4000,5000,6000,7000,8000,9000,10000,11000,12000]
		
		return (
		<Row>
			<Col>
				<Row><Col><h3 className="text-pink">Score</h3></Col></Row>
				<Row>
					<Col xs={12} md={6} lg={4} xl={3}>
						<Row><Col><h4 className="text-pink">Top</h4></Col></Row>
						<MyTable padding="0.1em" align="right" variant="violet" data={reports.raid.topRankTable(topCheck)}></MyTable>
					</Col>
				{Object.keys(data).filter(a=>["N","VH","H","HC"].indexOf(a) < 0).map((diff) => {
					return (
						<Col key={diff} xs={12} md={6} lg={4} xl={3}>
								<Row><Col><h4 className="text-pink">{diff}</h4></Col></Row>
								<MyTable padding="0.1em" align="right" variant="violet" data={data[diff]?.filter(row=>row.ctr>0) ?? []}></MyTable>
							</Col>
						)
					})}
				</Row>
				<Row>
					<Col xs={12} md={9}>
						<Row><Col><h3 className="text-pink">Statistics</h3></Col></Row>
						<Row>
							<Col xs={12} md={8} lg={6}>
								{/* <Row><Col><h4 className="text-pink">Top</h4></Col></Row> */}
								<MyTable padding="0.1em" align="right" variant="violet" data={reports.raid.scoreStatistics}></MyTable>
							</Col>
							<Col>
							{ props.fullUnits && 
								<MyTable padding="0.1em" align="right" variant="violet" data={reports.raid.teamStatistics}></MyTable>
							}
							</Col>
						</Row>
					</Col>
					<Col xs={12} md={3}>
						<Row><Col><h3 className="text-pink">Levels</h3></Col></Row>
						<Row>
							<Col>
								<MyTable padding="0.1em" align="right" variant="violet" data={reports.raid.levels}></MyTable>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>);
	}

	function StudentScatter() {
		
		const chartColor = [
			{
				borderColor: 'rgb(162, 53, 235)',
				backgroundColor: 'rgba(162, 53, 235, 0.5)',
			},{
				borderColor: 'rgb(255, 175, 255)',
				backgroundColor: 'rgba(255, 175, 255, 0.5)',
			},{
				borderColor: 'rgb(224, 60, 164)',
				backgroundColor: 'rgba(224, 60, 164, 0.5)',
			},{
				borderColor: 'rgb(224, 60, 79)',
				backgroundColor: 'rgba(224, 60, 79, 0.5)',
			},{
				borderColor: 'rgb(162, 53, 235)',
				backgroundColor: 'rgba(162, 53, 235, 0.5)',
			}
		]
		const [studentScatter, setStudentScatter] = useState(students[0]?.getName() ?? "Hina");
		const studentIndex = useMemo(() => reports.attendance.findIndex(a => a.student.getId() == studentScatter || a.student.getName() === studentScatter),[studentScatter]);
		function ScatterChart({i}: {i: number}) {
			// TODO: Multi-student in one chart
			// TODO: Summary (number of points for when x = N) https://discord.com/channels/883515002651295754/883515096540790804/1178589142003748924
			const options = {
				responsive: true,
				scales: {
				  x: {
					  ticks: {
						  stepSize: 1,
					  }
				  }
				},
				plugins: {
				  legend: {
					// labels: {
					// 	color: 'white'
					// },
					// title: {
					// 	color: 'white'
					// }
				  },
				  title: {
					display: false,
					text: 'Ranks vs Scores',
					// color: 'white',
				  },
				}
			  };
			  const dataset = {
				  datasets: [{
					label: reports.attendance[studentIndex]?.scatter[i]?.label??"No Data (Non-Assist)",
					data: reports.attendance[studentIndex]?.scatter[i]?.data??[],
					...chartColor[0]
				  },{
					label: reports.attendance[studentIndex]?.scatterAssist[i]?.label??"No Data (Assist)",
					data: reports.attendance[studentIndex]?.scatterAssist[i]?.data??[],
					...chartColor[1]
				  }],
				};
			
			return (<Row>
				<Col>
				<hr></hr>
					<Row>
						<Col>
						<h4 className="text-pink">{reports.attendance[studentIndex]?.scatter[i]?.label ?? reports.attendance[studentIndex]?.scatterAssist[i]?.label ?? "???"}</h4>
						</Col>
					</Row>
					<Row>
						<Col>
							<Scatter options={options} data={dataset} />
						</Col>
					</Row>
				</Col>
			</Row>);

		}
		return (<Row>
			<Col>
				<Row>
					{studentIndex >= 0 && <Col xs={12} md={4} lg={2}>
						<StudentRenderer model={students[studentIndex]} bare compact />
					</Col>}
					<Col>
						<StudentPicker target={studentScatter} setTarget={setStudentScatter} autoFocus/>
					</Col>
				</Row>
			
				{!!reports.attendance[studentIndex] && Object.keys(reports.attendance[studentIndex]?.scatter).map(i => (<ScatterChart key={i} i={parseInt(i)}/>))}
				{!!!reports.attendance[studentIndex] && "No Data"}
			</Col>
		</Row>)
	}

	const Content = () => {
		switch (tab) {
			case "#Raid": return <RaidReport />;
			case "#Chart": return <MyChart />;
			case "#Attendance": return <Attendance />;
			case "#Teams": return <Teams />;
			case "#Student": return <StudentReport />;
			case "#Scatter": return <StudentScatter />;
		}
		return <Attendance/>;
	}
	return (
		<Modal scrollable fullscreen show={show} onHide={handleCloseAttendanceReport}>
			<Modal.Header closeButton>
				<Modal.Title className="text-pink">{tab.substring(1)} Report - {server?.toLocaleUpperCase()} - S{season} - {date}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Content />
			</Modal.Body>
			<Modal.Footer>
				<Col xs={1}>Reports:</Col>
				<Col xs={5} md={7}>
					<Nav 
						defaultActiveKey={"#"+displayedTabs[0]}
						variant="pills" 
						onSelect={setTab}
					>
						{displayedCommonTabs.map((tempTab,i) => (
							<Nav.Item key={i}>
								<Nav.Link href={`#${tempTab}`}>{tempTab}</Nav.Link>
							</Nav.Item>
						))}
						{props.fullUnits && displayedFullTabs.map((tempTab,i) => (
							<Nav.Item key={i+commontabs.length}>
								<Nav.Link href={`#${tempTab}`}>{tempTab}</Nav.Link>
							</Nav.Item>
						))}
					</Nav>
				</Col>
				<Col className="text-pink" style={{
					justifyContent: "right",
					display: "flex"
				}}>
					{senseis} Senseis<br/>{reports?.team?.length} Unique Teams
				</Col>
				<Col style={{
					justifyContent: "right",
					display: "flex"
				}}>
				<Button variant="secondary" onClick={handleCloseAttendanceReport}>Close</Button>
				<Button variant="secondary" disabled>Export</Button>
				</Col>
			</Modal.Footer>
		</Modal>
	)
}