import MultiStudentPicker from "Components/MultiStudentPicker";
import StudentFilterItem from "Components/StudentFilterItem";
import { IPVPHistoryGeneralFilterItem, IPVPHistoryPlayerFilterItem } from "Interfaces/IPVPHistoryFilter";
import IStyle from "Interfaces/IStyle";
import IParentProps from "Interfaces/Props/IParentProps";
import { PlayerType } from "Models/PVP/BattleHistoryItem";
import StudentFilter from "Models/StudentFilter";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

const style:IStyle = {
	filterRow: {marginTop: 10}
}
interface IProps {
	filters: IPVPHistoryPlayerFilterItem;
	setFilters: React.Dispatch<React.SetStateAction<IPVPHistoryPlayerFilterItem>> | ((toSet:IPVPHistoryPlayerFilterItem) => void);
	isPublic?: boolean;
}
interface IGenProps {
	filters: IPVPHistoryGeneralFilterItem;
	setFilters: React.Dispatch<React.SetStateAction<IPVPHistoryGeneralFilterItem>> | ((toSet:IPVPHistoryGeneralFilterItem) => void);
	isPublic?: boolean;
}

export default function PVPHistoryFilters({filters, setFilters, isPublic}:IGenProps) {
	return (
	<div className="filters">
		<Row style={style.filterRow}>
			<Col md={2}>
				<label>Date</label>
			</Col>
			<Col>
				<input 
					defaultValue={filters.from}
					style={{width:"100%"}}
					type="date"
					placeholder="From (1900-01-31)"
					onChange={(event)=>{
						setFilters({
							...filters,
							from: event.target.value
						})
					}}
					>
				</input>
			</Col>
			<Col xs={1}>-</Col>
			<Col>
				<input 
					defaultValue={filters.to}
					style={{width:"100%"}}
					type="date"
					placeholder="Until (3099-01-31)"
					onChange={(event)=>{
						setFilters({
							...filters,
							to: event.target.value
						})
					}}
					>
				</input>
			</Col>
		</Row>
		<Row style={style.filterRow}>
			<Col md={2}>
				<label>Winner</label>
			</Col>
			<Col>
				<Form.Select onChange={(event) => {
					setFilters({
						...filters,
						winner: event.target.value as PlayerType
					})
				}}>
					<option key="ANY" value="ANY">ANY</option>
					{["ATK","DEF"].map(opt => {
						return (<option key={opt} value={opt}>{opt}</option>);
					})}
				</Form.Select>
			</Col>
		</Row>
	</div>
	);
}
function Private({children, isPublic}:IParentProps & {isPublic?: boolean}) {
	if (isPublic) return null;
	const As = children;
	return <>{children}</>;
}
export function PVPHistoryPlayerFilters({filters, setFilters, isPublic}:IProps) {
	const [isAdvanced, setIsAdvanced] = useState(false);

	function maxRankOnChange(event) {
		event.target.value = event.target.value.replaceAll(/[^\d]/g,"");
		if (event.target.value.length) {
			const maxRank = parseInt(event.target.value);
			setFilters({
				...filters,
				maxRank: maxRank
			})
		} else {
			delete filters.maxRank
		}
	}

	function minRankOnChange(event) {
		event.target.value = event.target.value.replaceAll(/[^\d]/g,"");
		if (event.target.value.length) {
			const minRank = parseInt(event.target.value);
			setFilters({
				...filters,
				minRank: minRank
			})
		} else {
			delete filters.minRank
		}
	}
	return (
		<div className="filters">
			<Private isPublic={isPublic}>
				<Row style={style.filterRow}>
					<Col md={2}>
						<label>Rank</label>
					</Col>
					<Col>
						<input 
							style={{width:"100%"}}
							type="number"
							value={filters.minRank}
							placeholder="Top Rank (1)"
							step={10}
							onChange={minRankOnChange}
							>
						</input>
					</Col>
					<Col xs={1}>-</Col>
					<Col>
						<input 
							style={{width:"100%"}}
							type="number"
							value={filters.maxRank}
							placeholder="Bottom Rank (15001)"
							step={10}
							onChange={maxRankOnChange}
							>
						</input>
					</Col>
				</Row>
			</Private>
			<Row style={style.filterRow}>
				<Col md={2}>
					<label>Sensei</label>
				</Col>
				<Col>
					<input type="text" onChange={(e) => {
						if (e.target.value?.length) {
							setFilters({
								...filters,
								sensei: e.target.value
							})
						} else {
							delete filters.sensei;
							setFilters({...filters});
						}
					}}></input>
				</Col>
			</Row>
			<Row style={style.filterRow}>
				<Col md={2}>
					<label>Include</label>
				</Col>
					<Col>
						<Row>
							<Col>
							{filters?.includeStudents.map((student, index)=>(
								<StudentFilterItem 
								key={index}
								isAdvanced={isAdvanced}
								bg="violet"
								className="list-item student include"
								student={student}
								id={index}
								onClose={()=>{
									filters.includeStudents.splice(index, 1);
									setFilters({...filters});
								}}></StudentFilterItem>
							))}

							<MultiStudentPicker 
								setTarget={(toSet:StudentFilter[]) => {
									filters.includeStudents = toSet ?? [];
									setFilters({...filters});
								}} 
								target={filters.includeStudents}
								pop={() => {
									const r =filters.includeStudents.pop()
									setFilters({...filters});

									return r;
								}}
								push={(toAdd:string) => {
									const studentFilter = new StudentFilter(toAdd);
									filters.includeStudents.push(studentFilter);
									filters.includeStudents = [...new Set(filters.includeStudents)];
									setFilters({...filters});
								}}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row style={style.filterRow}>
				<Col md={2}>
					<label>Exclude</label>
				</Col>
					<Col>
						<Row><Col>
						{filters?.excludeStudents.map((student, index)=>(
							<StudentFilterItem 
							key={index}
							isAdvanced={isAdvanced}
							bg="pink-border"
							className="list-item student exclude"
							student={student}
							id={index}
							onClose={()=>{
								filters.excludeStudents.splice(index, 1);
								setFilters({...filters});
							}}></StudentFilterItem>
						))}

						<MultiStudentPicker 
							setTarget={(toSet:StudentFilter[]) => {
								filters.excludeStudents = toSet ?? [];
								setFilters({...filters});
							}} 
							target={filters.excludeStudents}
							pop={() => {
								const r =filters.excludeStudents.pop()
								setFilters({...filters});

								return r;
							}}
							push={(toAdd:string) => {
								const studentFilter = new StudentFilter(toAdd);
								filters.excludeStudents.push(studentFilter);
								filters.excludeStudents = [...new Set(filters.excludeStudents)];
								setFilters({...filters});
							}}
						/>
						</Col></Row>
					</Col>
			</Row>
			{/* <Row style={style.filterRow}>
				<Col md={2}>
				</Col>
				<Col>
					<div key="advanced-student-filter-switch" className="mb-3">
						<Form.Check 
						type="switch"
						id="advanced-student-filter-switch"
						label="Show Advanced Student Options? (This is ugly. Applied even if Hidden)"
						onChange={(a)=>{
							setIsAdvanced(a.target.checked);
						}}
						/>
					</div>
				</Col>
			</Row> */}
			{/* <Row>
				<Col md={2}>
				</Col>
				<Col>
					<div key="full-teams-switch" className="mb-3">
						<Form.Check 
						type="switch"
						id="full-teams-switch"
						label="Only Include Full Units (Required for Full Analytics)"
						onChange={(a)=>{
							filters.fullTeamsOnly = a.target.checked;
							setFilters(filters);
							updateView();
							// setDisplayedData(filteredData());
						}}
						/>
					</div>
				</Col>
			</Row> */}
		</div>
	)
}