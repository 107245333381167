import { ITableRow } from "Interfaces/ITableRow";
import RaidReportItem from "Reports/RaidReportItem";
import { secondsToTime } from "Utils/Common";

class TopTableRow implements ITableRow {
	private data:string[];
	constructor(data:number[]) {
		this.data = [
			data[0].toLocaleString(),
			secondsToTime(data[1]).toLocaleString(),
			data[2].toLocaleString(),
		]
	}
	getHeaders() {
		return ["Rank", "Time", "Score"];
	}
	toArray() {
		return this.data;
	}
	toKeyValues() {
		return { 
			Rank: this.data[0],
			Time: this.data[1],
			Score: this.data[2],
		 };
	}
	
}
class ClearCountTableRow implements ITableRow {
	private data:string[];
	time:number = 0; 
	rank:number = 0; 
	total:number = 0; 
	ctr:number = 0;
	constructor([time, rank, total, ctr]:number[]) {
		this.time=time;
		this.rank=rank;
		this.total=total;
		this.ctr=ctr;
		this.data = [
			secondsToTime(time),
			rank.toLocaleString(),
			ctr.toLocaleString(),
			total.toLocaleString(),
		]
	}
	getHeaders() {
		return ["Time", "Rank", "Count", "Total"];
	}
	toArray() {
		return this.data;
	}
	toKeyValues() {
		return { 
			Time: this.data[0],
			Rank: this.data[1],
			Count: this.data[2],
			Total: this.data[3],
		 };
	}
}
class LevelTableRow implements ITableRow {
	private data:string[];
	constructor([level, ctr]:number[]) {
		this.data = [
			level.toLocaleString(),
			ctr.toLocaleString(),
		]
	}
	getHeaders() {
		return ["Level", "Count"];
	}
	toArray() {
		return this.data;
	}
	toKeyValues() {
		return { 
			Level: this.data[0],
			Count: this.data[1],
		 };
	}
}
class ScoreStatsTableRow implements ITableRow {
	private data:string[];
	constructor(label:string, score:number, time:number) {
		this.data = [
			label,
			score.toLocaleString(),
			secondsToTime(time)
		]
	}
	getHeaders() {
		return ["Label", "Score", "Time"];
	}
	toArray() {
		return this.data;
	}
	toKeyValues() {
		return { 
			Label: this.data[0],
			Score: this.data[1],
			Time: this.data[2],
		 };
	}
}
class TeamStatsTableRow implements ITableRow {
	private data:string[];
	constructor(label:string, teams:number) {
		this.data = [
			label,
			teams.toLocaleString(),
		]
	}
	getHeaders() {
		return ["Label", "Teams"];
	}
	toArray() {
		return this.data;
	}
	toKeyValues() {
		return { 
			Label: this.data[0],
			Teams: this.data[1],
		 };
	}
}
export default class CommonRaidReportItem {
	scores:number[] = [];
	teams:number[] = [];
	times:number[] = [];
	timesCount:{[diff:string]: number[]} = {};
	timesMinMax:number[];
	levels:LevelTableRow[] = [];
	stats:RaidReportItem = null;
	scoreStatistics:ScoreStatsTableRow[] = [];
	teamStatistics:TeamStatsTableRow[] = [];
	constructor(
		scores:number[],
		timesCount:{[diff:string]: number[]},
		timesMinMax:number[],
		times:number[],
		levels:{[level:number]: number},
		teams:number[],
		displayedScores:number[]
	) {
		this.scores = scores;
		this.teams = teams;
		this.timesCount = timesCount;
		this.timesMinMax = timesMinMax;
		this.times = times;
		this.levels = [];
		for (const level in levels) {
			this.levels.push(new LevelTableRow([parseInt(level), levels[level]]))
		}
		this.stats = new RaidReportItem().calculateScoreData(displayedScores,[]).calculateTeamData(teams,[]);
		const {score, time, teams : teamsUsed} = this.stats;
		this.scoreStatistics = [
			new ScoreStatsTableRow("Mean", score.meanWith, time.meanWith),
			new ScoreStatsTableRow("Max", score.maxWith, time.maxWith),
			new ScoreStatsTableRow("Median", score.medianWith, time.medianWith),
			new ScoreStatsTableRow("Min", score.minWith, time.minWith),
		]
		this.teamStatistics = [
			new TeamStatsTableRow("Mean", teamsUsed.meanWith),
			new TeamStatsTableRow("Max", teamsUsed.maxWith),
			new TeamStatsTableRow("Median", teamsUsed.medianWith),
			new TeamStatsTableRow("Min", teamsUsed.minWith),
		]
	}

	topRankTable(includeRank:number[]):ITableRow[] {
		const data:TopTableRow[] =[]
		for (const rank of includeRank) {
			if  (rank > this.scores.length) continue;
			data.push(new TopTableRow([rank, this.times[rank-1], this.scores[rank-1]]))
		}
		return data;
	}

	timeTableGroupedBy(groupingSeconds:number) {
		const start = this.timesMinMax[0]-(this.timesMinMax[0]%groupingSeconds);
		const toRet:{[diff:string]:ClearCountTableRow[]} = {};
		let currRank:number = 0;
		for (const diff in this.timesCount) {
			for (let time = start; time < this.timesMinMax[1]; time+=groupingSeconds) {
				let ctr = 0;
				for (let i=time; i<time+groupingSeconds && i<=this.timesMinMax[1]; i++) {
					ctr += this.timesCount[diff][i];
				}
				// if(ctr===0) continue;
				if(!!!toRet[diff])
					toRet[diff] = [];
				currRank += ctr;
				const cumulative:number = toRet[diff].length ? toRet[diff][toRet[diff].length-1].total : 0;
				toRet[diff].push(new ClearCountTableRow([time+groupingSeconds, currRank, cumulative+ctr, ctr]));
			}
		}

		return toRet;
	}
}