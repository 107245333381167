import CurrentQR from "Components/CurrentQR";
import IconButton from "Components/IconButton";
import StudentRenderer from "Components/ModelRenderers/StudentRenderer";
import MyBrand from "Components/MyBrand";
import domToImage from 'dom-to-image';
import IStyle from "Interfaces/IStyle";
import { MyToast, MyToastContainer } from "Layouts/Toast";
import Student, { IGameServer, NumberedStudent, SchaleStudent } from "Models/Student";
import StudentMap from "Models/StudentMap";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Accordion, Button, Col, Form, Row, Toast } from "react-bootstrap";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import { copyToClipboard } from "Utils/Common";

interface IStudentCompareProps {
	left: SchaleStudent;
	right: SchaleStudent;
}

const styles:IStyle = {
	filterRow: {
		
	},
	button: {
		marginTop: "0.3em"
	},
	container: {
		padding: 10,
		margin: 5,
		border: "1px solid",
		// borderColor: "violet",
		borderRadius: 20,
		borderColor: "pink",
	},
}

enum CompareResult {
	NO_INPUT,
	LEFT,
	TIE,
	RIGHT,
}
const SERVERS = [
	"jp",
	"global",
	"cn"
]
class ComparatorString {
	current: number = 0;
	inputString: string;
	nextLeft: Student = null;
	nextRight: Student = null;
	constructor(inputString: string) {
		this.inputString = inputString;
	}
	// 0 = Tie, 1 = Left, 2 = Right
	compareNext() {
		if (this.inputString.length <= this.current) return CompareResult.NO_INPUT;
		switch (this.inputString.charAt(this.current++)) {
			case '0': return CompareResult.TIE;
			case '1': return CompareResult.LEFT;
			case '2': return CompareResult.RIGHT;
		}

		return CompareResult.TIE;
	}

	setNext(left:Student, right:Student) {
		if (this.nextLeft || this.nextRight) return false;
		this.nextLeft = left;
		this.nextRight = right;
		return true;
	}
}

export default function BaSort() {
	const allData = useMemo(()=>StudentMap.schaleStudent.sort((a,b)=>a.DefaultOrder - b.DefaultOrder),[]);
	const SCHOOLS = useMemo(()=>[...new Set(allData.map(s=>s.School))], []);
	const [altsIncluded, setAltsIncluded] = useState(false);
	const [server, setServer] = useState<IGameServer>("jp");
	const [includeSchools, setIncludeSchools] = useState(SCHOOLS.filter(s=>!["ETC", "Tokiwadai", "Sakugawa"].includes(s)));
	
	const rosterDiv = useRef(null);
	const [isRunning, setIsRunning] = useState(false);
	const [toasts, setToasts] = useState([]);
	function pushToast(toast:string) {
		setToasts([...toasts, toast])
	}
	const includedData = useMemo(()=>{
		return allData.filter((student, i) => {
			const isReleased = student.isReleased(server);
			const isSchool = includeSchools.indexOf(student.School) >= 0;
			const isAlt = (altsIncluded || student.Name.indexOf("(")<0)
			return isReleased && isSchool && isAlt;
		});
	},[allData, includeSchools, server, altsIncluded, isRunning]);
	const estimatedComparisons = useMemo(()=>Math.ceil(includedData.length * Math.log(includedData.length)), [includedData.length]);
	const [inputString, setInputString] = useState("");
	const [sortedData, setSortedData] = useState<Student[]>(includedData);
	const [comparedStudents, setComparedStudents] = useState<[Student, Student]>([includedData[0], includedData[1]])
	const card = document.querySelector(".student")?.getBoundingClientRect();
	const screenshotWidth = (card?.width ?? 0)*Math.min(16, Math.max(6, (Math.floor(Math.pow(sortedData.length, 0.5)))));


	useEffect(()=>{
		if (!isRunning) {
			setSortedData(includedData);
			return;
		}
		const comparator = new ComparatorString(inputString);
		function mergeSort(array: Student[]): Student[] {
			if (array.length <= 1) {
				return array;
			}
			const middle = Math.floor(array.length / 2);
			const leftHalf = array.slice(0, middle);
			const rightHalf = array.slice(middle);
			return merge(mergeSort(leftHalf), mergeSort(rightHalf));
		}
		
		function merge(left: Student[], right: Student[]): Student[] {
			let result: Student[] = [];
			let leftIndex = 0;
			let rightIndex = 0;
		
			while (leftIndex < left.length &&
				rightIndex < right.length) {
				const compare = comparator.compareNext();
				if (compare == CompareResult.NO_INPUT) {
					comparator.setNext(left[leftIndex], right[rightIndex]);
				}
				if (compare == CompareResult.LEFT || compare == CompareResult.TIE) {
					result.push(left[leftIndex]);
					leftIndex++;
				} else {
					result.push(right[rightIndex]);
					rightIndex++;
				}
			}

			return result.concat(left.slice(leftIndex)).
				concat(right.slice(rightIndex));
		}
		setSortedData(mergeSort(includedData));
		setComparedStudents([comparator.nextLeft, comparator.nextRight]);
		if (comparator.nextLeft == null)
			setIsRunning(false);
		else 
			setIsRunning(true);
	},[inputString, includedData])


	function StudentCompare(props: IStudentCompareProps) {

		return (<>
			<Row>
				<Col onClick={()=>{
					setInputString(`${inputString}1`);
				}}><StudentRenderer model={props.left} /></Col>
				<Col>Hello</Col>
				<Col onClick={()=>{
					setInputString(`${inputString}2`);
				}}><StudentRenderer model={props.right} /></Col>
			</Row>
		</>);
	}
	// const [allData, setAllData] = useState<AronaStudent[]>([]);

	// useEffect(() => {
	// 	let ignore = false;
	// 	async function loadAronaSpriteInfo() {
	// 		const fullDataUrl = `https://arona.ai/_next/static/chunks/pages/table/scenariocharacter-6ffd2a179b028833.js`;
	// 		const result = await getText(fullDataUrl);
	// 		// (?<=\.exports = )\[(.|\n\s)*\]
	// 		if (!ignore) {
	// 			const exports = /(?<=\.exports\s*=\s*)\[/gi;
	// 			const exportsIndex = result.search(exports);
	// 			const data = result.substring(exportsIndex, result.indexOf("]", exportsIndex)+1)
	// 			const evaluatedResult:IAronaSprite[] = Function(`"use strict";return ${data}`)();

	// 			setAllData(evaluatedResult.filter((obj1, i, arr) => {
	// 				return obj1.nicknameKR.indexOf("?") < 0
	// 					&& obj1.portrait.length > 1
	// 					&& obj1.portrait.indexOf("_Null") < 0
	// 			}).filter((obj1, i, arr) => {
	// 				// obj2.nameJP === obj1.nameJP || 
	// 				return arr.findIndex(obj2 => (obj2.portrait === obj1.portrait)) === i; 
	// 			}).map(d => new AronaStudent(d)))
	// 		}
	// 	}

	// 	async function loadWikiruInfo() {
	// 		// 			const result = await getJson(`https://ba-api.midokuni.com/wheelofnames/${forcedPickKey}`);

	// 		const fullDataUrl = `https://ba-api.midokuni.com/wikirujp/?NPC%E4%B8%80%E8%A6%A7`;
	// 		const result = await getText(fullDataUrl);
			
	// 		console.log(await fetch(fullDataUrl, {
	// 			method: 'GET'
	// 		}));
	// 		// (?<=\.exports = )\[(.|\n\s)*\]
	// 		if (!ignore) {
	// 			console.log(result);
	// 			const parsedXMLResponse = (new DOMParser()).parseFromString(result, "text/xml");
	// 			const data = parsedXMLResponse.getElementsByTagName("td");

	// 			// const exports = /(?<=\.exports\s*=\s*)\[/gi;
	// 			// const exportsIndex = result.search(exports);
	// 			// const data = result.substring(exportsIndex, result.indexOf("]", exportsIndex)+1)
	// 			// const evaluatedResult:IAronaSprite[] = Function(`"use strict";return ${data}`)();

	// 			// setAllData(evaluatedResult.filter((obj1, i, arr) => {
	// 			// 	return obj1.nicknameKR.indexOf("?") < 0
	// 			// 		&& obj1.portrait.length > 1
	// 			// 		&& obj1.portrait.indexOf("_Null") < 0
	// 			// }).filter((obj1, i, arr) => {
	// 			// 	// obj2.nameJP === obj1.nameJP || 
	// 			// 	return arr.findIndex(obj2 => (obj2.portrait === obj1.portrait)) === i; 
	// 			// }).map(d => new AronaStudent(d)))
	// 		}
	// 	}

	// 	async function loadData() {
	// 		// const npcs = loadWikiruInfo();
	// 		// const students = loadSchaleInfo();

	// 		// await npcs;
	// 		// await students;
	// 	}

		

	// 	return () => {
	// 		ignore = true
	// 	}
	// }, [])

	function updateInputString(value: CompareResult) {
		switch (value) {
			case CompareResult.LEFT: setInputString(`${inputString}1`); break;
			case CompareResult.RIGHT: setInputString(`${inputString}2`); break;
			case CompareResult.TIE: setInputString(`${inputString}0`); break;
			case CompareResult.NO_INPUT: 
				if (inputString.length == 0) return;
				setInputString(inputString.substring(0, inputString.length-1));
		}
	}
	const handleKeyPress = useCallback((event: KeyboardEvent) => {
		if (isRunning) {
			switch(event.code) {
				case "ArrowLeft":
				case "Digit1": updateInputString(CompareResult.LEFT); break;
				case "ArrowDown":
				case "Digit2": updateInputString(CompareResult.TIE); break;
				case "ArrowRight":
				case "Digit3": updateInputString(CompareResult.RIGHT); break;
				case "Delete": updateInputString(CompareResult.NO_INPUT); break;
			}
		}
	}, [isRunning, inputString]);

	useEffect(() => {
		// attach the event listener
		document.addEventListener('keydown', handleKeyPress);

		// remove the event listener
		return () => {
			document.removeEventListener('keydown', handleKeyPress);
		};
	}, [handleKeyPress]);

	const [isPic, setIsPic]=useState(false);
	async function downloadImage() {
		setIsPic(true);
		try {
			const su = rosterDiv.current;
			const dataUrl = await domToImage.toPng(su);
			const link = document.createElement('a');
			link.href = dataUrl;
			link.download = `BaSort_Hina-Loves-Midokuni_${moment().format("YYYY-MM-DD_HH:mm")}.png`;
			link.click();
		} catch (error) {
			pushToast('Error capturing image:'+error);
			console.error('Error capturing or copying image:', error);
		}
		setIsPic(false);
	}
	async function copyImage() {
		setIsPic(true);
		try {
			const su = rosterDiv.current;
			const dataUrl = await domToImage.toPng(su);
			const canvas:any = document.createElement('canvas');
			// console.log(su.parentElement.style.background)
			// canvas.bgcolor = "red"
			// canvas.bgcolor = window.getComputedStyle(su).getPropertyValue('--bs-body-bg')
			canvas.width = su.offsetWidth;
			canvas.height = su.offsetHeight;
			const ctx = canvas.getContext('2d');
			const img = new Image();
			img.src = dataUrl;
			img.onload = () => {
				ctx?.drawImage(img, 0, 0);
				canvas.toBlob(function(blob) { 
					const item = new ClipboardItem({ "image/png": blob });
					navigator.clipboard.write([item]); 
					pushToast("Copied to clipboard");
				});
			};
		} catch (error) {
			pushToast('Error capturing image:'+error);
			console.error('Error capturing or copying image:', error);
		}
		setIsPic(false);
	}

	function getActionText(val: string) {
		switch (val) {
			case "1": return "LEFT";
			case "2": return "RIGHT";
			case "0": return "TIE";
		}
	}

	return (<>
		{!isRunning && <Row className="filters">
			<Col>
				<Accordion defaultActiveKey="0">
					<Accordion.Item eventKey="0">
						<AccordionHeader><h4>Settings</h4></AccordionHeader>
						<AccordionBody>
							<Row style={styles.filterRow}>
								<Col xs={12}>
									<label>Game Server</label>
								</Col>
							</Row>
							<Row>
								{SERVERS.map((state:IGameServer) => {
									return <Col><Form.Check // prettier-ignore
									checked={server == state}
									onClick={()=>{
										setServer(state);
									}}
									key={`server_${state}`}
									type="radio"
									id={`server_${state}`}
									label={state.toLocaleUpperCase()}
								/></Col>
								})}
							</Row>
							<hr></hr>
							<Row style={styles.filterRow}>
								<Col xs={12}>
									<label>Schools</label>
								</Col>
							</Row>
							<Row>
								{SCHOOLS.map((school) => {
								return <Col><Form.Check // prettier-ignore
									checked={includeSchools.indexOf(school) >= 0}
									onClick={()=>{
										if (includeSchools.indexOf(school) >= 0) {
											setIncludeSchools(includeSchools.filter(fa => fa !== school))
										} else {
											setIncludeSchools([...includeSchools, school])
										}
									}}
									key={`school_${school}`}
									type="radio"
									id={`school_${school}`}
									label={`${school}`}
								/></Col>
								})}
							</Row>
							<hr></hr>
							<Row>
								<Col>
									<div key="include-alts" className="mb-3">
										<Form.Check 
										checked={altsIncluded}
										type="switch"
										id="include-alts"
										label="Include Alts"
										onChange={(a)=>{
											setAltsIncluded(a.target.checked);
										}}
										/>
									</div>
								</Col>
							</Row>
							<hr></hr>
							<Row>
								<Col>
									<Button variant="violet" onClick={()=>{
										setIsRunning(true);
									}}>START SORTING (&#8804;{estimatedComparisons})</Button>
								</Col>
							</Row>
							<Row>
								<Col>
									<IconButton 
										image="/logo512.png"
										label="📷 Screenshot"
										onClick={async () => copyImage()}
									/>
								</Col>
								<Col>
									<IconButton 
										image="/logo512.png"
										label="📷 Download"
										onClick={async () => downloadImage()}
									/>
								</Col>
								<Col>
									<IconButton 
										image="/logo512.png"
										label="Copy Text to Clipboard"
										onClick={async () => {
											const shareText = sortedData.map((s, i) => `${i+1}. ${s.getName()}`).join("\n");
											copyToClipboard(shareText);
											pushToast("Rankings have been put in your clipboard!")
										}}
									/>
								</Col>
							</Row>
						</AccordionBody>
					</Accordion.Item>
				</Accordion>
			</Col>
		</Row>}
		{isRunning && <Row  style={{...styles.container,display:"flex"}}>
			<Col className="text-center">
				<Row><Col className="text-pink">Match {inputString.length+1} / ~{estimatedComparisons} ({Math.floor(100*inputString.length/estimatedComparisons)}%)</Col></Row>
				<Row style={{...styles.container,display:"flex", justifyContent: "center"}} className="responsive-font full-list">
					<Col className="student text-end" onClick={()=>{
						updateInputString(CompareResult.LEFT);
					}}><StudentRenderer bare model={NumberedStudent.fromNameWithNumber(comparedStudents[0].getName(), 1)} /></Col>
					<Col xs={4} md={2} className="text-center" >
						<Row><Col><Button style={styles.button} variant="violet" onClick={()=>{
							updateInputString(CompareResult.LEFT);
						}}>&lt;1 Left</Button></Col>
						<Col><Button style={styles.button} variant="danger" onClick={()=>{
							updateInputString(CompareResult.TIE);
						}}>Tie</Button></Col>
						<Col><Button style={styles.button} variant="violet" onClick={()=>{
							updateInputString(CompareResult.RIGHT);
						}}>Right 3&gt;</Button></Col></Row>
						<hr></hr>
						{inputString.length > 0 && <Row><Col><Button style={styles.button} disabled={inputString.length == 0} variant="warning" onClick={()=>{
							updateInputString(CompareResult.NO_INPUT);
						}}>Previous ({getActionText(inputString.charAt(inputString.length-1))})</Button></Col></Row>}
						<Row><Col><Button style={styles.button} variant="danger" onClick={()=>{
							setIsRunning(false);
							setInputString("")
						}}>Cancel</Button></Col></Row>
						<hr></hr>
					</Col>
					<Col className="student" onClick={()=>{
						updateInputString(CompareResult.RIGHT);
					}}><StudentRenderer bare model={NumberedStudent.fromNameWithNumber(comparedStudents[1].getName(), 3)} /></Col>
				</Row>
				<Row><Col className="text-pink"><p><small>Use TIE all you want but I'm not a believer of 2 units being truly equal. I am not going to show them equally in the rankings, TIE will only be for sorting purposes. Use at your own risk.</small></p></Col></Row>
			</Col>
		</Row>}
		<Row>
			<Col>
				<h5 className="text-pink">Rankings: </h5>
			</Col>
		</Row>
		<Row style={{...styles.container,display:"flex"}} className="responsive-font full-list">
			{sortedData?.map((student, rank) => (<Col className="student"><StudentRenderer bare model={NumberedStudent.fromNameWithNumber(student.getName(), rank+1)} /></Col>))}
		</Row>
		
		<Row id="screenshot-roster" hidden={!isPic} ref={rosterDiv} className="bg-body-secondary" style={{minWidth: `${screenshotWidth}px`, width: `${screenshotWidth}px`
	}}>
		<Col>
			<Row>
				<Col>
					<h1 className="text-pink">Rankings: </h1>
				</Col>
			</Row>
			<Row style={{...styles.container,display:"flex"}} className="responsive-font full-list">
				{sortedData?.map((student, rank) => {
					return <Col className="student">
						<StudentRenderer bare model={NumberedStudent.fromNameWithNumber(student.getName(), rank+1)} />
					</Col>
				})}
			</Row>
			<Row style={{textAlign: "center", marginTop: "0.2em"}}><Col><CurrentQR size={256}></CurrentQR></Col></Row>
			<Row>
				<Col>
					<MyBrand/>
				</Col>
			</Row>
		</Col>
	</Row>
	<Row className="responsive-font"><Col>This has been inspired by <a href="https://ba-sort.netlify.app">https://ba-sort.netlify.app</a> but I am using a different algorithm and only include playable students (Unless someone can provide me an API to get NPC data). The data used here is processed from <a href="https://schaledb.com">schaledb.com</a></Col></Row>



		<MyToastContainer>
			{toasts.map((toast) => <MyToast>
				<Toast.Header closeButton>
					<strong className="me-auto">Alert!</strong>
				</Toast.Header>
				<Toast.Body>{toast}</Toast.Body>
			</MyToast>)}
		</MyToastContainer>
	</>);
}