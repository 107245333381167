import Logo from "Logo";
import React from "react";
import { Col, Row } from "react-bootstrap";

export default function MyBrand() {
	return (<Row>
		<Col style={{textAlign: "right"}}>
			<Logo />
		</Col>
		<Col>
			<Row>
				{document.location.hostname.replaceAll(".midokuni.com","").replaceAll("."," ").toLocaleUpperCase()}
			</Row>
			<Row>
				<sub className="text-pink">MIDOKUNI.com</sub>
			</Row>
		</Col>
	</Row>
)
}