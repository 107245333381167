import Player from "Models/PVP/Player";
import Student from "Models/Student";
import StudentMap from "Models/StudentMap";
import TeamFormation from "Models/TeamFormation";

export type PlayerType = "ANY" | "ATK" | "DEF";
export default class BattleHistoryItem 
{
	id: number;
	battleTimeEnd: string;
	didAttackWin: boolean;
	attacker: Player;
	defender: Player;

	getTier() {
		const rank = this.attacker.rankBeforeBattle;
		for (const r of [1,2,10,100,200,500,1000,2000,3000,4000,5000, 15001])
			if (rank <= r) return r;
			
		return 15001;
	}

	getDisplayName() {
		return `${this.attacker.name} vs ${this.defender.name}`;
	}

	getDate() {
		return this.battleTimeEnd.split('T')[0];
	}

	toString() {
		return `#${this.getTier()}: ${this.attacker.team} ATTACKS ${this.defender.team}`;
	}
	static createPlayer(data: string[], i: number):[Player, number] {
		const player = new Player();
		player.level = parseInt(data[i++]);
		player.name = data[i++].replaceAll(/^\[[^\]]+\]/g, "");
		
		if (player.name.match(/^Character_\d+$/g)) {
			player.name = new StudentMap().getName(player.name.replace("Character_", ""));
		}
			
		player.id = data[i++];
		player.profilePictureId = data[i++];
		player.rankBeforeBattle = parseInt(data[i++]);
		player.team = new TeamFormation(
			[
				Student.fromName(data[i++]),
				Student.fromName(data[i++]),
				Student.fromName(data[i++]),
				Student.fromName(data[i++]),
			],
			[
				Student.fromName(data[i++]),
				Student.fromName(data[i++]),
			]
		)

		return [player, i];
	}
	static parse(raw:string) {
		const data = raw.split('\t');
		if (data.length < 2) return undefined;
		const row = new BattleHistoryItem();
		let i=0;
		row.id = parseInt(data[i++]);
		row.battleTimeEnd = data[i++];
		row.didAttackWin = "ATK" === data[i++];
		[row.attacker, i] = this.createPlayer(data, i);
		[row.defender, i] = this.createPlayer(data, i);
		row.attacker.damage = [
			parseInt(data[i++]),
			parseInt(data[i++]),
			parseInt(data[i++]),
			parseInt(data[i++]),
			parseInt(data[i++]),
			parseInt(data[i++]),
		]
		row.defender.damage = [
			parseInt(data[i++]),
			parseInt(data[i++]),
			parseInt(data[i++]),
			parseInt(data[i++]),
			parseInt(data[i++]),
			parseInt(data[i++]),
		]
		
		return row;
	}
	static parseTsv(raw: string):BattleHistoryItem[] {
		const data = [];
		for(const row of raw.split('\n')) {
			try {
				if (row.length < 5) continue;
				const parsed = BattleHistoryItem.parse(row);
				if (!!parsed)
					data.push(parsed);
			} catch {

			}
		}

		return data;
	}
	
	async fromTsvUrl(url:string) {
		const rawResponse = await fetch(url, {
			method: 'GET'
		});
		const data = await rawResponse.text()
		return BattleHistoryItem.parseTsv(data);
	}
}