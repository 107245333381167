import { BannerStudentRenderer } from "Components/ModelRenderers/BannerStudentRenderer";
import { BorrowStudentRenderer } from "Components/ModelRenderers/BorrowStudentRenderer";
import { FarmableStudentRenderer } from "Components/ModelRenderers/FarmableStudentRenderer";
import { GenericStudentRenderer } from "Components/ModelRenderers/GenericStudentRenderer";
import { StudentThoughtsRenderer } from "Components/ModelRenderers/StudentThoughtsRenderer";
import BannerStudent from "Models/BannerStudent";
import BorrowStudent from "Models/BorrowStudent";
import FarmableStudent from "Models/FarmableStudent";
import GenericStudent from "Models/GenericStudent";
import StudentThoughts from "Models/StudentThoughts";
import React from "react";
export class ModelRenderer extends React.Component<{ model: any, extra?: any }> {
	render() {
		const { model, extra } = this.props;
		if (model == null) {
			// return <TestRenderer/>
			return <>No Entry</>
		}
		switch (model?.constructor) {
			case BannerStudent:
				return <BannerStudentRenderer {...extra}/>
			case FarmableStudent:
				return <FarmableStudentRenderer model={model}/>
			case BorrowStudent:
				return <BorrowStudentRenderer model={model}/>
			case StudentThoughts:
				return <StudentThoughtsRenderer model={model} />
			case GenericStudent:
				return <GenericStudentRenderer model={model} />
			default:
				console.log(model);
				return (<>Unknown Model</>);
		}

	}
}
