import IParentProps from "Interfaces/Props/IParentProps";
import React, { useState } from "react";
import { Col, Image, Modal, Pagination, Row } from "react-bootstrap";

interface IProps {
	srcs: string[];
	title?: string;
}
export default function ImageGallery(props: IProps & IParentProps) {
	const [activeIndex, setActiveIndex] = useState(-1);
	const isActive = activeIndex >= 0;
	
	function PaginationButtons() {
		if (props.srcs.length <= 1) return null;
		return <Pagination style={{display: "flex", justifyContent: "center"}}>
			<Pagination.First key="first" onClick={() => setActiveIndex(0)} />
			<Pagination.Prev
				key="prev"
				onClick={() => setActiveIndex((activeIndex - 1)%props.srcs.length)}
			/>
			<Pagination.Next
				key="next"
				onClick={() => setActiveIndex((activeIndex + 1)%props.srcs.length)}
			/>
			<Pagination.First key="first" onClick={() => setActiveIndex(props.srcs.length-1)} />
		</Pagination>
	}
	return (<>
		<Modal fullscreen style={{alignContent: "center"}} centered show={isActive} onHide={()=>{setActiveIndex(-1)}}>
			<Modal.Header closeButton><Modal.Title className="text-pink">{props.title}</Modal.Title></Modal.Header>
			<Modal.Body >
				<Row><Col>{PaginationButtons()}</Col></Row>
				<Row>
					<Col><Image style={{display: "block", margin: "auto"}} fluid src={props.srcs[activeIndex]}></Image></Col>
				</Row>
				<Row><Col>{PaginationButtons()}</Col></Row>
			</Modal.Body>
		</Modal>
		{props.srcs.map((src,i) => {
			return <Image key={src} width="100%" onClick={()=>setActiveIndex(i)} src={src}></Image>
		})}
	</>
	);
}