import RelationOperatorOptions from "Components/RelationOperatorDatalist";
import StudentFilter, { InclusionOperator, Mystic, RelationalOperator } from "Models/StudentFilter";
import React from "react";
import { Badge, CloseButton, Col, Form, Row } from "react-bootstrap";

interface IProps {
	onClose: React.MouseEventHandler<HTMLButtonElement>;
	student: StudentFilter;
	id: string|number;
	bg: string;
	className: string;
	isAdvanced?: boolean;
	excludeField?: string[]
}

export default function StudentFilterItem(props: IProps) {
	const {student, id, bg, className, isAdvanced} = props;
	if (!!!isAdvanced) {
		return (
			<Badge bg={bg} className={className} key={id}>
			<CloseButton aria-label={`delete-${id}`} onClick={props.onClose}/>
			<span>
				{student.toString()}
			</span>
			</Badge>)
	}
	return (
		<Row style={{marginTop: "1em", marginBottom: "1em"}}>
			<Col xs={3}>
			<Badge bg={bg} className={className} key={id}>
			<CloseButton aria-label={`delete-${id}`} onClick={props.onClose}/>
			<span>
				{student.toString()}
			</span>
			</Badge>
			</Col>
			<Col>
				{(!!!props.excludeField || props.excludeField?.indexOf("Level") < 0) && <Row>
					<Col md={2}>
						Level 
					</Col>
					<Col md={3}>
						<Form.Select onChange={(event) => {
							student.targetLevel.operator = event.target.value as RelationalOperator;
						}}>
							<RelationOperatorOptions />
						</Form.Select>
					</Col>
					<Col md={5}>
						<input
							type="number"
							placeholder="1"
							step={1}
							min={1}
							onChange={(event) => {
								event.target.value = event.target.value.replaceAll(/[^\d]/g,"");
								student.targetLevel.operand = parseInt(event.target.value);
							}}
						/>
					</Col>
				</Row>}
				{(!!!props.excludeField || props.excludeField?.indexOf("Mystic") < 0) && <Row>
					<Col md={2}>
						Mystic 
					</Col>
					<Col md={3}>
						<Form.Select onChange={(event) => {
							student.targetMystic.operator = event.target.value as RelationalOperator;
						}}>
							<RelationOperatorOptions />
						</Form.Select>
					</Col>
					<Col md={5}>
						<Form.Select onChange={(event) => {
							student.targetMystic.operand = event.target.value as Mystic;
						}}>
							{["1☆", "2☆", "3☆", "4☆", "5☆", "UE"].map(opt => {
								return (<option key={opt} value={opt}>{opt}</option>);
							})}
						</Form.Select>
					</Col>
				</Row>}
				{(!!!props.excludeField || props.excludeField?.indexOf("Assist") < 0) && <Row>
					<Col md={2}>
						Assist
					</Col>
					<Col>
						<Form.Select onChange={(event) => {
							student.targetAssist = event.target.value as InclusionOperator;
						}}>
							{["Any", "Excluded", "Only"].map(opt => {
								return (<option key={opt} value={opt}>{opt}</option>);
							})}
						</Form.Select>
					</Col>
				</Row>}
				{(!!!props.excludeField || props.excludeField?.indexOf("Unit") < 0) && <Row>
					<Col md={2}>
						Unit 
					</Col>
					<Col md={3}>
						<Form.Select onChange={(event) => {
							student.targetUnit.operator = event.target.value as RelationalOperator;
						}}>
							<RelationOperatorOptions />
						</Form.Select>
					</Col>
					<Col md={5}>
						<input
							type="number"
							placeholder="1"
							step={1}
							min={1}
							onChange={(event) => {
								event.target.value = event.target.value.replaceAll(/[^\d]/g,"");
								student.targetUnit.operand = parseInt(event.target.value);
							}}
						/>
					</Col>
				</Row>}
			</Col>
		</Row>
	)
}