import { DIFFICULTY_SCORE } from "Constants/Raids";
import { defaultScoreParams } from "Utils/Calculator";

export default class RaidScore {
	difficultyScore: number = 0;
	timeLimit: number = 0;
	timeMultiplier: number = 0;
	hpScore: number = 0;
	timeElapsed: number = 0;

	constructor (
		data: {
			params?:{
				difficultyScore:number,
				timeLimit:number|string,
				timeMultiplier:number,
				hpScore:number,
				timeElapsed?:number|string,
			},
			array?:string[]
		},
	) {
		if (typeof(data.params) === "undefined") {
			if (typeof(data.array) === "undefined")
				return;
			data.params = {
				difficultyScore: parseInt(data.array[0]),
				timeLimit: data.array[1],
				timeMultiplier: parseInt(data.array[2]),
				hpScore: parseInt(data.array[3]),
				timeElapsed: data.array[4] ?? 0,
			};
		}
		this.difficultyScore = data.params.difficultyScore
		if (typeof(data.params.timeLimit) === "number")
			this.timeLimit = data.params.timeLimit
		else 
			this.timeLimit = this.getTime(data.params.timeLimit)

		this.timeMultiplier = data.params.timeMultiplier
		this.hpScore = data.params.hpScore
		if (typeof(data.params.timeElapsed) === "number")
			this.timeElapsed = data.params.timeElapsed
		else 
			this.timeElapsed = this.getTime(data.params.timeElapsed ?? "0")
	}

	static fromRaid(server:string, season:number, name:string):RaidScore[] {
		const defaults = defaultScoreParams();
		server = server.toLowerCase();
		name = name.toLowerCase();
		let limitType = 1;
		if (name === "binah" || name.indexOf("kaiten") >= 0)
			limitType = 0;
		let scoringType = "JP";
		switch (server) {
			case "jp": 
				if (season < 50)
					scoringType = "EN";
				break;
			case "cn": 
				scoringType = "EN"; 
				break;
			default:
				if (season < 44)
					scoringType = "EN";
				break;
		}
		const toRet:RaidScore[] = [];
		for(let difficulty in DIFFICULTY_SCORE) {
			toRet.push(new RaidScore({params: defaults[scoringType][difficulty][limitType]}))
		}
		
		return toRet;
	}

	getTime(timeString:string):number {
		const parts = timeString.split(':')
		const numerics = /(\d+(\.\d+)?)/g
		let time:number = parseFloat(numerics.exec(parts[0])?.[0] ?? '0');
		if (parts.length <= 1)
			return time;
		time *= 60;
		numerics.lastIndex = 0;
		time += parseFloat(numerics.exec(parts[1])?.[0] ?? '0');

		return time;
	}

	getEffectiveTime(timeElapsed?:number):number {
		return this.timeLimit - Math.min(this.timeLimit, timeElapsed ?? this.timeElapsed);
	}

	calculate(timeElapsed?:number):number {
		return this.difficultyScore
		 + this.hpScore
		  + (this.getEffectiveTime(timeElapsed) * this.timeMultiplier)
		;
	}

	calculateSecondsElapsed(score:number):number {
		return this.timeLimit - (score - (this.difficultyScore + this.hpScore))/this.timeMultiplier;
	}

	toJSON() {
		return {
			time: this.timeElapsed,
			score: this.calculate()
		}
	}
}