import IParentProps from "Interfaces/Props/IParentProps";
import React, { useState } from "react";
import { Toast, ToastProps } from "react-bootstrap";
import ToastContainer from 'react-bootstrap/ToastContainer';

export function MyToast(props: ToastProps) {
	const [show, setShow] = useState(true);
	return <Toast bg="dark" show={show} autohide delay={5000} onClose={() => {
		setShow(false);
	}} {...props}>
		{props.children}
	</Toast>
}

export function MyToastContainer(props: IParentProps) {
	return (
	<ToastContainer style={{paddingTop: 80+70, marginTop: -70, paddingRight: "1em", zIndex: "9999 !important"}} position="top-end" className="position-fixed">
		
		{props.children}
		{/* <Toast>
		<Toast.Header>
			<img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
			<strong className="me-auto">Bootstrap</strong>
			<small className="text-muted">just now</small>
		</Toast.Header>
		<Toast.Body>See? Just like this.</Toast.Body>
		</Toast>
		<Toast>
		<Toast.Header>
			<img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
			<strong className="me-auto">Bootstrap</strong>
			<small className="text-muted">2 seconds ago</small>
		</Toast.Header>
		<Toast.Body>Heads up, toasts will stack automatically</Toast.Body>
		</Toast> */}
	</ToastContainer>
	);
}
