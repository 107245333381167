import { BorrowStudentRaidRow } from "Components/BorrowStudentRaidRow";
import { ColBadge } from "Components/ColBadge";
import { ParagraphString } from "Components/ParagraphString";
import { TagBadge } from "Components/TagBadge";
import { IBorrowStudentRaidRendererProps } from "Interfaces/IBorrowStudentRaidRendererProps";
import React from "react";
import { Card, Col, ListGroup, Row } from "react-bootstrap";
export class BorrowStudentRaidRenderer extends React.Component<IBorrowStudentRaidRendererProps> {
	renderVal(str:string) {
		if (!str) return <></>;
		return (
			<Col>
				{ str === "Wait" || str === "No"
					? (<TagBadge>{str}</TagBadge>) 
					: (<ParagraphString>{str}</ParagraphString>)
				}
			</Col>
		)
	}
	// TODO: Merge duplicates
	render() {
		const { model, children, name } = this.props;
		const devName = this.props.devName ?? name;

		return (
			<ListGroup.Item>
				<Row>
					<Col xs={12} lg={3} style={{
						display: "flex",
						textAlign: "center",
						alignItems: "center",
						verticalAlign: "center"
					}}>
						<div style={{textAlign: "center", verticalAlign: "center"}}>
							<Card border="dark">
								<Card.Img variant="bottom" src={`/img/Boss_Portrait_${devName}_Lobby.png`} />
								<Card.Footer>{name}</Card.Footer>
							</Card>
						</div>
					</Col>
					<Col>
						<BorrowStudentRaidRow model={model.outdoors} label="Outdoors" />
						<BorrowStudentRaidRow model={model.indoors} label="Indoors" />
						<BorrowStudentRaidRow model={model.urban} label="Urban" />
					</Col>
				</Row>
			</ListGroup.Item>
		);
	}
	render2() {
		const { model, children } = this.props;
		let str;
		return (
			<ListGroup.Item>
				<Row>
					<Col xs={12} lg={3}>
						{children}
					</Col>
					<Col>
					<Row>
						{model.outdoors && <ColBadge xs={true} sm={true} md={true} lg={true}>Outdoors</ColBadge>}
						{model.indoors && <ColBadge xs={true} sm={true} md={true} lg={true}>Indoors</ColBadge>}
						{model.urban && <ColBadge xs={true} sm={true} md={true} lg={true}>Urban</ColBadge>}
					</Row>
					<Row>
						{this.renderVal(model.outdoors)}
						{this.renderVal(model.indoors)}
						{this.renderVal(model.urban)}
					</Row>
					</Col>
				</Row>
			</ListGroup.Item>
		);
	}
	render3() {
		const { model, children } = this.props;
		return (
			<ListGroup.Item>
				{model.outdoors && (<Row>
					<ColBadge md={4} lg={3}>{children} Outdoors</ColBadge>
					<Col>
						{ model.outdoors === "Wait" || model.outdoors === "No"
							? (<TagBadge>{model.outdoors}</TagBadge>) 
							: (<ParagraphString>{model.outdoors}</ParagraphString>)
						}
					</Col>
				</Row>)}
				{model.indoors && (<Row>
					<ColBadge md={4} lg={3}>{children} Indoors</ColBadge>
					<Col>
						{ model.indoors === "Wait" || model.indoors === "No"
							? (<TagBadge>{model.indoors}</TagBadge>) 
							: (<ParagraphString>{model.indoors}</ParagraphString>)
						}
					</Col>
				</Row>)}
				{model.urban && (<Row>
					<ColBadge md={4} lg={3}>{children} Urban</ColBadge>
					<Col>
						{ model.urban === "Wait" || model.urban === "No"
							? (<TagBadge>{model.urban}</TagBadge>) 
							: (<ParagraphString>{model.urban}</ParagraphString>)
						}
					</Col>
				</Row>)}
				
			</ListGroup.Item>
		);
	}
}
