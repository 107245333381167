import { ICellData } from "Interfaces/ICellData";
import { IParser } from "Interfaces/IParser";
import { ITableRow } from "Interfaces/ITableRow";

export default class FarmableStudent implements 
		IParser<FarmableStudent>,
		ITableRow {
	public name:string;
	public tldr:string;
	public type:string;
	public from:string;
	public updated:string;
	public whenToFarm:string;
	public whyFarm:string;
	public howMuchToFarm:string;
	public similar:string;
	public comments:string;
	getHeaders() {
		return [
			"name",
			"tldr",
			"type",
			"from",
			"updated",
			"whenToFarm",
			"whyFarm",
			"howMuchToFarm",
			"similar",
			"comments",
		]
	}
	toArray() {
		return [
			this.name,
			this.tldr,
			this.type,
			this.from,
			this.updated,
			this.whenToFarm,
			this.whyFarm,
			this.howMuchToFarm,
			this.similar,
			this.comments,
		]
	}
	toKeyValues() {
		return {
			name: this.name,
			tldr: this.tldr,
			type: this.type,
			from: this.from,
			updated: this.updated,
			whenToFarm: this.whenToFarm,
			whyFarm: this.whyFarm,
			howMuchToFarm: this.howMuchToFarm,
			similar: this.similar,
			comments: this.comments,
		}
	}

	parse(data:ICellData[]): FarmableStudent {
		try {
			return FarmableStudent.parse(data);
		} catch {
			return null;
		}
	}

	static parse(data:ICellData[]): FarmableStudent {
		let student = new FarmableStudent();
		student.name = data[0]?.v ?? '';
		student.tldr = data[2]?.v ?? '';
		student.type = data[3]?.v ?? '';
		student.from = data[4]?.v ?? '';
		student.updated = data[5]?.f ?? '';
		student.whenToFarm = data[6]?.v ?? '';
		student.whyFarm = data[7]?.v ?? '';
		student.howMuchToFarm = data[8]?.v ?? '';
		student.similar = data[11]?.v ?? '';
		student.comments = data[12]?.v ?? '';

		return student;
	}
	static isHardMode(data:ICellData[]):boolean {
		return data[3]?.v?.indexOf("Hard Quest") >= 0 ?? false;
	}
	static exists(data:ICellData[]):boolean {
		return data[0]?.v?.length > 0 ?? false;
	}
	extractWhenToFarm() {
		const hqTags = /Always|Anytime|Outside of (Other )?Events|When you simp|TBA/gi
		const res = this.whenToFarm.match(hqTags) ?? ["Wait?"]
		this.whenToFarm = res[0].replaceAll("Other ", "");
		return this.whenToFarm;
	}
	getHqPriority() {
		switch (this.extractWhenToFarm()) {
			case "Always": return 1;
			case "Anytime": return 2;
			case "Outside of Events": return 3;
			case "When you simp": return 4;
			case "Wait":
			case "TBA": return 5;
		}

		return 6;
	}
	getStages() {
		const regx = /HQ.+/i
		return this.from.match(regx)[0].substring(3);
	}
	whenToFarmFlavor() {
		switch (this.extractWhenToFarm()) {
			case "Always": return "ALWAYS farm regardless of ongoing Events. Do them daily!";
			case "Anytime": return "Worth farming even during events. Your call.";
			case "Outside of Events": return "";
			case "When you simp": return "";
			case "Wait":
			case "TBA": return "Midokuni is lazy";
		}
	}
}
