import { ColBadge } from "Components/ColBadge";
import IconLink from "Components/IconLink";
import { ParagraphString } from "Components/ParagraphString";
import { TagBadge } from "Components/TagBadge";
import GenericStudent from "Models/GenericStudent";
import React from "react";
import { Badge, Card, Col, ListGroup, Row } from "react-bootstrap";

export class GenericStudentRenderer extends React.Component<{ model: GenericStudent}> {
	render() {
		const model = this.props.model;
		return (
			<ListGroup variant="flush">
				{
					model?.displayData?.map(data => (
						<ListGroup.Item>
							<Row>
								<Col>
									<Card.Subtitle><Badge bg="secondary">{data.label}</Badge></Card.Subtitle>
									<ParagraphString As={Card.Text}>
										{data.content?.toString() ?? ''}
									</ParagraphString>
								</Col>
							</Row>
						</ListGroup.Item>
					))
				}
			<ListGroup.Item>
				<Row>
					<ColBadge>Maintainer</ColBadge>
					<Col>
						{
							model.source.author.split('/').map( data => (<TagBadge>{data.trim()}</TagBadge>))
						}
					</Col>
				</Row>
				<Row>
					<ColBadge>Source</ColBadge>
					<Col>
						<IconLink
							image={model.source?.iconUrl ?? '/logo512.png'}
							label={model.source?.name ?? 'Reference'}
							href={model.source?.url ?? '#Unknown'}
						/>
					</Col>
				</Row>
			</ListGroup.Item>
			<Card.Text className="text-muted"><sub>Updated on {model?.updated ?? 'Unknown'}<br />I do not own the data of this tab. Contact the respective owners/maintainers for any issues.</sub></Card.Text>
		</ListGroup>
		)
	}
}
