import StudentNameDatalist from "Components/StudentNameDatalist";
import { IMultiStudentPickerProps } from "Interfaces/Props/IStudentPickerProps";
import StudentMap from "Models/StudentMap";
import React, { useState } from "react";
import { CloseButton, Col, Row } from 'react-bootstrap';

export default function MultiStudentPicker(props: IMultiStudentPickerProps) {
	const {hasFull} = props;
	const mapper = new StudentMap();
	const fullNames = Object.keys(StudentMap.revMap).map(id => mapper.getProperName(id)).filter(name => name.length > 0).sort();
	const [text, setText] = useState(hasFull ? fullNames[0] : "");
	
	const handleTextChange = (event:React.ChangeEvent<HTMLInputElement>) => {
		if (
			event.target.value === "/" ||
			event.target.value === "," ||
			event.target.value === "\\"
		) return;
		if (event.target.value.endsWith(",") || event.target.value.endsWith("/") || event.target.value.endsWith("\\")) {
			handleInput(new StudentMap().getProperName(text), ",");
		} else {
			const student = new StudentMap().getProperName(event.target.value);
			setText(student ?? event.target.value);
		}
	}

	const addStudent = (student:string) => {
		if (!student)
			return;
		props.push(student);
		setText("");
	}

	const handleInput = (student:string, key:string) => {
		switch (key) {
			case "Backspace": 
				if (text.length || !!!props.pop)
					return;
				props.pop();
				const len = props.target.length;
				if (len>0)
					setText(props.target[len-1]+",");
				else setText("")
			case "Enter":
			case ",":
			case "\\":
			case "/": addStudent(student);
		}
	}

	const handleKeyDown = (event:React.KeyboardEvent<HTMLInputElement>) => {
		const student = new StudentMap().getProperName(text);
		handleInput(student, event.key);
	}

	if (hasFull) {
		return (
			<Row>
			  <Col className="search">
			  	<select onChange={(e)=>{
					// console.log(e.target.value);
					setText(e.target.value);
				}}>
					{fullNames.map(name => <option key={name} value={name}>{name}</option>)}
				</select>
				  <input
					  className="search"
					  type="text"
					  name="studentName"
					  list="studentNames"
					  value={text}
					  onChange={handleTextChange}
					  onKeyDown={handleKeyDown}
					  autoFocus={props.autoFocus}
				  />
				  <CloseButton style={{marginLeft:5, transform: "rotate(45deg)"}} aria-label="Add" onClick={()=>addStudent(new StudentMap().getProperName(text))}/>
				  {!!props.setTarget && <CloseButton style={{marginLeft:5}} aria-label="Clear" onClick={()=>props.setTarget([])}/>}
			  </Col>
			  <StudentNameDatalist />
			</Row>
			);
	}
	
	  return (
	  <Row>
		<Col className="search">
			<input
				className="search"
				type="text"
				name="studentName"
				list="studentNames"
				value={text}
				onChange={handleTextChange}
				onKeyDown={handleKeyDown}
				autoFocus={props.autoFocus}
			/>
			<CloseButton style={{marginLeft:5, transform: "rotate(45deg)"}} aria-label="Add" onClick={()=>addStudent(new StudentMap().getProperName(text))}/>
			<CloseButton style={{marginLeft:5}} aria-label="Clear" onClick={()=>props.setTarget([])}/>
		</Col>
		<StudentNameDatalist />
	  </Row>
	  );
}
