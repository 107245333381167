import StudentMap from "Models/StudentMap";
import React from "react";

export default function StudentNameDatalist() {
	if (document.getElementById("studentNames")) return null;
	return (<datalist id="studentNames">
		{new StudentMap().getStudentNames().map((sname, key) => (
				<option key={key} value={sname}></option>
			))
		}
	</datalist>);
}