import { TableRow } from "Components/TableRow";
import { ITableRow } from "Interfaces/ITableRow";
import { TextAlign } from "chart.js";
import * as CSS from 'csstype';
import React, { MouseEventHandler } from "react";
import Table from 'react-bootstrap/Table';
interface IProps { 
	data: ITableRow[]; 
	variant?: string, 
	align?: TextAlign, 
	padding?: CSS.Property.Padding<string | number> 
	onCellClick?: MouseEventHandler<HTMLTableDataCellElement>;
}
export class MyTable extends React.Component<IProps> {
	render() {
		const data = this.props.data;
		if (typeof data === "undefined" || data.length === 0)
			return (<>No Entry</>);
		const headers = data[0].getHeaders();
		return (
		<Table style={{textAlign: this.props.align}} responsive variant={this.props.variant ?? "dark"} striped bordered hover>
		{/* <Table style={{width: "100vw"}} responsive variant="dark" striped bordered hover> */}
			<thead>
				<TableRow cells={headers} tag="th" />
			</thead>
			<tbody>
				{data.map((row, i) => <TableRow key={`row_${i}`} onClick={this.props.onCellClick} padding={this.props.padding} cells={row.toKeyValues()} />)}
			</tbody>
		</Table>);
	}
}
