import React from "react";

interface IProps {
	width ?: number;
	height ?: number;
	src: string;
	title ?: string;
}

export default function YoutubeEmbed(props: IProps) {
	return (<iframe 
		width={props.width ?? "560"} 
		height={props.height ?? "315"}
		src={props.src}
		title={props.title ?? "YouTube video player"} 
		allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
		referrerPolicy="strict-origin-when-cross-origin" 
		allowFullScreen>
	</iframe>);
}