import { ColBadge } from "Components/ColBadge";
import IconLink from "Components/IconLink";
import { ParagraphString } from "Components/ParagraphString";
import { TagBadge } from "Components/TagBadge";
import FarmableStudent from "Models/FarmableStudent";
import { multiLineSplice, toSingleLine } from "Utils/Common";
import React from "react";
import { Badge, Card, Col, ListGroup, Row } from "react-bootstrap";

export class FarmableStudentRenderer extends React.Component<{ model: FarmableStudent}> {
	render() {
		const model = this.props.model;
		return (
			
			<ListGroup variant="flush">
			<ListGroup.Item>
				<Row>
					<ColBadge>TLDR</ColBadge>
					<Col>
						{multiLineSplice(model.tldr).map(
							str => <TagBadge>{str.trim().replaceAll(/^\(|\)$/g,'')}</TagBadge>
						)}
					</Col>
				</Row>
			</ListGroup.Item>
			<ListGroup.Item>
				<Row>
					<ColBadge>Type</ColBadge>
					<Col>
						{multiLineSplice(model.type).map(
							str => <TagBadge>{str.trim().replaceAll(/^\(|\)$/g,'')}</TagBadge>
						)}
					</Col>
				</Row>
			</ListGroup.Item>
			<ListGroup.Item>
				<Row>
					<ColBadge>From</ColBadge>
					<Col>
						<Card.Text>
							{toSingleLine(model.from)}
						</Card.Text>
						{/* {multiLineSplice(model.from).map(
							str => <TagBadge>{str.trim().replaceAll(/^\(|\)$/g,'')}</TagBadge>
						)} */}
					</Col>
				</Row>
			</ListGroup.Item>
			<ListGroup.Item>
				<Row>
					<ColBadge>When to Farm</ColBadge>
					<Col>
						<Card.Text>
						{toSingleLine(model.whenToFarm)}
						</Card.Text>
					</Col>
				</Row>
			</ListGroup.Item>
			<ListGroup.Item>
				<Row>
					<ColBadge>Farm Until</ColBadge>
					<Col>
						<Card.Text>
							{toSingleLine(model.howMuchToFarm)}
						</Card.Text>
					</Col>
				</Row>
			</ListGroup.Item>
			<ListGroup.Item>
				<Row>
					<Col>
					<Card.Subtitle><Badge bg="secondary">Why Farm</Badge> / <Badge bg="secondary">Why Not Farm</Badge></Card.Subtitle>
						<ParagraphString As={Card.Text}>
							{model.whyFarm}
						</ParagraphString>
					</Col>
				</Row>
			</ListGroup.Item>
			{model.comments && (<ListGroup.Item>
				<Row>
					<Col>
						<Card.Subtitle><Badge bg="secondary">Comments</Badge></Card.Subtitle>
						<ParagraphString As={Card.Text}>
							{model.comments}
						</ParagraphString>
					</Col>
				</Row>
			</ListGroup.Item>)
			}
			<ListGroup.Item>
				<Row>
					<ColBadge>Source</ColBadge>
					<Col xs={4} sm={6} md={4} lg={2}>
						<IconLink
							image="https://ssl.gstatic.com/docs/spreadsheets/favicon3.ico"
							label="Farmables"
							href={`/doc/Farmable/${model.name.replaceAll(' ','_')}`}
						/>
					</Col>
				</Row>
			</ListGroup.Item>
			<Card.Text className="text-muted"><sub>Updated on {model.updated}</sub></Card.Text>
		</ListGroup>
		)
	}
}
