
export const DIFFICULTY_SCORE = {
	Normal: 250000,
    Hard: 500000,
    VeryHard: 1000000,
    Hardcore: 2000000,
    Extreme: 4000000,
    Insane: 6800000,
    Torment: 12200000
}
export const HP_SCORE = {
	JP: {
		Normal:[229000,277000],
		Hard:[458000,554000],
		VeryHard:[916000,1108000],
		Hardcore:[1832000,2216000],
		Extreme:[5392000,6160000],
		Insane:[12449600,14216000],
		Torment:[18876000,19508000]
	},
	EN: {
		Normal:[373000,325000],
		Hard:[746000,650000],
		VeryHard:[1492000,1300000],
		Hardcore:[2984000,2600000],
		Extreme:[5968000,5200000],
		Insane:[10145600,8840000],
		Torment:[18300000,15860000]
	}
}

export const TIME_MULTIPLIER = {
	JP: {
		Normal: 120,
		Hard: 240,
		VeryHard: 480,
		Hardcore: 960,
		Extreme: 1440,
		Insane: 1920,
		Torment: 2400
	},
	EN: {
		Normal: 400,
		Hard: 800,
		VeryHard: 1600,
		Hardcore: 3200,
		Extreme: 6400,
		Insane: 12800,
		Torment: 12800
	}
}

export const TIME_LIMIT = {
	JP: 3600,
	EN: [720, 960]
}
export const KEY = {
	BATCH: "param_batch",
	DIFFICULTY_SCORE: "difficulty_score",
	TIME_LIMIT: "time_limit",
	TIME_MULTIPLIER: "time_multiplier",
	HP_SCORE: "hp_score",
	TIME: "time_elapsed",
	ACTION: "action",
	DISCORD: "discord",
};
export const ACTION = {
	GET_DEFAULT: "get_defaults",
	HELP: "help"
}