import { TagBadge } from "Components/TagBadge";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function About() {
	return (
		<>
			<Row>
				<Col><h1>About Me</h1></Col>
			</Row>
			<Row>
				<Col>Should actually be Contact <span className="text-pink">Us</span> but I'm developing this alone. </Col>
			</Row>
			<Row>
				<Col>I mainly made this /About just so I can test the fancy Navigation Bar above lol. </Col>
			</Row>
			<Row>
				<Col><h2>Discord Server</h2></Col>
			</Row>
			<Row>
				<Col><p>Finally decided to make a server for the website: <a href="https://discord.gg/rnDfMg2Xhg">https://discord.gg/rnDfMg2Xhg</a></p></Col>
			</Row>
			<Row>
				<Col><h2>Social</h2></Col>
			</Row>
			<Row>
				<Col>Feel free to drop me a DM/Message on any of these. I'm mainly on Discord but note that if you only send a Friend Request I might end up ignoring you if you don't leave a message prior to sending the request.</Col>
			</Row>
			<Row>
				<Col>
					<ul>
						<li>
							<Row>
								<Col xs={3} md={2} lg={1}>Discord</Col>
								<Col>midokuni</Col>
							</Row>
						</li>
						<li>
							<Row>
								<Col xs={3} md={2} lg={1}>Youtube</Col>
								<Col><Link to="https://www.youtube.com/@Midokuni">@Midokuni</Link></Col>
							</Row>
						</li>
						<li>
							<Row>
								<Col xs={3} md={2} lg={1}>Blue Sky</Col>
								<Col><Link to="https://bsky.app/profile/midokuni.com">@midokuni.com</Link></Col>
							</Row>
						</li>
						<li>
							<Row>
								<Col xs={3} md={2} lg={1}>X</Col>
								<Col><Link to="https://x.com/Midokuni_Mido">@Midokuni_Mido</Link></Col>
							</Row>
						</li>
						<li>
							<Row>
								<Col xs={3} md={2} lg={1}>Reddit</Col>
								<Col><Link to="https://www.reddit.com/user/Midokuni">u/Midokuni</Link></Col>
							</Row>
						</li>
					</ul>
				</Col>
			</Row>
			<Row>
				<Col><h2>Email</h2></Col>
			</Row>
			<Row>
				<Col>
					<ul>
						<li>
							<Row>
								<Col xs={3} md={2} lg={1}>Personal</Col>
								<Col>... <TagBadge>No</TagBadge></Col>
							</Row>
						</li>
						<li>
							<Row>
								<Col xs={3} md={2} lg={1}>Work</Col>
								<Col>... Also <TagBadge>No</TagBadge></Col>
							</Row>
						</li>
						<li>
							<Row>
								<Col xs={3} md={2} lg={1}>Dev</Col>
								<Col><a href="mailto:dev@midokuni.com">dev@midokuni.com</a></Col>
							</Row>
						</li>
					</ul>
				</Col>
			</Row>
			<Row>
				<Col><h2>Support</h2></Col>
			</Row>
			<Row>
				<Col>How kind of you to want to support me awwww. If you insist:</Col>
			</Row>
			<Row>
				<Col>
					<ul>
						<li>
							<Row>
								<Col xs={3} md={2} lg={1}>Dev</Col>
								<Col>Tell me your thoughts/suggestions/reports on my Socials. 
								I am also in <Link to="https://discord.com/invite/bluearchiveglobal">Blue Archive Official(Global) Discord server.</Link></Col>
							</Row>
						</li>
						<li>
							<Row>
								<Col xs={3} md={2} lg={1}>Ko-fi</Col>
								<Col><Link to="https://ko-fi.com/midokuni">https://ko-fi.com/midokuni</Link></Col>
							</Row>
						</li>
					</ul>
				</Col>
			</Row>
			<br></br>
			<Row>
				<Col><h2>Cost of Maintenance</h2></Col>
			</Row>
			<Row>
				<Col>
					<p>In case anyone new is interested in creating a similar website, these are the fees I pay related to <span className="text-pink">midokuni.com</span>. There is no development cost because I am not paying myself to do this.</p>
					<p>Technically, the minimum you need overall is 0.00 USD (F2P BTW). However, I do recommend at least getting a Domain so you can make it easier to remember/share your website. <span className="text-pink">Hina Loves Midokuni</span> is easy to remember no?</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<h3>Regularly Billed</h3>
					<ul>
						<li>
							<p>10.44 USD: <span className="text-pink">midokuni.com</span> Domain Renewal</p>
							<ul><li><p>Thankfully, <span className="text-pink">midokuni</span> isn't a contested name so it doesn't go up to 3 digits like the others I saw lol. I kinda wanted a <span className="text-pink">midoku.ni</span> domain, but it wasn't supported by Cloudflare yet.</p></li></ul>
						</li>
						<li>
							<p>3.00 USD: Microsoft Business Basic (incl. <span className="text-pink">@midokuni.com</span> emails)</p>
						</li>
						<li>
							<p>0.00 USD: Development / Making a slave work.</p>
						</li>
						<li>
							<p>0.00 USD: Online services.</p>
							<ul><li><p>So far I haven't been forced to need an online service that is behind a paywall. I am considering one of those Google Docs/Sheets-To-Image stuff to create better and live preview images when sharing my links.</p></li></ul>
						</li>
						<li>
							<p>0.00 USD: Cloudflare Plans - Free Tier.</p>
							<ul>
								<li><p>I think some may have experienced some errors during 2024 October, but apparently I've managed to hit the <span className="text-pink">"Daily request limit exceeded for Cloudflare Workers and/or Pages"</span> for the Free tier. If this becomes a trend I may need to upgrade to the Paid Plan which costs:</p>
									<ul><li><p>5.00 USD + some multiple of 0.50 USD depending on how much over the new limit it gets (I doubt we can hit the Paid Plan limit though)</p></li></ul>
								</li>
							</ul>
						</li>
					</ul>
					<h3>One-time Payment</h3>
					<ul>
						<li>
							<p>0.00 USD: <span className="text-pink">hina.loves.midokuni.com</span> Logo.</p>
							<ul><li><p>This is only 0.00 because I made it myself. If you were to ask someone else to make one, please pay them appropriately. Ask the artist for the price and maybe add something on top as long as you aren't as cheap as I am. This will be the face of your website that will be with you for a loooong time. Take care of it.</p></li></ul>
						</li>
					</ul>
				</Col>
			</Row>
		</>
	)
}