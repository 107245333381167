import { ColBadge } from "Components/ColBadge";
import IconLink from "Components/IconLink";
import { ParagraphString } from "Components/ParagraphString";
import { TagBadge } from "Components/TagBadge";
import BannerStudent from "Models/BannerStudent";
import { multiLineSplice, toSingleLine } from "Utils/Common";
import React from "react";
import { Badge, Card, Col, ListGroup, Row } from "react-bootstrap";

export class BannerStudentRenderer extends React.Component<{ models: BannerStudent[], index?: number}> {
	render() {
		const model = this.props?.models[this.props?.index ?? 0];
		
		return (
			
			<ListGroup variant="flush">
			<ListGroup.Item>
				<Row>
					<ColBadge>TLDR</ColBadge>
					<Col>
						{multiLineSplice(model.tldr).map(
							str => <TagBadge>{str.trim().replaceAll(/^\(|\)$/g,'')}</TagBadge>
						)}
					</Col>
				</Row>
			</ListGroup.Item>
			<ListGroup.Item>
				<Row>
					<ColBadge>Date</ColBadge>
					<Col>
					{
						this.props?.models.map((m, i) => m.eta < -60 && i > 0
						? (<Card.Text className="text-muted">
							<sup>{m.start} (ETA: {m.getEta()})</sup>
						</Card.Text>)
						: m.eta < -28 && i > 0
						? (<Card.Text className="text-muted">
							{m.start} (ETA: {m.getEta()})
						</Card.Text>)
						: (<Card.Text>
							{m.start} (ETA: {m.getEta()})
						</Card.Text>))
					}
					</Col>
				</Row>
			</ListGroup.Item>
			<ListGroup.Item>
				<Row>
					<ColBadge>Must Pull</ColBadge>
					<Col>
						<Card.Text>
							{toSingleLine(model.mustPullIf)}
						</Card.Text>
					</Col>
				</Row>
			</ListGroup.Item>
			<ListGroup.Item>
				<Row>
					<ColBadge>Easy Skip</ColBadge>
					<Col>
						<Card.Text>
						{toSingleLine(model.easySkipIf)}
						</Card.Text>
					</Col>
				</Row>
			</ListGroup.Item>
			<ListGroup.Item>
				<Row>
					<ColBadge>Usecase</ColBadge>
					<Col>
						<Card.Text>
							{toSingleLine(model.useCases)}
						</Card.Text>
					</Col>
				</Row>
			</ListGroup.Item>
			<ListGroup.Item>
				<Row>
					<ColBadge>What to do without</ColBadge>
					<Col>
						<Card.Text>
							{toSingleLine(model.whatDo)}
						</Card.Text>
					</Col>
				</Row>
			</ListGroup.Item>
			<ListGroup.Item>
				<Row>
					<ColBadge>Invest</ColBadge>
					<Col>
						<Card.Text>
							{toSingleLine(model.investments)}
						</Card.Text>
					</Col>
				</Row>
			</ListGroup.Item>
			<ListGroup.Item>
				<Row>
					<Col>
						<Card.Subtitle><Badge bg="secondary">Comments</Badge></Card.Subtitle>
						<ParagraphString As={Card.Text}>
							{model.comments}
						</ParagraphString>
					</Col>
				</Row>
			</ListGroup.Item>
			{model.rants && (<ListGroup.Item>
				<Row>
					<Col>
					<Card.Subtitle><Badge bg="secondary">Rants</Badge></Card.Subtitle>
						<ParagraphString As={Card.Text}>
							{model.rants}
						</ParagraphString>
					</Col>
				</Row>
			</ListGroup.Item>)
			}
			<ListGroup.Item>
				<Row>
					<ColBadge>Source</ColBadge>
					<Col xs={4} sm={6} md={4} lg={2}>
						<IconLink
							image="https://ssl.gstatic.com/docs/spreadsheets/favicon3.ico"
							label="Banners"
							href={`/doc/Banner/${model.name.replaceAll(' ','_')}`}
						/>
					</Col>
				</Row>
			</ListGroup.Item>
			<Card.Text className="text-muted"><sub>Updated on {model.updated}</sub></Card.Text>
		</ListGroup>
		)
	}
}
