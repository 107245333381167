import { ITableRowProps } from "Interfaces/Props/ITableRowProps";
import React from "react";


export class TableRow extends React.Component<ITableRowProps> {
	render() {
		switch (this.props.tag) {
			case "th":
				if (this.props.cells instanceof Array)
					return (
						<tr>
							{this.props.cells.map(cell => (<th key={cell} className={cell}>{cell}</th>))}
						</tr>
					);
				break;
			default:
				if (!(this.props.cells instanceof Array))
					return (
						<tr>
							{Object.keys(this.props.cells).map(k => (<td onClick={this.props.onClick} style={{padding: this.props.padding}} key={k} className={k}>{this.props.cells[k]}</td>))}
						</tr>
					);
		}
	}
}
