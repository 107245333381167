import GenericCardRenderer from "Components/ModelRenderers/GenericCardRenderer";
import StudentRenderer from "Components/ModelRenderers/StudentRenderer";
import { Sticky } from "Components/Sticky";
import IStyle from "Interfaces/IStyle";
import IParentProps from "Interfaces/Props/IParentProps";
import BattleHistoryItem from "Models/PVP/BattleHistoryItem";
import Player from "Models/PVP/Player";
import TeamFormation from "Models/TeamFormation";
import React, { useState } from "react";
import { Accordion, Button, Col, Row } from "react-bootstrap";
const styles:IStyle = {
	damageChart: {
		backgroundColor: "rgba(255, 175, 255, 0.5)",
		height: "100%"
	}
}
interface IProps {
	model:BattleHistoryItem, 
	compact?: boolean,
	isPublic?: boolean
}

export default function PVPBattleHistoryItemRenderer(props: IProps) {
	const {model, compact, isPublic} = props;
	const [isDamageShown, setIsDamageShown] = useState(false);
	
	function TeamRenderer(props: {model: TeamFormation, label?: string, sublabel?: string, compact?: boolean}) {
		const {model, label, sublabel, compact} = props;
		if (!!!model) return null;
		let striker=0;
		let special=0;
		return (
			<Row className="" style={{margin:5, padding:5}}>
				<Col lg={2} className="text-center">
					<Sticky offset={70}>
						<h3 className="text-pink">{label ?? "Unit"}</h3>
						<h5 className="text-pink">{sublabel}</h5>
					</Sticky>
				</Col>
				<Col>
					<Row>
						<Col className="px-xl-4 px-lg-2 px-md-1 px-sm-1 px-0" style={styles.student} xs={3} md={2}><StudentRenderer bare compact={compact} model={model.strikers[striker++]} /></Col>
						<Col className="px-xl-4 px-lg-2 px-md-1 px-sm-1 px-0" style={styles.student} xs={3} md={2}><StudentRenderer bare compact={compact} model={model.strikers[striker++]} /></Col>
						<Col className="px-xl-4 px-lg-2 px-md-1 px-sm-1 px-0" style={styles.student} xs={3} md={2}><StudentRenderer bare compact={compact} model={model.strikers[striker++]} /></Col>
						<Col className="px-xl-4 px-lg-2 px-md-1 px-sm-1 px-0" style={styles.student} xs={3} md={2}><StudentRenderer bare compact={compact} model={model.strikers[striker++]} /></Col>
						<Col className="px-xl-4 px-lg-2 px-md-1 px-sm-1 px-0" style={styles.student} xs={3} md={2}><StudentRenderer bare compact={compact} model={model.specials[special++]} /></Col>
						<Col className="px-xl-4 px-lg-2 px-md-1 px-sm-1 px-0" style={styles.student} xs={3} md={2}><StudentRenderer bare compact={compact} model={model.specials[special++]} /></Col>
					</Row>
				</Col>
			</Row>
		)
	}

	function Private({children}:IParentProps) {
		if (isPublic) return null;
		const As = children;
		return <>{children}</>;
	}

	function DamageChart({player}:{player: Player}) {
		if (!isDamageShown) return null;
		let student = 0;
		const maxDamage = Math.max(player.damage[student++],player.damage[student++],player.damage[student++],player.damage[student++],player.damage[student++],player.damage[student++]);
		return (
			<Row className="text-center" style={{margin:5, padding:5, justifyItems: "center"}}>
				<Col lg={2}>
					
				</Col>
				<Col>
					<Row style={{height: "20vh"}}>
						{player.damage.map(damage => (
							<Col className="px-xl-4 px-lg-2 px-md-1 px-sm-1 px-0" 
							
							xs={3} md={2}
							>
								<Row  style={{height: (100-Math.floor((100*damage/maxDamage)))+"%"}}>
									<Col className="text-white">
										{damage.toLocaleString()}
									</Col>
								</Row>
								<Row style={{height: (Math.floor((100*damage/maxDamage)))+"%"}}>
									<Col xs={4}></Col>
									<Col xs={4}>
										<Row style={styles.damageChart} >
										</Row>
									</Col>
									<Col xs={4}></Col>
								</Row>
							</Col>
						))}
					</Row>
				</Col>
			</Row>
		)
	}

	return (
	<Row>
		<Col>
			<Accordion key={`${model.id}_${model.attacker}_${model.defender}`} defaultActiveKey="0" className="block">
				<Accordion.Item eventKey="0">
					<Accordion.Header>
						{model.getDate()}: <Private>[#{model.attacker.rankBeforeBattle}]</Private> {model.attacker.name} vs <Private>[#{model.defender.rankBeforeBattle}]</Private> {model.defender.name} (Winner: {model.didAttackWin 
							? " ATK - "+ model.attacker.getDisplayName() 
							: " DEF - "+ model.defender.getDisplayName()
						})</Accordion.Header>
					<Accordion.Body>
						<Row>
							<Col  xs={12} md={3} lg={3} className="text-center">
								<Sticky offset={70}>
									<Row>
										<Col className="d-flex justify-content-center">
											<Row>
												<Col className={model.didAttackWin ? "winner" : "loser grayscale"}>
													<GenericCardRenderer model={model.attacker}></GenericCardRenderer>
												</Col>
												<Col className={!model.didAttackWin ? "winner" : "loser grayscale"}>
													<GenericCardRenderer model={model.defender}></GenericCardRenderer>
												</Col>
											</Row>
										</Col>
									</Row>
									<Row>
										<Button variant="secondary" onClick={()=>setIsDamageShown(!isDamageShown)}>Toggle Chart</Button>
									</Row>
								</Sticky>
							</Col>

							<Col>
							
								<DamageChart player={model.attacker}/>
								<TeamRenderer compact={compact} label="ATK" sublabel={model.attacker.getDisplayName()} model={model.attacker.team} />
								<DamageChart player={model.defender}/>
								<TeamRenderer compact={compact} label="DEF" sublabel={model.defender.getDisplayName()} model={model.defender.team} />
							</Col>
						</Row>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</Col>
	</Row>);
}
