import { MyTable } from "Components/MyTable";
import { ITableRow } from "Interfaces/ITableRow";
import React, { useEffect, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";

export default function PVP() {
	const [refreshTier, setRefreshTier] = useState(0);
	const [startRank, doSetStartRank] = useState(15001);
	const [tickets, setTickets] = useState(5);
	const [refills, setRefills] = useState(2);

	useEffect(() => {
		const cache = parseInt(localStorage.getItem("midokuni.tool.pvp.rank"));
		if (!!cache) {
			setStartRank(cache);
		}
	},[])

	function setStartRank(rank:number) {
		doSetStartRank(rank);
		localStorage.setItem("midokuni.tool.pvp.rank", rank.toString());
	}

	class PVPAnalyzerRow implements ITableRow {
		win: number;
		rank: number;
		data: string[] = [];
		constructor(win:number, rank:number) {
			this.win = win;
			this.rank = rank;
			const [tier, pyro, coin] = this.getRewards();
			const currRefill = Math.ceil(Math.max(win - tickets, 0)/5)
            const APRefill = 120;
            const APShop = 360;
            const CostShop = 210;
            const ShopRate = Math.floor(APShop/CostShop);
            const CostTicket = 60;
			const remainingPyroxene = pyro - (currRefill * CostTicket);

			this.data = [
				currRefill.toLocaleString(),
				this.win.toLocaleString(),
				this.rank.toLocaleString(),
				tier.toLocaleString(),
				pyro.toLocaleString(),
				coin.toLocaleString(),
			]

			for (const cost of [30, 60, 100, 150, 200, 300]) {
				console.log(`(${APRefill} * ${remainingPyroxene} / ${cost}) + (${ShopRate} * ${coin})`)
				this.data.push(
					(
						(APRefill * remainingPyroxene / cost) + (ShopRate * coin)
					).toLocaleString()
				)
			}
		}

		getRewards():[tier:number, pyroxene:number, coin:number] {
			if (this.rank <= 1) return [1, 45, 125]
            if (this.rank <= 2) return [2, 40, 120]
            if (this.rank <= 10) return [10, 35, 110]
            if (this.rank <= 100) return [100, 30, 100]
            if (this.rank <= 200) return [200, 25, 90]
            if (this.rank <= 500) return [500, 20, 80]
            if (this.rank <= 1000) return [1000, 18, 70]
            if (this.rank <= 2000) return [2000, 16, 60]
            if (this.rank <= 4000) return [4000, 14, 50]
            if (this.rank <= 8000) return [8000, 12, 40]
            if (this.rank <= 15000) return [15000, 10, 30]
            return [15001, 10, 30]
		}
		getHeaders() {
			return ["Refill", "Win#", "Rank", "Tier", "Pyros", "Coins", "AP 00", "AP 03", "AP 06", "AP 09", "AP 12", "AP 15"];
		}
		toArray() {
			return this.data;
		}
		toKeyValues() {
			const headers = this.getHeaders();
			const keyValues = {}
			for (const i in headers) {
				keyValues[headers[i]] = this.data[i];
			}

			return keyValues;
		}
	}
	function Parameters() {
		return (<>
			<Row>
				<Col>Starting Rank</Col>
				<Col>
					<input 
						style={{width:"100%"}}
						type="number"
						placeholder="Starting Rank"
						max={15001}
						value={startRank}
						min={0}
						step={1}
						onChange={(event)=>{
							event.target.value = event.target.value.replaceAll(/[^\d]/g,"");
							if (event.target.value.length) {
								const rank = parseInt(event.target.value);
								setStartRank(rank);
							} else {
								setStartRank(0)
							}
						}}
						>
					</input>
				</Col>
			</Row>
			<Row>
				<Col>Tickets Remaining</Col>
				<Col>
					<input 
						style={{width:"100%"}}
						type="number"
						placeholder="Tickets"
						max={5}
						value={tickets}
						min={0}
						step={1}
						onChange={(event)=>{
							event.target.value = event.target.value.replaceAll(/[^\d]/g,"");
							if (event.target.value.length) {
								const rank = parseInt(event.target.value);
								setTickets(rank);
							} else {
								setTickets(0);
							}
						}}
						>
					</input>
				</Col>
			</Row>
			<Row>
				<Col>Max Ticket Refills</Col>
				<Col>
					<input 
						style={{width:"100%"}}
						type="number"
						placeholder="Refills"
						value={refills}
						min={0}
						step={1}
						onChange={(event)=>{
							event.target.value = event.target.value.replaceAll(/[^\d]/g,"");
							if (event.target.value.length) {
								const rank = parseInt(event.target.value);
								setRefills(rank);
							} else {
								setRefills(0)
							}
						}}
						>
					</input>
				</Col>
			</Row>
		</>
		)
	}

	const totalWins = tickets + (refills * 5);
	const wins = [new PVPAnalyzerRow(0, startRank)]
	function getPeakRank(rank: number) {
		switch (rank) {
			case 3:
			case 4: return 1;
			case 5: return 2;
			case 6: return 3;
			default: return Math.floor(rank*0.7);
		}
	}
	let rank = getPeakRank(startRank);
	for (let i=1; i<= totalWins && rank > 0; i++) {
		wins.push(new PVPAnalyzerRow(i, rank));
		console.log(rank)
		rank = getPeakRank(rank);
	}


	return (<Row>
		<Col>
			<Row>
				<Alert variant="warning" dismissible>
					<h3>How to read:</h3>
					<label><b>Refill</b></label>: Number of PVP Ticket purchases in order to reach this rank assuming no losses<br></br>
					<label><b>Win#</b></label>: How many wins it takes to reach this Rank<br></br>
					<label><b>Rank</b></label>: The Rank being considered<br></br>
					<label><b>Tier</b></label>: The Reward tier that corresponds to the Rank<br></br>
					<label><b>Pyros / Coins</b></label>: Rewards you receive upon claiming at that Rank<br></br>
					<label><b>AP X</b></label>: The net value of the Rewards at this tier when converted into AP assuming that you are doing X AP Refills using Pyroxene<br></br>
				</Alert>
			</Row>
			<Row>
				<Col xs={12} md={6}>
					{Parameters()}
				</Col>
			</Row>
			<Row>
				<MyTable padding="0.1em" align="right" variant="violet" data={wins}></MyTable>
			</Row>
		</Col>
	</Row>);
}

