import IconButton from "Components/IconButton";
import IconLink from "Components/IconLink";
import MyLoadingSpinner from "Components/MyLoadingSpinner";
import { ParagraphString } from "Components/ParagraphString";
import { TagBadge } from "Components/TagBadge";
import { ICellData } from "Interfaces/ICellData";
import { IParser } from "Interfaces/IParser";
import IStyle from "Interfaces/IStyle";
import ImageGallery from "Modals/ImageGallery";
import Sheet from "Models/Sheet";
import React, { useEffect, useMemo, useState } from "react";
import { Accordion, Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
const styles:IStyle = {
	cardContainer: {
		display: "flex", 
		flexDirection: "row", 
		flexWrap: "wrap"
	},
	cardGroup: {
		margin: "1em",
		zIndex: 1,
		padding: "0 0.3em 0 0.3em",
		border: "1px solid",
		borderColor: "pink",
	},
	compactCardGroup: {
		margin: "1em 1em 0.1em 1em",
		zIndex: 1,
		padding: "0 0.3em 0 0.3em",
	},
	card: {
		textAlign: "center",
		display: "block",
		// margin: "auto",
		marginBottom: "auto",
		marginLeft: "auto",
		marginRight: "auto",
		// verticalAlign: "top"
	},
	spinnerRow: {
		// alignContent: "center",
		// alignItems: "center",
		// alignSelf: "center",
		// textAlign: "center"
		zIndex: 10
	},
	button: {
		marginRight: 5
	},
	buttonLink: {
		margin: 5,
	},
	selectButton: {
		marginRight: "1em",
		padding: "0.3em"
	}
}
const SPREADSHEET_ID = "1wn8piQiZKDyxXYPNWzJ6_vS1XBYJBTUPalVbcq-va78";
const SPREADSHEET_GID = "0";
class ResourceEntry implements 
		IParser<ResourceEntry>
{
	tags: string[];
	title: string;
	resourceUrl: string;
	description: string;
	author: string;
	footnote: string;
	imageUrl: string;
	languages: string[];
	updatedDate: Date;
	updated: string;

	parse(data: ICellData[]):ResourceEntry {
		const entry = new ResourceEntry();
		let i = 0;
		entry.tags = (data[i++]?.v ?? '').split(',');
		entry.tags.forEach((tag, i) => entry.tags[i] = tag.trim());
		entry.title = data[i++]?.v?.trim() ?? '';
		entry.resourceUrl = data[i++]?.v?.trim() ?? '';
		entry.description = data[i++]?.v?.trim() ?? '';
		entry.author = data[i++]?.v?.trim() ?? '';
		entry.footnote = data[i++]?.v?.trim() ?? '';
		entry.imageUrl = data[i++]?.v?.trim() ?? '';
		entry.languages = (data[i++]?.v ?? 'English').split(',');
		entry.languages.forEach((lang, i) => entry.languages[i] = lang.trim());
		if (data[i]?.v?.startsWith("Date(")) {
			const [y, m, d, h, mi, s] = data[i].v.substring(5, data[i].v.length-1).split(',').map(a=>parseInt(a));
			entry.updatedDate = new Date(y, m, d, h, mi, s);
		}
		entry.updated = data[i++]?.f ?? '';
		return entry;
	}

	hasImage(): boolean {
		return this.imageUrl.length > 0
	}

	static isEntry(data: ICellData[]) {
		try {
			return data[1].v.length > 0 && data[1].v !== "Title";
		} catch {
			return false;
		}
	}
}
export default function ResourceRepository() {
	const [isLoading, setIsLoading] = useState(true);
	const [resources, setResources] = useState<Sheet>(new Sheet("", ""));
	const [compact, setIsCompact] = useState(true);
	const [filterLanguages, setFilterLanguages] = useState<string[]>(["English"]);
	const [filterTags, setFilterTags] = useState<string[]>([]);
	const [filterAuthors, setFilterAuthors] = useState<string[]>([]);
	const [search, setSearch] = useState("");
	const [data, tags, authors, languages]  = useMemo(
		() => {
			if (!resources.json) return [[],[],[],[]]
			const disp = resources.findAndParseByCondition((row) => {
				return ResourceEntry.isEntry(row);
			}, new ResourceEntry()).sort((a,b) => b.description.length - a.description.length ).sort((a,b) => {
				if (a.hasImage() === b.hasImage())
					return 0;
				if (a.hasImage())
					return -1;
				return 1;
			}).sort( (a,b) => (b.updatedDate?.getTime() ?? 0) - (a.updatedDate?.getTime() ?? 0));
			const tags = [...new Set(disp.map((d) => d.tags).reduce<string[]>((prev, curr) => {
				prev.push(...curr);
				return prev;
			}, []))];
			const authors = [...new Set(disp.map((d) => d.author).reduce<string[]>((prev, curr) => {
				prev.push(curr);
				return prev;
			}, []))];
			const lang = [...new Set(disp.map((d) => d.languages).reduce<string[]>((prev, curr) => {
				prev.push(...curr);
				return prev;
			}, []))];
			setFilterLanguages(lang);
			setFilterTags(tags);
			setFilterAuthors(authors);
			return [disp, tags.sort(), authors.sort(), lang];
		},
		[resources]
	);
	const display = useMemo(() => {
		return data.filter( d => {
			return filterAuthors.indexOf(d.author) >= 0 
			&& d.tags.filter(t => filterTags.indexOf(t) >= 0).length > 0
			&& d.languages.filter(t => filterLanguages.indexOf(t) >= 0).length > 0
			;
		});
	}, [data, filterTags, filterAuthors, filterLanguages, search]);
	
	useEffect(() => {
		let ignore = false;
		const loadAsync = async () => {
			const sheet = new Sheet(SPREADSHEET_ID, SPREADSHEET_GID);
			const sheetTask = await sheet.initialize();
			if (!ignore) {
				setResources(sheetTask);
				setIsLoading(false)
			}
		}

		loadAsync();

		return () => {
			ignore = true
		}
	}, [])
	

	function ResourceCard({data}:{data:ResourceEntry}) {
		return (
			<Card className="text-pink resource-entry card" key={data.title} style={styles.card}>
				<Card.Header>
					<Card.Title>
						<IconLink
							label={data.title}
							href={data.resourceUrl}
						/>
					</Card.Title>
					<Card.Subtitle>
						{data.tags.map(t => <TagBadge>{t}</TagBadge>)}
					</Card.Subtitle>
				</Card.Header>
				<Card.Body>
					{data.hasImage() && <Card.Img as={"span"}><ImageGallery title={data.title} srcs={[data.imageUrl]}></ImageGallery></Card.Img>}
					<Card.Text><ParagraphString>{data.description}</ParagraphString></Card.Text>
					{/* <Card.Text>
						<IconLink
							label="Link Here!"
							href={data.resourceUrl}
						/>
					</Card.Text> */}
				</Card.Body>
				<Card.Footer><Card.Text>{data.author}{data.footnote.length > 0 && <small><br></br>{data.footnote}</small>}</Card.Text></Card.Footer>
				<Card.Footer><Card.Text>{data.languages.map(t => <TagBadge>{t}</TagBadge>)}</Card.Text></Card.Footer>
			</Card>
		)
	}


	return (<Row>
		<Col>
			<Row><Col>
				<Alert variant="primary" dismissible><p>Hello, I am currently looking for <span className="text-pink">resources, guides, suggestions and others</span> that can be put here (Link to the form below).</p>
					<p>I am also looking for people who can do anything of the following:
					<ul>
						<li>Help regularly <span className="text-pink">keep things updated</span> in case there are some links or images that get outdated.</li>
						<li>Help <span className="text-pink">review submissions</span> to see if they are alright to add</li>
					</ul></p>
					<p>If anyone is interested feel free to contact me in Discord or via email.</p>
					</Alert>
			</Col></Row>
			<Row>
				<Col>
				<h1 className="text-pink">Blue Archive Resources, Guides, Tools, etc.</h1>
				</Col>
			</Row>
			<Row>
				<Col>
					<p>This is a community-ran repository of resources, guides, tools, and anything helpful to someone there playing Blue Archive. In here you can find resources that might be of use to you or someone else.</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<Row>
						<Col style={styles.buttonLink}>
							<IconButton 
								// image="/logo512.png"
								image="https://www.google.com/s2/favicons?sz=64&domain=google.com"
								label="Missing something? Want to add something? Submit a Request!"
								href="https://docs.google.com/forms/d/e/1FAIpQLScaPbeUc6CvqsebJKVgfUa7bdvk-JVCmRgsut4r_W1i11PqKQ/viewform?usp=sf_link" 
							/>
						</Col>
					</Row>
					<Accordion>
						<Accordion.Item eventKey="0">
							<AccordionHeader><h4>Filters</h4></AccordionHeader>
							<AccordionBody>
								<Row style={styles.filterRow}>
									<Col xs={12}>
										<label>Tags</label>
									</Col>
									<Col md={2}>
										<Button style={styles.selectButton} variant="violet" onClick={() => setFilterTags(tags)}>Select All</Button>
										<Button style={styles.selectButton} variant="violet" onClick={() => setFilterTags([])}>Deselect All</Button>
									</Col>
								</Row>
								<Row>
									{tags.map(tag => {
										return <Col><Form.Check // prettier-ignore
										checked={filterTags.indexOf(tag) >= 0}
										onClick={()=>{
											if (filterTags.indexOf(tag) >= 0) {
												setFilterTags(filterTags.filter(fa => fa !== tag))
											} else {
												setFilterTags([...filterTags, tag])
											}
										}}
										key={`tags_${tag}`}
										type="radio"
										id={`tags_${tag}`}
										label={tag}
									/></Col>
									})}
								</Row>
								<hr></hr>
								<Row style={styles.filterRow}>
									<Col xs={12}>
										<label>Author</label>
									</Col>
									<Col md={2}>
										<Button style={styles.selectButton} variant="violet" onClick={() => setFilterAuthors(authors)}>Select All</Button>
										<Button style={styles.selectButton} variant="violet" onClick={() => setFilterAuthors([])}>Deselect All</Button>
									</Col>
								</Row>
								
								<Row>
									{authors.map(a => {
										return <Col><Form.Check // prettier-ignore
										checked={filterAuthors.indexOf(a) >= 0}
										onClick={()=>{
											if (filterAuthors.indexOf(a) >= 0) {
												setFilterAuthors(filterAuthors.filter(fa => fa !== a))
											} else {
												setFilterAuthors([...filterAuthors, a])
											}
										}}
										key={`authors_${a}`}
										type="radio"
										id={`authors_${a}`}
										label={a}
									/></Col>
									})}	
								</Row>
								<hr></hr>
								<Row style={styles.filterRow}>
									<Col xs={12}>
										<label>Supported Languages</label>
									</Col>
									<Col md={2}>
										<Button style={styles.selectButton} variant="violet" onClick={() => setFilterLanguages(languages)}>Select All</Button>
										<Button style={styles.selectButton} variant="violet" onClick={() => setFilterLanguages([])}>Deselect All</Button>
									</Col>
								</Row>
								<Row>
									{languages.map(a => {
										return <Col><Form.Check // prettier-ignore
										checked={filterLanguages.indexOf(a) >= 0}
										onClick={()=>{
											if (filterLanguages.indexOf(a) >= 0) {
												setFilterLanguages(filterLanguages.filter(fa => fa !== a))
											} else {
												setFilterLanguages([...filterLanguages, a])
											}
										}}
										key={`lang_${a}`}
										type="radio"
										id={`lang_${a}`}
										label={a}
									/></Col>
									})}	
								</Row>
								<hr></hr>
								<Row style={styles.filterRow}>
									<Col md={2}>
										<label>Search</label>
									</Col>
									<Col>
									<input disabled placeholder="Work in Progress"></input>
									</Col>
								</Row>
							</AccordionBody>
						</Accordion.Item>
					</Accordion>
				</Col>
			</Row>
			<Row hidden={!isLoading}>
				<Col></Col>
				<Col style={styles.spinnerRow}>
					<MyLoadingSpinner isLoading={isLoading}/>
				</Col>
				<Col></Col>
			</Row>
			<Row style={{verticalAlign: "top"}}>
				<Col style={styles.cardContainer}>
					{display.map(r => <ResourceCard data={r}></ResourceCard>)}
				</Col>
			</Row>
		</Col>
	</Row>
	);
}