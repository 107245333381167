import IParentProps from "Interfaces/Props/IParentProps";
import React from "react";
import { Badge, Col, ColProps } from "react-bootstrap";

export class ColBadge extends React.Component<IParentProps & ColProps> {
	render() {
		const {
			xs,
			md,
			sm,
			lg,
		} = this.props;
		return (<Col xs={xs ?? 12} sm={sm} lg={lg} md={md ?? 2}><Badge bg="secondary">{this.props.children}</Badge></Col>);
	}
}
