import React, { useMemo } from "react";
import { QRCode } from "react-qrcode-logo";
interface IProps {
	size?: number;
}


export default function CurrentQR(props: IProps) {
	// const [value, setValue] = useState(window.location.href);
	const value = useMemo(()=>window.location.href, [window.location.href])
	const calced = Math.ceil(Math.sqrt(value.length))*25;
	function getSize() {
		// const calced = Math.ceil(Math.sqrt(window.location.href.length)*5);
		// const calced = Math.ceil(Math.sqrt(window.location.href.length*16)*5);
		const min = 11;
		// const min = Math.pow(2, 7);
		// let i=Math.pow(2, 9)
		let i=23
		for(; i>min; i--) {
			const sq = i*i;
			if (calced > sq)
				return sq;
		}
		return i*i;
		// Math.ceil(Math.sqrt(window.location.href.length*16)*5)
	}
	const {size} = props;
	return (<QRCode 
		bgColor="#ffafff" 
		fgColor="#383744" 
		quietZone={5} 
		removeQrCodeBehindLogo 
		logoPaddingStyle="circle" 
		// style={{width: "auto", height: "auto"}}
		size={getSize()} 
		logoImage="/logo512.png" 
		value={value}
	/>);
}