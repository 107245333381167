import IconButton from "Components/IconButton";
import StudentRenderer from "Components/ModelRenderers/StudentRenderer";
import MyLoadingSpinner from "Components/MyLoadingSpinner";
import { TagBadge } from "Components/TagBadge";
import { GOOGLE_SPREADSHEET_GID, GOOGLE_SPREADSHEET_ID } from "Constants/Common";
import IStyle from "Interfaces/IStyle";
import BannerStudent from "Models/BannerStudent";
import Sheet from "Models/Sheet";
import Student from "Models/Student";
import { multiLineSplice } from "Utils/Common";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Card, CardGroup, Col, Form, Row } from "react-bootstrap";
import { Xwrapper, useXarrow } from 'react-xarrows';
const styles:IStyle = {
	cardContainer: {
		display: "flex", 
		flexDirection: "row", 
		flexWrap: "wrap"
	},
	cardGroup: {
		margin: "1em",
		zIndex: 1,
		padding: "0 0.3em 0 0.3em",
		border: "1px solid",
		borderColor: "pink",
	},
	compactCardGroup: {
		margin: "0.1em 1em 0.1em 1em",
		zIndex: 1,
		padding: "0 0.3em 0 0.3em",
		display: "block",
		marginBottom: "auto",
		marginTop: "5px",
		border: "1px dotted"
	},
	card: {
		textAlign: "center",
		display: "block",
		marginBottom: "auto",
		marginLeft: "auto",
		marginRight: "auto",
	},
	spinnerRow: {
		// alignContent: "center",
		// alignItems: "center",
		// alignSelf: "center",
		// textAlign: "center"
		zIndex: 10
	},
	button: {
		marginRight: 5
	},
	buttonLink: {
		margin: 5,
	},
}
const defaultFrom = moment().add(-14, "days").format("YYYY-MM-DD");
const defaultTo = moment().add(30*7, "days").format("YYYY-MM-DD");
const dateNowObj = moment();
export default function BannerSummary() {
	const [isLoading, setIsLoading] = useState(true);
	const [dateFrom, setDateFrom] = useState(defaultFrom);
	const [dateTo, setDateTo] = useState(defaultTo);
	const [banners, setBanners] = useState<Sheet>(new Sheet("", ""));
	const dateFromDiff = useMemo(()=>moment(dateFrom).diff(dateNowObj, "days"),[dateFrom]);
	const dateToDiff = useMemo(()=>moment(dateTo).diff(dateNowObj, "days"),[dateTo]);
	// const [display, setDisplay] = useState<BannerStudent[]>([]);
	// const [bannerGroups, setBannerGroups] = useState<{[eta: number]: BannerStudent[]}>({});
	// const [dates, setDates] = useState<string[]>([]);
	const updateXarrow = useXarrow()
	const [compact, setIsCompact] = useState(true);
	const display = useMemo(
		() => !banners.json ? [] : banners.findAndParseByCondition((row) => {
			const eta = BannerStudent.parseEta(row);
			return eta >= dateFromDiff && eta <= dateToDiff;
		}, new BannerStudent()),
		[banners, dateFrom, dateTo]
	);

	const data: {[eta: number]: BannerStudent[]} = {};
	const date = [];
	for (const match of display) {
		if (data[match.eta]) data[match.eta].push(match);
		else {
			data[match.eta] = [match];
			date.push(match.eta);
		}
	}
	const bannerGroups = data;
	const dates = date;

	const loadAsync = async () => {
		const banner = new Sheet(GOOGLE_SPREADSHEET_ID, GOOGLE_SPREADSHEET_GID.BANNER);
		const bannerTask = await banner.initialize();
		setBanners(bannerTask);
		setIsLoading(false)
	}

	useEffect(() => { 
		loadAsync() 
	}, []);
	// useEffect(() => {
	// 	// if (!!!banners.json) return;
	// 	// const matches = banners.findAndParseByCondition((row) => BannerStudent.parseEta(row) >= -14, new BannerStudent());
	// 	// setDisplay(matches);
	// 	// const data: {[eta: number]: BannerStudent[]} = {};
	// 	// const date = [];
	// 	// for (const match of matches) {
	// 	// 	if (data[match.eta]) data[match.eta].push(match);
	// 	// 	else {
	// 	// 		data[match.eta] = [match];
	// 	// 		date.push(match.eta);
	// 	// 	}
	// 	// }
	// 	// setBannerGroups(data);
	// 	// setDates(date);
	// 	// setIsLoading(false);
	// }, [banners]);


	function SummaryCard({data}:{data:BannerStudent}) {
		return (
			<Card className="banner-summary card" key={data.name} style={styles.card}>
				<StudentRenderer link="/StudentInsights/%id%" compact={compact} bare model={Student.fromName(data.name)}></StudentRenderer>
				<Card.Body>
					<Card.Text>{multiLineSplice(data.tldr).map(
						(str,i) => <TagBadge key={i}>{str.trim().replaceAll(/^\(|\)$/g,'')}</TagBadge>
					)}</Card.Text>
				</Card.Body>
			</Card>
		)
	}

	function BannerGroup({data}:{data:BannerStudent[]}) {
		return (
			<Card className="text-pink banner-group" key={data[0].start} id={data[0].start} style={compact ? styles.compactCardGroup : styles.cardGroup}>
				<Card.Header><Card.Title>{data[0].start} ({data[0].getEta()})</Card.Title></Card.Header>
				<CardGroup>
					{data.map(card => <SummaryCard key={card.name+"_"+card.start} data={card}/>)}
				</CardGroup>
			</Card>
		)
	}

	function Banners() {
		const keys = dates;
		// const keys = Object.keys(bannerGroups).sort((a, b) => {
		// 	console.log(Date.parse(a));
			
		// 	return moment(a, "YYYY-MMM-DD").valueOf() - moment(b, "YYYY-MMM-DD").valueOf()
		// });
		// const keys = Object.keys(bannerGroups).sort((a, b) => Date.parse(a) - Date.parse(b));

		return (
			<Xwrapper>
				{keys.map(key => <BannerGroup key={key} data={bannerGroups[key]} />)}
				{/* {Object.keys(keys).map(keystr => {
					const key = parseInt(keystr);
					if (!keys[key+1]) return null; 
					return <Xarrow 
						// path="grid"
						// gridBreak="0%"
						start={bannerGroups[keys[key]][0].start} 
						end={bannerGroups[keys[key+1]][0].start}
						startAnchor="right"
						endAnchor="left"
						color="rgb(255, 175, 255)"
					/>
				})} */}
			</Xwrapper>
		)
	}

	return (<Row>
		<Col>
			<Row>
				<Col>
					<Row>
						<Col style={styles.buttonLink}>
							<IconButton 
								// image="/logo512.png"
								image="https://ssl.gstatic.com/docs/spreadsheets/favicon3.ico"
								label="Banner Summary Spreadsheet ver."
								href="https://docs.google.com/spreadsheets/d/1BO-Uh_CsAOIetPjrNyFy9-7r1a4RAtVwK135bBC3hzM#gid=502736701" 
							/>
						</Col>
					</Row>
					<Row style={styles.filterRow}>
						<Col md={2}>
							<label>Start Date</label>
						</Col>
						<Col>
							<input 
								style={{width:"100%"}}
								type="date"
								placeholder={`From (${defaultFrom})`}
								value={dateFrom}
								onChange={(event)=>{ setDateFrom(event.target.value) }}
							>
							</input>
						</Col>
						<Col xs={1}>-</Col>
						<Col>
							<input 
								style={{width:"100%"}}
								type="date"
								placeholder={`Until (${defaultTo})`}
								value={dateTo}
								onChange={(event)=>{ setDateTo(event.target.value) }}
								// onChange={(event)=>{
								// 	setFilters({
								// 		...filters,
								// 		to: event.target.value
								// 	})
								// }}
								>
							</input>
						</Col>
					</Row>
					<Row>
						<Col>
							<div key="student-compact" className="mb-3">
								<Form.Check 
								checked={compact}
								type="switch"
								id="student-compact"
								label="Compact Mode"
								onChange={(a)=>{
									setIsCompact(a.target.checked);
								}}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col className="text-pink">
							<h1>Click on a student for better insights and details.</h1>
						</Col>
					</Row>
					<hr></hr>
				</Col>
			</Row>
			<Row hidden={!isLoading}>
				<Col></Col>
				<Col style={styles.spinnerRow}>
					<MyLoadingSpinner isLoading={isLoading}/>
				</Col>
				<Col></Col>
			</Row>
			<Row>
				<Col style={styles.cardContainer}>
					<Banners />
				</Col>
			</Row>
		</Col>
	</Row>
	);
}