import MyLoadingSpinner from "Components/MyLoadingSpinner";
import { MyTable } from "Components/MyTable";
import IStyle from "Interfaces/IStyle";
import { ITableRow } from "Interfaces/ITableRow";
import { getJson } from "Utils/Common";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";

const styles:IStyle = {
	titleS: {
		fontSize: "3.6em",
		textAlign: "right",
		paddingRight: 0,
		marginTop: "-0.3em"
	},
	titleEX: {
		fontSize: "1.5em",
		textAlign: "left",
		paddingLeft: 0,
	},
	titleExtra: {
		padding: 0
	},
	spinnerRow: {
		zIndex: 10
	}
}
interface IStudentJSON {
	breakpoints: [from:number, to:number][],
	exp: number[]
}
export default function StudentExp() {
	const [report4, doSetReport4] = useState(0);
	const [report3, doSetReport3] = useState(0);
	const [report2, doSetReport2] = useState(0);
	const [report1, doSetReport1] = useState(0);
	const reports = [report1, report2, report3, report4];
	function setReport1(count:number) {
		doSetReport1(count);
		localStorage.setItem("midokuni.tool.sex.report1", count.toString());
	}
	function setReport2(count:number) {
		doSetReport2(count);
		localStorage.setItem("midokuni.tool.sex.report2", count.toString());
	}
	function setReport3(count:number) {
		doSetReport3(count);
		localStorage.setItem("midokuni.tool.sex.report3", count.toString());
	}
	function setReport4(count:number) {
		doSetReport4(count);
		localStorage.setItem("midokuni.tool.sex.report4", count.toString());
	}
	const setReports = [setReport1, setReport2, setReport3, setReport4];
	
	useEffect(() => {
		for (const i of [1,2,3,4]) {
			const cache = parseInt(localStorage.getItem("midokuni.tool.sex.report"+i));
			if (!!cache) {
				setReports[i-1](cache);
			}
		}
	},[])
	const [data, setData] = useState<IStudentJSON>(null);
	const [isLoading, setIsLoading] = useState(true);
	function Title() {
		return <Row>
			<Col style={styles.titleS}>BA </Col>
			<Col xs={2} sm={1} style={styles.titleS}><span className="text-pink" >S</span></Col>
			<Col style={styles.titleExtra}>
				<Row><Col><sub>TUDENT</sub></Col></Row>
				<Row><Col><span className="text-pink" style={styles.titleEX}>EX</span><span>PERIENCE</span></Col></Row>
			</Col>
		</Row>
	}
	async function loadData() {
		setData((await getJson("/files/data/student.json")));
		setIsLoading(false)
	}
	useEffect(() => {
		loadData();
	}, []);

	function calculateStudentExp([from, to]:[from:number, to:number]) {
		if (!!!data) return undefined;
		if (from > to) {
			const temp = to;
			to = from;
			from = temp;
		}
		from = Math.max(1, from);
		to = Math.min(data.exp.length, to);
		return data.exp[to-1] - data.exp[from-1];
	}

	class StudentExpAnalyzerBreakpointRow implements ITableRow {
		data: string[] = [];
		constructor([from, to]: [from: number, to:number], count:number) {
			this.data = [
				`Lv${from} - Lv${to}`,
				count.toLocaleString(),
			]
		}

		getHeaders() {
			return ["Breakpoint", "Count"];
		}
		toArray() {
			return this.data;
		}
		toKeyValues() {
			const headers = this.getHeaders();
			const keyValues = {}
			for (const i in headers) {
				keyValues[headers[i]] = this.data[i];
			}

			return keyValues;
		}
	}
	
	function Parameters() {
		return (<>
			<Row>
				{[1,2,3,4].map(i => 
					<Col xs={6} sm={3}>
						<Card>
							<Card.Header><Card.Title className="text-center">Tier {i}</Card.Title></Card.Header>
							<Card.Body>
								<Card.Img src={`/img/Item_Report${i}.webp`}></Card.Img>
							</Card.Body>
							<Card.Footer>
								<input 
									style={{width:"100%", textAlign: "right"}}
									type="number"
									placeholder="0"
									value={reports[i-1]}
									min={0}
									step={100}
									onChange={(event)=>{
										event.target.value = event.target.value.replaceAll(/[^\d]/g,"");
										if (event.target.value.length) {
											setReports[i-1](parseInt(event.target.value));
										} else {
											setReports[i-1](0);
										}
									}}
									>
								</input>
							</Card.Footer>
						</Card>
					</Col>)}
			</Row>
		</>
		)
	}
	function getTotalExp() {
		return report1 * 50 + report2 * 500 + report3 * 2000 + report4 * 10000
	}
	

	const exp = getTotalExp()
	const breakpoints:StudentExpAnalyzerBreakpointRow[] = [];
	if (!!data) {
		if (exp <= data.exp[data.exp.length-1]) {
			let maxLevel = data.exp.length;
			for (let i=data.exp.length-1; i>= 0; i--) {
				if (exp >= data.exp[i]) {
					maxLevel = i+1;
					break;
				}
			}
			breakpoints.push(new StudentExpAnalyzerBreakpointRow([1, maxLevel], 1))
		} else {
			for(const breakpoint of data.breakpoints) {
				const bexp = calculateStudentExp(breakpoint)
				if (bexp > exp) continue;
				const count = Math.floor(exp/bexp);
				breakpoints.push(new StudentExpAnalyzerBreakpointRow(breakpoint, count));
			}
		}

	}
	return (<Row>
		<Col>
			<Row><Col><Title /></Col></Row>
			<Row><Col>{Parameters()}</Col></Row>
			<Row visible={isLoading}>
				<Col></Col>
				<Col style={styles.spinnerRow}>
					<MyLoadingSpinner isLoading={isLoading}/>
				</Col>
				<Col></Col>
			</Row>
			<Row><Col>Equivalent to <span className="text-pink">{exp.toLocaleString()} EXP</span></Col></Row>
			<Row>
				<Col></Col>
				<Col xs={12} sm={10} md={4} lg={3}>
					<MyTable padding="0.1em" align="right" variant="violet" data={breakpoints}></MyTable>
				</Col>
				<Col></Col>
			</Row>

		</Col>
	</Row>);
}