import RaidRankTsvRow from "Models/RaidRankTsvRow";
import Tsv from "Models/Tsv";
import { ITeamInfoJson } from "../Interfaces/ITeamInfoJson";

interface IExtraParse {
	teams: ITeamInfoJson,
	names: {[id:string]: {
		Username: string,
		Emblem: string
	}}
}
export default class RaidRankTsv extends Tsv<RaidRankTsvRow> {
	// rows: RaidRankTsvRow[] = [];

	parse(data:string):RaidRankTsv {
		// const tsv = new RaidRankTsv();
		
		const rows = data.trim().split('\n');
		for (const row of rows) {
			this.rows.push(RaidRankTsvRow.parse(row));
		}
		this.raw = data;

		return this;
	}
	parseWith(data:string, extra: IExtraParse):RaidRankTsv {
		// const tsv = new RaidRankTsv();
		const rows = data.trim().split('\n');
		for (const row of rows) {
			const parsed = RaidRankTsvRow.parse(row);
			if (!!extra.teams[parsed.uid]) {
				parsed.withTeams(extra.teams[parsed.uid])
			}
			if (!!extra.names[parsed.uid]) {
				parsed.setName(extra.names[parsed.uid].Username.replace(/^\[.+?\]/gi, ''))
				parsed.setEmblem(extra.names[parsed.uid].Emblem)
			}
			this.rows.push(parsed);
		}
		this.raw = data;

		return this;
	}
}