import { IPoint } from "Interfaces/IPoint";
import Student from "Models/Student";
import RaidReportItem from "Reports/RaidReportItem";
interface IAttendance {
	total: number,
	[key:string]: number,
}
interface IScatter {
	data: IPoint[],
	label: string
}
export default class RaidStudentReportItem extends RaidReportItem {
	student: Student;
	attendance: IAttendance;
	assist: IAttendance;
	senseis: IAttendance;
	attendancePercent: string;
	assistPercent: string;
	senseisPercent: string;
	scatter: IScatter[];
	scatterAssist: IScatter[];

	constructor (student: Student, attendance: IAttendance, assist: IAttendance, senseis: IAttendance, scatter: IScatter[], scatterAssist: IScatter[]) {
		super();
		this.student = student
		this.attendance = attendance
		this.attendancePercent = (100*attendance.total/RaidReportItem.totalPlayers).toLocaleString(undefined, { minimumFractionDigits: 4 })
		this.assist = assist
		this.assistPercent = (100*assist.total/RaidReportItem.totalPlayers).toLocaleString(undefined, { minimumFractionDigits: 4 })
		this.senseis = senseis
		this.senseisPercent = (100*senseis.total/RaidReportItem.totalPlayers).toLocaleString(undefined, { minimumFractionDigits: 4 })
		this.scatter = scatter;
		this.scatterAssist = scatterAssist;
	}
	export() {
		return `${this.student.getName()}\t${this.attendance}\t${this.attendancePercent}\t${this.assist}\t${this.assistPercent}`
	}
	toString() {
		return `${this.student.getName()}
	Attendance: ${this.attendance} (${this.attendancePercent})
	Assist: ${this.assist} (${this.assistPercent})`
	}
}