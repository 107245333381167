import IParentProps from "Interfaces/Props/IParentProps";
import React from "react";

export class Sticky extends React.Component<IParentProps & {offset: number, z?: number, style?: React.CSSProperties }> {
	render() {
		return (
			<div className="sticky-top" style={{
				...this.props.style,
				paddingTop: this.props.offset,
				marginTop: -this.props.offset,
				zIndex:this.props.z ?? undefined,
			}}>
				{this.props.children}
			</div>)
	}
}