import { DIFFICULTY_SCORE, HP_SCORE, TIME_LIMIT, TIME_MULTIPLIER } from "Constants/Raids";

export const KEY = {
	BATCH: "param_batch",
	DIFFICULTY_SCORE: "difficultyScore",
	TIME_LIMIT: "timeLimit",
	TIME_MULTIPLIER: "timeMultiplier",
	HP_SCORE: "hpScore",
	TIME: "timeElapsed",
	ACTION: "action",
	DISCORD: "discord",
};

export const defaultScoreParams = () => {
	let data = {
		EN: {},
		JP: {}
	};
	for(let difficulty in DIFFICULTY_SCORE) {
		let en:any[] = [];
		let jp:any[] = [];
		// 0 = Binah(3:00), 1 = Etc(4:00)
		for (let type in [0, 1]) {
			en.push({
				[KEY.TIME_LIMIT]: TIME_LIMIT.EN[type],
				[KEY.TIME_MULTIPLIER]: TIME_MULTIPLIER.EN[difficulty],
				[KEY.DIFFICULTY_SCORE]: DIFFICULTY_SCORE[difficulty],
				[KEY.HP_SCORE]: HP_SCORE.EN[difficulty][type]
			});
			jp.push({
				[KEY.TIME_LIMIT]: TIME_LIMIT.JP,
				[KEY.TIME_MULTIPLIER]: TIME_MULTIPLIER.JP[difficulty],
				[KEY.DIFFICULTY_SCORE]: DIFFICULTY_SCORE[difficulty],
				[KEY.HP_SCORE]: HP_SCORE.JP[difficulty][type]
			});
		}

		data.EN[difficulty] = en;
		data.JP[difficulty] = jp;
	}

	return data;
}