import Student from "Models/Student";
import StudentMap from "Models/StudentMap";
import TeamFormation from "Models/TeamFormation";

export default class Player {
	level: number;
	name: string;
	id: string;
	profilePictureId: string;
	rankBeforeBattle: number;
	team: TeamFormation;
	damage: [number,number,number,number,number,number];

	getIcon() {
		const icon = new StudentMap().getIcon(this.profilePictureId);
		if (icon?.length)
			return icon;
		return "/img/DefaultStudent.jpg";
	}

	getDisplayName() {
		return this.name;
	}

	toString() {
		return this.getDisplayName();
	}
	
	findStudentById(student: string) {
		let s = new StudentMap().getProperName(student) ?? "EMPTY";

		if (this.team.getNames().indexOf(s) >= 0)
			return true;

		return false;
	}

	findStudent(student: Student) {
		return this.findStudentById(student.id.toString());
	}
}