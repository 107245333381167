import assert from "assert";
import { SCORE_CALCULATORS } from "Constants/Common";
import { getDifficultyNumber, reduceSum } from "Utils/Common";

interface IRaidStatistic {
	meanWith:number;
	medianWith:number;
	meanWithout:number;
	withCount:number;
	withoutCount:number;
	minWith:number;
	minWithout:number;
	maxWith:number;
	maxWithout:number;
}
export default class RaidReportItem {
	static totalPlayers: number = 1;
	score: IRaidStatistic = {
		meanWith:0,
		medianWith:0,
		meanWithout:0,
		withCount:0,
		withoutCount:0,
		minWith:0,
		maxWith:0,
		minWithout:0,
		maxWithout:0,
	};
	teams: IRaidStatistic = {
		meanWith:0,
		medianWith:0,
		meanWithout:0,
		withCount:0,
		withoutCount:0,
		minWith:0,
		maxWith:0,
		minWithout:0,
		maxWithout:0,
	};

	time: IRaidStatistic = {
		meanWith:0,
		medianWith:0,
		meanWithout:0,
		withCount:0,
		withoutCount:0,
		minWith:0,
		maxWith:0,
		minWithout:0,
		maxWithout:0,
	};
	
	getScoreInSeconds(score:number) {
		return SCORE_CALCULATORS.getCalculators()[getDifficultyNumber(score)].calculateSecondsElapsed(score);
	}

	calculateScoreData(withItem:number[], withoutItem:number[]) {
		assert(withItem.length > 0, "Why are you calculating nonexistent data");
		withItem.sort((a,b)=>a-b);
		if (withoutItem.length > 0)
			withoutItem.sort((a,b)=>a-b);

		const meanwithItem = withItem.reduce(reduceSum)/withItem.length;
		const withMid = Math.floor(withItem.length/2);
		const medianwithItem = withItem.length%2 == 0? (withItem[withMid] + withItem[withMid-1])/2 : withItem[withMid];
		const meanwithoutItem = withoutItem.length>0 ? withoutItem.reduce(reduceSum)/withoutItem.length : 0;
		this.score = {
			meanWith: meanwithItem,
			meanWithout: meanwithoutItem,
			medianWith: medianwithItem,
			withCount: withItem.length,
			withoutCount: withoutItem.length,
			minWith:withItem[0],
			maxWith:withItem[withItem.length-1],
			minWithout:withoutItem.length > 0 ? withoutItem[0] : 0,
			maxWithout:withoutItem.length > 0 ? withoutItem[withoutItem.length-1] : 0,
		};

		this.time = {
			meanWith: this.getScoreInSeconds(Math.round(meanwithItem)),
			meanWithout: this.getScoreInSeconds(Math.round(meanwithoutItem)),
			medianWith: this.getScoreInSeconds(Math.round(medianwithItem)),
			withCount: withItem.length,
			withoutCount: withoutItem.length,
			minWith:this.getScoreInSeconds(Math.round(withItem[0])),
			maxWith:this.getScoreInSeconds(Math.round(withItem[withItem.length-1])),
			minWithout:withoutItem.length > 0 ? this.getScoreInSeconds(Math.round(withoutItem[0])) : 0,
			maxWithout:withoutItem.length > 0 ? this.getScoreInSeconds(Math.round(withoutItem[withoutItem.length-1])) : 0,
		};

		


		return this;
	}
	calculateTeamData(withItem:number[], withoutItem:number[]) {
		assert(withItem.length > 0, "Why are you calculating nonexistent data");
		withItem.sort((a,b)=>a-b);
		if (withoutItem.length > 0)
			withoutItem.sort((a,b)=>a-b);
		const meanwithItem = withItem.reduce(reduceSum)/withItem.length;
		const withMid = Math.floor(withItem.length/2);
		const medianwithItem = withItem.length%2 == 0? (withItem[withMid] + withItem[withMid-1])/2 : withItem[withMid];
		const meanwithoutItem = withoutItem.length>0 ? withoutItem.reduce(reduceSum)/withoutItem.length : 0;
		this.teams = {
			meanWith: meanwithItem,
			meanWithout: meanwithoutItem,
			medianWith: medianwithItem,
			withCount: withItem.length,
			withoutCount: withoutItem.length,
			minWith:withItem[0],
			maxWith:withItem[withItem.length-1],
			minWithout:withoutItem.length > 0 ? withoutItem[0] : 0,
			maxWithout:withoutItem.length > 0 ? withoutItem[withoutItem.length-1] : 0,
		};
		
		return this;
	}
}