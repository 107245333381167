
export default abstract class Tsv<T> {
	rows: T[] = [];
	raw: string;
	constructor() { }

	// TODO: Add validation
	async fromUrl(url:string) {
		const rawResponse = await fetch(url, {
			method: 'GET'
		});
		const data = await rawResponse.text()

		return this.parse(data);
	}
	async fromUrlWith(url:string, extra:any) {
		const rawResponse = await fetch(url, {
			method: 'GET'
		});
		const data = await rawResponse.text()

		return this.parseWith(data, extra);
	}

	abstract parse(data: string): Tsv<T>;
	abstract parseWith(data: string, extra: any): Tsv<T>;

	// static parse(data:string) {
	// 	const tsv = new Tsv();
	// 	const rows = data.trim().split('\n');
	// 	for (const row of rows) {
	// 		tsv.rows.push(row.trim().split('\t'));
	// 	}
	// 	tsv.raw = data;

	// 	return tsv;
	// }
}