import { IAttachable } from "Interfaces/IAttachable";
import { ICellData } from "Interfaces/ICellData";
import { IParser } from "Interfaces/IParser";
import { IRowData } from "Interfaces/IRowData";
import { ITqResponse } from "Interfaces/ITqResponse";
import { ciContains, ciEquals } from "Utils/Common";

export default class Sheet {
	url:string;
	id:string;
	gid:string;
	json:ITqResponse;

	constructor(id:string, gid:string) {
		this.id = id;
		this.gid = gid;
		this.url = `https://docs.google.com/spreadsheets/d/${id}/gviz/tq?tqx=out:json&tq&gid=${gid}`;
	}

	async initialize() {
		const rawResponse = await fetch(this.url, {
			method: 'GET'
		});
		const match = (await rawResponse.text()).match(/(?<=google\.visualization\.Query\.setResponse\().+(?=\);)/g)
		if (match) {
			this.json = JSON.parse(match[0]);
		}

		return this;
	}

	convert<T>(parser:IParser<T>) {
		const results:T[] = [];
		for (const i in this.getTable().rows) {
			const r = this.getTable().rows[i];
			results.push(parser.parse(r.c));
		}

		return results;
	}

	attachAllTo(map:IAttachable) {
		for (const i in this.getTable().rows) {
			const r = this.getTable().rows[i];
			map.attach(r.c);
		}

	}

	getTable() {
		return this.json.table;
	};

	findColIndex(col:string):number {
		for (const i in this.getTable().cols) {
			const colData = this.getTable().cols[i]
			if (colData.id === col || colData.label === col)
				return parseInt(i);
		}

		return -1;
	}

	findColIndexContains(col:string):number {
		for (const i in this.getTable().cols) {
			const colData = this.getTable().cols[i]
			if (colData.id === col || colData.label.indexOf(col) >= 0)
				return parseInt(i);
		}

		return -1;
	}

	findByCondition(filterCondition:(r:ICellData[])=>boolean):IRowData[] {
		const results:IRowData[] = [];
		for (const i in this.getTable().rows) {
			const r = this.getTable().rows[i];
			const row = r.c;
			// better add some getter since type = datetime has f: "2023-Sep-13", v: "Date(2022,8,13)"
			
			if (filterCondition(row)) {
				r.id = parseInt(i);
				results.push(r);
			}
		}

		return results;
	}
	findByCol(col:string, search:string):IRowData[] {
		const icol:number = this.findColIndex(col);
		const results:IRowData[] = [];
		if (icol < 0)
			return results;
		for (const i in this.getTable().rows) {
			const r = this.getTable().rows[i];
			const row = r.c;
			// better add some getter since type = datetime has f: "2023-Sep-13", v: "Date(2022,8,13)"
			
			if (ciEquals(search, row[icol]?.v?.trim())) {
				r.id = parseInt(i);
				results.push(r);
			}
		}

		return results;
	}

	findAndParseByCondition<T>(filterCondition:(r:ICellData[])=>boolean, parser:IParser<T>):T[] {
		const results:T[] = [];
		for (const i in this.getTable().rows) {
			const r = this.getTable().rows[i];
			const row = r.c;
			
			if (filterCondition(row)) {
				r.id = parseInt(i);
				const parsed:T = parser.parse(r.c);
				if (parsed)
					results.push(parsed);
			}
		}

		return results;
	}
	findAndParseByCol<T>(col:string, search:string, parser:IParser<T>):T[] {
		const icol:number = this.findColIndex(col);
		const results:T[] = [];
		if (icol < 0)
			return results;
		for (const i in this.getTable().rows) {
			const r = this.getTable().rows[i];
			const row = r.c;
			// better add some getter since type = datetime has f: "2023-Sep-13", v: "Date(2022,8,13)"
			
			if (ciEquals(search, row[icol]?.v?.trim().replaceAll(/[\s\n]+/g, " "))) {
				r.id = parseInt(i);
				const parsed:T = parser.parse(r.c);
				if (parsed)
					results.push(parsed);
			}
		}

		return results;
	}
	findAnyByCol(col:string, search:string, delimiter:string = ""):IRowData[] {
		const icol:number = this.findColIndex(col);
		const results:IRowData[] = [];
		if (icol < 0)
			return results;
		if (delimiter === "") {
			for (const i in this.getTable().rows) {
				const r = this.getTable().rows[i];
				const row = r.c;
				// better add some getter since type = datetime has f: "2023-Sep-13", v: "Date(2022,8,13)"
	
				if (ciContains(row[icol]?.v, search)) {
					r.id = parseInt(i);
					results.push(r);
				}
			}
		} else {
			for (const i in this.getTable().rows) {
				const r = this.getTable().rows[i];
				const row = r.c;
				// better add some getter since type = datetime has f: "2023-Sep-13", v: "Date(2022,8,13)"
				for (const tag of row[icol]?.v?.split(delimiter)) {
					if (ciEquals(tag.trim(), search)) {
						r.id = parseInt(i);
						results.push(r);
						break;
					}
				}
	
			}
		}

		return results;
	}
}