import { BOND_EMBLEM_TIERS, POTENTIAL_EMBLEM_TIERS, RAID_MINSCORE } from "Constants/Common";
import { ITableRow } from "Interfaces/ITableRow";
import { ITeamInfoJsonItem } from "Interfaces/ITeamInfoJsonItem";
import Student from "Models/Student";
import StudentFilter from "Models/StudentFilter";
import StudentMap from "Models/StudentMap";
import TeamFormation from "Models/TeamFormation";

export default class RaidRankTsvRow 
implements ITableRow
{
	username: string;
	rank: number;
	uid: string | number;
	score: number;
	level: number;
	// strikers: Student[] = [];
	// specials: Student[] = [];
	finalTeam: TeamFormation;
	teams: TeamFormation[];
	tier: number;
	isFullTeam: boolean = false;
	profilePictureId: number;
	// `5{student.Id}{i}` = Student Bond Tier i (20/50/100)
	// `8{student.Id}{i}` = Student Potential/LB Tier i (25/?/?)
	emblemId: string;
	emblem: string;
	knownEmblem: boolean = false;
	// getHeaders: () => string[];
	toArray: () => string[];
	toKeyValues: () => { [key: string]: string; };
	getHeaders() {
		return [
			"Rank",
			"Score",
			"Sensei Level",
			"Sensei",
			"Unit",
			"Students"
		]
	}
	export():string[][] {
		let name = this.username ?? "#"+this.rank;
		if (this.teams?.length) {
			const teams = [];
			for (const team in this.teams) {
				teams.push([
					this.rank.toLocaleString(),
					this.score.toLocaleString(),
					this.level.toLocaleString(),
					name,
					"Unit "+((parseInt(team))+1),
					this.teams[team].toString()
				]);
			}

			return teams;
		}
		return [[
			this.rank.toLocaleString(),
			this.score.toLocaleString(),
			this.level.toLocaleString(),
			name,
			"Final Only",
			this.finalTeam.toString()
		]];
	}

	getDifficulty() {
		const score = this.score;
		if (score >= RAID_MINSCORE.TOR)
			return "TOR";
		if (score >= RAID_MINSCORE.INS)
			return "INS";
		if (score >= RAID_MINSCORE.EXT)
			return "EXT";
		if (score >= RAID_MINSCORE.HC)
			return "HC";
		if (score >= RAID_MINSCORE.VH)
			return "VH";
		if (score >= RAID_MINSCORE.H)
			return "H";
		return "N";
	}
	getDifficultyNumber():number {
		const score = this.score;
		if (score >= RAID_MINSCORE.TOR)
			return 6;
		if (score >= RAID_MINSCORE.INS)
			return 5;
		if (score >= RAID_MINSCORE.EXT)
			return 4;
		if (score >= RAID_MINSCORE.HC)
			return 3;
		if (score >= RAID_MINSCORE.VH)
			return 2;
		if (score >= RAID_MINSCORE.H)
			return 1;
		return 0;
	}

	hasTeamData() {
		return this.isFullTeam && this.teams && this.teams.length > 0;
	}
	findStudentById(student: string) {
		let s = new StudentMap().getProperName(student) ?? "EMPTY";
		if (this.hasTeamData()) {
			for(const team of this.teams) {
				if (team.getNames().indexOf(s) >= 0)
					return true;
			}
		} else if (this.finalTeam) {
			if (this.finalTeam.getNames().indexOf(s) >= 0)
				return true;
		}
		return false;
	}
	findStudent(student: StudentFilter) {
		if (this.hasTeamData()) {
			for(const team of this.teams) {
				for(const member of team.strikers) {
					if (student.matches(member))
						return true;
				}
				for(const member of team.specials) {
					if (student.matches(member))
						return true;
				}
			}
		} else if (this.finalTeam) {
			for(const member of this.finalTeam.strikers) {
				if (student.matches(member))
					return true;
			}
			for(const member of this.finalTeam.specials) {
				if (student.matches(member))
					return true;
			}
		}
		return false;
	}

	getIcon() {
		const icon = new StudentMap().getIcon(this.profilePictureId);
		if (icon?.length)
			return icon;
		return "/img/DefaultStudent.jpg";
	}
	getTier() {
		switch (this.tier) {
			case 1: return "Bronze";
			case 2: return "Silver";
			case 3: return "Gold";
			case 4: return "Platinum";
			default: return "UNKNOWN";
		}
	}
	getTierIcon() {
		return `/img/Raid_RankIcon_0${this.tier}.png`;
	}
	getDisplayName() {
		if (this.knownEmblem) {
			return `Lv${this.level} ${this.username}\n[${this.emblem}]`;
		}
		return `Lv${this.level} ${this.username}`;
	}

	toString() {
		return `#${this.rank}: Score: ${this.score}\nFinal Team: ${this.finalTeam}`;
	}

	setName(name:string) {
		this.username = name;
	}
	setEmblem(emblemId:string) {
		this.emblemId = emblemId
		this.emblem = this.parseEmblem();
	}
	getEmblem() {
		return this.emblem;
	}
	parseEmblem() {
	// `5{student.Id}{i}` = Student Bond Tier i (20/50/100)
	// `8{student.Id}{i}` = Student Potential/LB Tier i (25/?/?)
		this.knownEmblem = false;
		if (this.emblemId.length < 3) return "";
		const emblemType = this.emblemId[0];
		const emblemTier = this.emblemId[this.emblemId.length-1];
		const studentId = this.emblemId.substring(1,this.emblemId.length-1)
		const studentName = new StudentMap().getName(studentId) ?? "Unknown Student";
		switch (emblemType) {
			case "5": 
			this.knownEmblem = true;
			return `${studentName} Bond ${BOND_EMBLEM_TIERS[emblemTier]}`;
			case "8": 
			this.knownEmblem = true;
			return `${studentName} LB All Lv ${POTENTIAL_EMBLEM_TIERS[emblemTier]}`;
			default: return "Unknown Title"
		}
	}

	withTeams(data: ITeamInfoJsonItem[]) {
		this.teams = [];
		let ctr = 1;
		for(const team of data) {
			let i=0, j=0;
			const formation = new TeamFormation(
				[
					Student.fromRaidRanks(team.team.strikers[i++]),
					Student.fromRaidRanks(team.team.strikers[i++]),
					Student.fromRaidRanks(team.team.strikers[i++]),
					Student.fromRaidRanks(team.team.strikers[i++]),
				],
				[
					Student.fromRaidRanks(team.team.specials[j++]),
					Student.fromRaidRanks(team.team.specials[j++]),
				]
			);
			formation.setUnit(ctr++);
			this.teams.push(formation);
			this.isFullTeam = true;
		}
	}

	static parse(raw:string) {
		const data = raw.split('\t');
		const row = new RaidRankTsvRow();
		let i=0;
		row.rank = parseInt(data[i++]);
		row.uid = data[i++];
		row.score = parseInt(data[i++]);
		row.level = parseInt(data[i++]);
		row.isFullTeam = false;
		row.finalTeam = new TeamFormation(
			[
				Student.fromRaidRanks(data[i++]),
				Student.fromRaidRanks(data[i++]),
				Student.fromRaidRanks(data[i++]),
				Student.fromRaidRanks(data[i++]),
			],
			[
				Student.fromRaidRanks(data[i++]),
				Student.fromRaidRanks(data[i++]),
			]
		)
		row.teams = [row.finalTeam];

		row.tier = parseInt(data[i++]);
		row.profilePictureId = parseInt(data[i++].trim());
		return row;
	}
}