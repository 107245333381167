import IconButton from "Components/IconButton";
import StudentRenderer from "Components/ModelRenderers/StudentRenderer";
import MyLoadingSpinner from "Components/MyLoadingSpinner";
import { TagBadge } from "Components/TagBadge";
import { GOOGLE_SPREADSHEET_GID, GOOGLE_SPREADSHEET_ID } from "Constants/Common";
import IStyle from "Interfaces/IStyle";
import FarmableStudent from "Models/FarmableStudent";
import Sheet from "Models/Sheet";
import Student from "Models/Student";
import React, { useEffect, useMemo, useState } from "react";
import { Card, CardGroup, Col, Form, Row } from "react-bootstrap";
import { Xwrapper } from 'react-xarrows';
const styles:IStyle = {
	cardContainer: {
		display: "flex", 
		flexDirection: "row", 
		flexWrap: "wrap"
	},
	cardGroup: {
		margin: "1em",
		zIndex: 1,
		padding: "0 0.3em 0 0.3em",
		border: "1px solid",
		borderColor: "pink",
	},
	compactCardGroup: {
		margin: "1em 1em 0.1em 1em",
		zIndex: 1,
		padding: "0 0.3em 0 0.3em",
	},
	card: {
		textAlign: "center"
	},
	spinnerRow: {
		// alignContent: "center",
		// alignItems: "center",
		// alignSelf: "center",
		// textAlign: "center"
		zIndex: 10
	},
	button: {
		marginRight: 5
	},
	buttonLink: {
		margin: 5,
	},
}
export default function FarmableSummary() {
	const [isLoading, setIsLoading] = useState(true);
	const [farmables, setFarmables] = useState<Sheet>(new Sheet("", ""));
	
	const [compact, setIsCompact] = useState(true);
	const display = useMemo(
		() => !farmables.json ? [] : farmables.findAndParseByCondition((row) => {
			return FarmableStudent.exists(row);
		}, new FarmableStudent()).sort((a,b) => b.type.split('\n').sort().join(",").localeCompare(a.type.split('\n').sort().join(","))),
		[farmables]
	);

	const data: {[source: string]: FarmableStudent[]} = {};
	const date = [];
	for (const m of display) {
		const match = m as FarmableStudent;
		if (data[match.type]) data[match.type].push(match);
		else {
			data[match.type] = [match];
			date.push(match.type);
		}
	}
	const bannerGroups = data;
	const dates = date;

	const loadAsync = async () => {
		const sheet = new Sheet(GOOGLE_SPREADSHEET_ID, GOOGLE_SPREADSHEET_GID.FARMABLE);
		const sheetTask = await sheet.initialize();
		setFarmables(sheetTask);
		setIsLoading(false)
	}

	useEffect(() => { 
		loadAsync() 
	}, []);
	// useEffect(() => {
	// 	// if (!!!banners.json) return;
	// 	// const matches = banners.findAndParseByCondition((row) => BannerStudent.parseEta(row) >= -14, new BannerStudent());
	// 	// setDisplay(matches);
	// 	// const data: {[eta: number]: BannerStudent[]} = {};
	// 	// const date = [];
	// 	// for (const match of matches) {
	// 	// 	if (data[match.eta]) data[match.eta].push(match);
	// 	// 	else {
	// 	// 		data[match.eta] = [match];
	// 	// 		date.push(match.eta);
	// 	// 	}
	// 	// }
	// 	// setBannerGroups(data);
	// 	// setDates(date);
	// 	// setIsLoading(false);
	// }, [banners]);


	function SummaryCard({data}:{data:FarmableStudent}) {
		return (
			<Card className="banner-summary card" key={data.name} style={styles.card}>
				<StudentRenderer link="/StudentInsights/%id%" alwaysFooter compact={compact} bare model={Student.fromName(data.name)}></StudentRenderer>
				<Card.Body>
					<Card.Subtitle>When?</Card.Subtitle>
					<Card.Text>
						{(data.whenToFarm.length > 0 ? data.whenToFarm : "Wait").split(/\n|\\|\/\//i).map(
							(str,i) => <TagBadge pill key={i}>{str.trim()}</TagBadge>
							)}
					</Card.Text>
					<Card.Subtitle>TLDR</Card.Subtitle>
					<Card.Text>
						{data.tldr.split('\n').map(
							(str,i) => <TagBadge key={i}>{str.trim().replaceAll(/^\(|\)$/g,'')}</TagBadge>
						)}
					</Card.Text>
					<Card.Text><sub>{data.name}</sub></Card.Text>
				</Card.Body>
			</Card>
		)
	}

	function FarmableGroup({data}:{data:FarmableStudent[]}) {
		return (
			<Card className="text-pink banner-group" key={data[0].whenToFarm} id={data[0].whenToFarm} style={compact ? styles.compactCardGroup : styles.cardGroup}>
				<Card.Header><Card.Title>
					{data[0].type.split('\n').map(
						(str,i) => <TagBadge pill key={i}>{str.trim()}</TagBadge>
					)}
				</Card.Title></Card.Header>
				<CardGroup>
					{data.map(card => <SummaryCard key={card.name} data={card}/>)}
				</CardGroup>
			</Card>
		)
	}

	function Farmables() {
		const keys = dates;
		// const keys = Object.keys(bannerGroups).sort((a, b) => {
		// 	console.log(Date.parse(a));
			
		// 	return moment(a, "YYYY-MMM-DD").valueOf() - moment(b, "YYYY-MMM-DD").valueOf()
		// });
		// const keys = Object.keys(bannerGroups).sort((a, b) => Date.parse(a) - Date.parse(b));

		return (
			<Xwrapper>
				{keys.map(key => <FarmableGroup key={key} data={bannerGroups[key]} />)}
				{/* {Object.keys(keys).map(keystr => {
					const key = parseInt(keystr);
					if (!keys[key+1]) return null; 
					return <Xarrow 
						// path="grid"
						// gridBreak="0%"
						start={bannerGroups[keys[key]][0].start} 
						end={bannerGroups[keys[key+1]][0].start}
						startAnchor="right"
						endAnchor="left"
						color="rgb(255, 175, 255)"
					/>
				})} */}
			</Xwrapper>
		)
	}

	return (<Row>
		<Col>
			<Row>
				<Col>
					<Row>
						<Col style={styles.buttonLink}>
							<IconButton 
								// image="/logo512.png"
								image="https://ssl.gstatic.com/docs/spreadsheets/favicon3.ico"
								label="Farmables Summary Spreadsheet ver."
								href="https://docs.google.com/spreadsheets/d/1BO-Uh_CsAOIetPjrNyFy9-7r1a4RAtVwK135bBC3hzM#gid=900270886" 
							/>
						</Col>
					</Row>
					{/* <Row style={styles.filterRow}>
						<Col md={2}>
							<label>Start Date</label>
						</Col>
						<Col>
							<input 
								style={{width:"100%"}}
								type="date"
								placeholder={`From (${defaultFrom})`}
								value={dateFrom}
								onChange={(event)=>{ setDateFrom(event.target.value) }}
							>
							</input>
						</Col>
						<Col xs={1}>-</Col>
						<Col>
							<input 
								style={{width:"100%"}}
								type="date"
								placeholder={`Until (${defaultTo})`}
								value={dateTo}
								onChange={(event)=>{ setDateTo(event.target.value) }}
								// onChange={(event)=>{
								// 	setFilters({
								// 		...filters,
								// 		to: event.target.value
								// 	})
								// }}
								>
							</input>
						</Col>
					</Row> */}
					<Row>
						<Col>
							<div key="student-compact" className="mb-3">
								<Form.Check 
								checked={compact}
								type="switch"
								id="student-compact"
								label="Compact Mode"
								onChange={(a)=>{
									setIsCompact(a.target.checked);
								}}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col className="text-pink">
							<h1>Click on a student for better insights and details.</h1>
						</Col>
					</Row>
					<hr></hr>
				</Col>
			</Row>
			<Row hidden={!isLoading}>
				<Col></Col>
				<Col style={styles.spinnerRow}>
					<MyLoadingSpinner isLoading={isLoading}/>
				</Col>
				<Col></Col>
			</Row>
			<Row>
				<Col style={styles.cardContainer}>
					<Farmables />
				</Col>
			</Row>
		</Col>
	</Row>
	);
}