import { ModelRenderer } from "Components/ModelRenderers/ModelRenderer";
import { KEY } from "Constants/Common";
import { IGenericStudentTemplate } from "Interfaces/IGenericStudentTemplate";
import { INamedSheets } from "Interfaces/INamedSheets";
import { IStudentInfoProps } from "Interfaces/Props/IStudentInfoProps";
import BannerStudent from "Models/BannerStudent";
import BorrowStudent from "Models/BorrowStudent";
import FarmableStudent from "Models/FarmableStudent";
import GenericStudent from "Models/GenericStudent";
import Sheet from "Models/Sheet";
import StudentMap from "Models/StudentMap";
import StudentThoughts from "Models/StudentThoughts";
import { ciEquals } from "Utils/Common";
import React from "react";
import { Card, Nav } from "react-bootstrap";


export class StudentInfoCard extends React.Component<IStudentInfoProps, {
	tab:string
}> {
	state = {
		tab: "#"+KEY.BANNER
	}
	constructor(props: any) {
		super(props);
	}

	getData() {
		let data = [];
		let database:INamedSheets;
		let template:IGenericStudentTemplate;
		switch (this.state.tab.substring(1)) {
			case KEY.BANNER:
				data = this.props.database?.banner.findAndParseByCol(
					"Name", 
					this.props.target, 
					new BannerStudent()
				) ?? [ null ];
				data.reverse();

				return data;
			case KEY.FARMABLE:
				data = this.props.database?.farmable.findAndParseByCol(
					"Name", 
					this.props.target, 
					new FarmableStudent()
				) ?? [ null ];

				return data;
			case KEY.BORROWS:
				data = this.props.database?.borrow.findAndParseByCol(
					"B", 
					this.props.target, 
					new BorrowStudent()
				) ?? [ null ];

				return data;
			case KEY.THOUGHTS:
				data = this.props.database?.thoughts.findAndParseByCol(
					"B", 
					this.props.target, 
					new StudentThoughts()
				) ?? [ null ];

				return data;
			case KEY.STOKKIE_RAID:
				database = this.props.database?.generics?.stokRaidRating as INamedSheets;
				let temp = []
				for (const sheet of ["STRIKER_EXT", "SPECIAL_EXT", "STRIKER_INS", "SPECIAL_INS"]) {
					const diff = sheet.endsWith("EXT") ? "EXT-" : "INS+";
					template = {
						name: 1,
						source: {
							author: "stokesia / causew",
							name: "Stokkie's Raid Ratings",
							url: "https://bit.ly/StokRaids",
							iconUrl: "https://ssl.gstatic.com/docs/spreadsheets/favicon3.ico",
						},
						displayData: [{
							index: database[sheet].findColIndexContains("Stokesia's Notes"),
							label: `Stokesia's Notes (${diff})`,
							content: "N/A",
						}, {
							index: database[sheet].findColIndexContains("Causew's Notes"),
							label: `Causew's Notes (${diff})`,
							content: "N/A",
						}]
					};
					const colIndex = database[sheet].findColIndex("B");
					let students = database[sheet].findAndParseByCondition(
						(row) => ciEquals(this.props.target, (new StudentMap()).getProperName(row[colIndex]?.v?.trim())),
						new GenericStudent(template)
					);
					temp = [
						...temp,
						...students
					];
				}
				data = temp;

				if (data.length === 0) 
					return [null];
				else if (data.length > 1) {
					const displayData = data.reduce((prev, curr) => { return [
							...(prev.displayData),
							...(curr.displayData),
						]
					})
					data[0].displayData = displayData;
				}
				return data as GenericStudent[];

			case KEY.BRICC_ARCHIVE:

			database = this.props.database?.generics?.briccArchive as INamedSheets;
			template = {
				name: 1,
				source: {
					author: "folie / brightrailgun",
					name: "Bricc Archive (Skill Level Guide)",
					url: "https://bit.ly/BriccArchive",
					iconUrl: "https://ssl.gstatic.com/docs/spreadsheets/favicon3.ico",
				},
				displayData: [{
					index: 5,
					label: "Minimum",
					content: "N/A",
				}, {
					index: 6,
					label: "Intermediate 1",
					content: "N/A",
				}, {
					index: 7,
					label: "Intermediate 2",
					content: "N/A",
				}, {
					index: 8,
					label: "Intermediate 3",
					content: "N/A",
				}, {
					index: 9,
					label: "Optimal for Cost",
					content: "N/A",
				}, {
					index: 10,
					label: "Optimal if 2*UE",
					content: "N/A",
				}, {
					index: 12,
					label: "Notes",
					content: "N/A",
				}, {
					index: -1,
					label: "Comment from Folie",
					content: "Unforunately the scale doesn't exactly follow specific raid difficulties at the moment and it is just a progressive step on which skills would be better to focus on next.",
				}]
			};
			data = [
				...(database.STRIKER.findAndParseByCondition(
					(row) => ciEquals(this.props.target, (new StudentMap()).getProperName(row[database.STRIKER.findColIndex("C")]?.v?.trim())),
					new GenericStudent(template)
				)),
				...(database.SPECIAL.findAndParseByCondition(
					(row) => ciEquals(this.props.target, (new StudentMap()).getProperName(row[database.SPECIAL.findColIndex("C")]?.v?.trim())),
					new GenericStudent(template)
				))
			];

			if (data.length === 0) return [null];
			return data as GenericStudent[];

			case KEY.CAUSEW_TOWER50:
				
			const sheet = this.props.database?.generics?.cswTower as Sheet;
			template = {
				name: 1,
				source: {
					author: "causew",
					name: "Causew's Tower F50+",
					url: "https://bit.ly/StokRaids",
					iconUrl: "https://ssl.gstatic.com/docs/spreadsheets/favicon3.ico",
				},
				displayData: [{
					index: sheet.findColIndexContains("Roles"),
					label: `Roles`,
					content: "N/A",
				},{
					index: sheet.findColIndexContains("Notes"),
					label: `Causew's Notes`,
					content: "N/A",
				}]
			};
			const colIndex = sheet.findColIndexContains("Name");
			let students = sheet.findAndParseByCondition(
				(row) => ciEquals(this.props.target, (new StudentMap()).getProperName(row[colIndex]?.v?.trim())),
				new GenericStudent(template)
			);
			data = students;

			if (data.length === 0) 
				return [null];
			else if (data.length > 1) {
				const displayData = data.reduce((prev, curr) => { return [
						...(prev.displayData),
						...(curr.displayData),
					]
				})
				data[0].displayData = displayData;
			}
			return data as GenericStudent[];

			default:
				// console.log(this.state.tab);
				return [null];
		}
	}

	getModel() {
		return this.getData()[0];
	}

	getParams() {
		const data = this.getData();
		return {
			model: data[0],
			extra: {
				models: data,
				index: 0
			}
		}
	}

	render() {
		// TODO: Add tooltips?
		const tabs = [
			KEY.BANNER,
			KEY.FARMABLE,
			KEY.BORROWS,
			KEY.THOUGHTS,
		]
		const genericReferences = [
			[KEY.STOKKIE_RAID, "Stokkie's Raid Ratings"],
			[KEY.BRICC_ARCHIVE, "Bricc Archive"],
			[KEY.CAUSEW_TOWER50, "Causew's Tower F50+"],
		];
		return (
			<Card>
				<Card.Header>
					<Nav 
						variant="tabs" 
						defaultActiveKey={"#"+KEY.BANNER}
						activeKey={this.state.tab}
						onSelect={(eventKey) => this.setState({
							tab: eventKey
						})}>
						<Nav.Item>
							<Nav.Link eventKey="disabled" disabled>
								<strong>Midokuni:</strong>
							</Nav.Link>
						</Nav.Item>
						{tabs.map(tab => (
							<Nav.Item>
								<Nav.Link href={`#${tab}`}>{tab}</Nav.Link>
							</Nav.Item>
						))}
						
					</Nav>
					<Nav 
						variant="tabs" 
						defaultActiveKey={"#"}
						activeKey={this.state.tab}
						style={{paddingTop: 5}}
						onSelect={(eventKey) => this.setState({
							tab: eventKey
						})}>
						<Nav.Item>
							<Nav.Link eventKey="disabled" disabled>
								<strong>Others:</strong>
							</Nav.Link>
						</Nav.Item>
						{genericReferences.map(tab => (
							<Nav.Item>
								<Nav.Link href={`#${tab[0]}`}>{tab[1]}</Nav.Link>
							</Nav.Item>
						))}
					</Nav>
				</Card.Header>
				<Card.Body>
					<ModelRenderer {...this.getParams()}/>
				</Card.Body>
				<Card.Footer>{this.props.target}</Card.Footer>
			</Card>);
	}
}