import React from "react";
import { Image } from "react-bootstrap";
export class Images {
	static UE() {
		return (<Image className="image-ue" style={{
			height:"1em",
			marginRight: 2, 
			position:"relative", 
			top:-2, 
		}} src="/img/Common_Icon_CharacterWeapon_off.png"></Image>)
	}

	static Pyroxene({size}:{size?: string|number}) {
		return (<Image width={size} className="image-pyroxene" style={{
			// width: size,
			// height:"1em",
			// marginRight: 2, 
			// position:"relative", 
			// top:-2, 
		}} src="/img/Item_Pyroxene.png"></Image>)
	}
	static Armor({color, size}:{color: string, size?: string|number}) {
		switch (color) {
			case "Light": color = "Red"; break;
			case "Heavy": color = "Yellow"; break;
			case "Unarmed":
			case "Special": color = "Blue"; break;
			case "Elastic": color = "Purple"; break;
		}
		return (<Image width={size} className="image-pyroxene" style={{
			// width: size,
			// height:"1em",
			// marginRight: 2, 
			// position:"relative", 
			// top:-2, 
		}} src={`/img/${color}Armor.png`}></Image>)
	}

	static Eligma({size}:{size?: string|number}) {
		return (<Image width={size} className="image-eligma" style={{
		// width: size,
			// height:"1em",
			// marginRight: 2, 
			// position:"relative", 
			// top:-2, 
		}} src="/img/Item_Eligma.webp"></Image>)
	}
	static Mood({size, mood}:{size?: string|number, mood: "SS" | "S" | "A" | "B" | "C" | "D"}) {
		return (<Image width={size} className="image-mood" src={`/img/Mood_${mood}.png`}></Image>)
	}
	static Eleph({size}:{size?: string|number}) {
		return (<Image width={size} className="image-eleph" src="/img/Item_Eleph.webp"></Image>)
	}
	static Spinner({size}:{size?: string|number}) {
		return (
			<div className="custom-spinner">
				<span className="rotating-spinner">
					<Image
						width={size}
						src="/logo512_back.png" 
						alt="Loading..." 
						/>
				</span>
				<span>
					<Image
						width={size}
						src="/logo512_front.png" 
						alt="Loading..." 
					/>
				</span>
			</div>
		);
	}
}