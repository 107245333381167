import { Images } from "Components/Images";
import IStyle from "Interfaces/IStyle";
import React, { CSSProperties } from "react";
import { Col, Row } from "react-bootstrap";
interface IProps {
	isLoading: boolean;
	size?: string | number;
	text?: string;
	overlay?: boolean;
}
const styles:IStyle = {
	container: {
		// backgroundColor: "white",
		// backgr
		// textAlign: "center",
		// alignContent: "center",
		// alignItems: "center",
	},
	overlay: {
		position: "fixed",
		left: "50vw",
		top: "50vh"
	}
}

export default function MyLoadingSpinner({isLoading, size, text, overlay}: IProps) {
	if (!isLoading) return null;
	const height = size ?? 512;
	const halfHeight = typeof(height) === "number" ? height/2 : parseInt(height)/2;
	
	const overlayStyle:CSSProperties = {
		position: "fixed",
		left: `calc(50vw - ${halfHeight}px)`,
		top:  `calc(50vh - ${halfHeight}px)`,
	}
	return (
		<span style={overlayStyle} className={overlay ? "my-loading z-top" : "my-loading"}>
			<Row style={{height}}>
				<Col><Images.Spinner size={size}/></Col>
			</Row>
			<Row>
				<Col><span className="text-pink">{text ?? "Loading..."}</span></Col>
			</Row>
		</span>
	)
}