import React from "react";
const OPERATORS = [">=", "=", "<="];
export default function RelationOperatorOptions() {
	// if (document.getElementById("relation-operator")) return null;
	// <datalist id="relation-operator">
	return (
		<>
		{OPERATORS.map((val, key) => (
				<option key={key} value={val}>{val}</option>
			))
		}
	</>
	);
}